/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Icon, ButtonToolbar, IconButton, Table, TagGroup, Tag } from 'rsuite'
import { ReadableDate } from '../helper/date'
import { rentalRequestStatus } from '../settings/rentalStatus'

const { Cell, HeaderCell, Column, Pagination: TablePagination } = Table

export default class RentalRequestsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      sortColumn: 'id',
      sortType: 'desc',
      displayLength: 10,
      page: 1,
      filters: { search: '', status: [] },
    }

    this.handleSortColumn = this.handleSortColumn.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeLength = this.handleChangeLength.bind(this)
  }

  componentDidMount() {
    // this.setState({loading: false})
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ data: nextProps.data })
      setTimeout(() => {
        this.setState({ data: this.getData() })
      }, 1000)
    }
    if (nextProps.filters) {
      this.setState({ filters: nextProps.filters })
      setTimeout(() => {
        this.setState({ data: this.getData() })
      }, 1000)
    }
  }

  getData() {
    const { data, sortColumn, sortType, filters } = this.state

    let resultData = []

    data.forEach((v, i) => {
      if (filters.search && filters.search.length > 0) {
        if (
          v.customerName.includes(filters.search) ||
          v.customerEmail.includes(filters.search) ||
          v.customerDocument.includes(filters.search) ||
          v.id.toString().includes(filters.search)
        ) {
          return resultData.push(v)
        }
        return
      }

      if (filters.status && filters.status.length > 0) {
        if (filters.status.includes(v.status)) {
          return resultData.push(v)
        }
        return
      }

      resultData.push(v)
    })

    if (sortColumn && sortType) {
      return resultData.sort((a, b) => {
        let x = a[sortColumn]
        let y = b[sortColumn]
        if (typeof x === 'string') {
          x = x.charCodeAt()
        }
        if (typeof y === 'string') {
          y = y.charCodeAt()
        }
        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return resultData
  }

  handleSortColumn(sortColumn, sortType) {
    this.setState({
      sortColumn,
      sortType,
      // loading: true
    })

    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 500)
  }

  handleChangePage(dataKey) {
    this.setState({
      page: dataKey,
    })
  }

  handleChangeLength(dataKey) {
    this.setState({
      page: 1,
      displayLength: dataKey,
    })
  }

  render() {
    const {
      data: originalData,
      sortType,
      sortColumn,
      loading,
      page,
      displayLength,
    } = this.state

    const data = this.getData().filter((v, i) => {
      const start = displayLength * (page - 1)
      const end = start + displayLength
      return i >= start && i < end
    })

    return (
      <div>
        <Table
          data={data}
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={this.handleSortColumn}
          loading={loading}
          height={500}
        >
          <Column sortable width={120} align="center">
            <HeaderCell>Número</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column sortable width={200}>
            <HeaderCell>Nome do cliente</HeaderCell>
            <Cell dataKey="customerName" />
          </Column>
          <Column width={240}>
            <HeaderCell>Status</HeaderCell>
            <Cell>
              {(rowData) => {
                const status = rentalRequestStatus[rowData.status]

                const styles = {
                  backgroundColor: status.color,
                  color: '#fff',
                }

                return (
                  <TagGroup>
                    <Tag style={styles}>{status.label}</Tag>
                  </TagGroup>
                )
              }}
            </Cell>
          </Column>
          <Column sortable width={120}>
            <HeaderCell>Envio</HeaderCell>
            <Cell dataKey="sendDate">
              {(row) => ReadableDate(row.sendDate, { showHours: false })}
            </Cell>
          </Column>
          <Column sortable width={120}>
            <HeaderCell>Início</HeaderCell>
            <Cell dataKey="startDate">
              {(row) => ReadableDate(row.startDate, { showHours: false })}
            </Cell>
          </Column>
          <Column sortable width={120}>
            <HeaderCell>Término</HeaderCell>
            <Cell dataKey="endDate">
              {(row) => ReadableDate(row.endDate, { showHours: false })}
            </Cell>
          </Column>
          <Column sortable width={120}>
            <HeaderCell>Retorno</HeaderCell>
            <Cell dataKey="returnDate">
              {(row) => ReadableDate(row.returnDate, { showHours: false })}
            </Cell>
          </Column>
          <Column sortable width={120}>
            <HeaderCell>Valor do frete</HeaderCell>
            <Cell dataKey="totalPrice">
              {(row) => `R$ ${Number(row.shippingPrice).toLocaleString()}`}
            </Cell>
          </Column>
          <Column sortable width={120}>
            <HeaderCell>Valor total</HeaderCell>
            <Cell dataKey="totalPrice">
              {(row) => `R$ ${Number(row.totalPrice).toLocaleString()}`}
            </Cell>
          </Column>
          <Column width={180} fixed="right">
            <HeaderCell>Ações</HeaderCell>
            <Cell className="link-group">
              {(rowData) => {
                const { handleShow } = this.props
                return (
                  <ButtonToolbar>
                    <IconButton
                      appearance="subtle"
                      onClick={() => handleShow(rowData)}
                      icon={<Icon icon="eye" />}
                    />

                    <IconButton
                      appearance="subtle"
                      onClick={() => this.props.handleDelete(rowData)}
                      icon={<Icon icon="trash" />}
                    />
                  </ButtonToolbar>
                )
              }}
            </Cell>
          </Column>
        </Table>
        <TablePagination
          lengthMenu={[
            {
              value: 10,
              label: 10,
            },
            {
              value: 20,
              label: 20,
            },
          ]}
          activePage={page}
          displayLength={displayLength}
          total={originalData.length}
          onChangePage={this.handleChangePage}
          onChangeLength={this.handleChangeLength}
        />
      </div>
    )
  }
}
