import React, { useState } from 'react'
import {
  Container,
  Panel,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  ButtonToolbar,
  Button,
} from 'rsuite'
import { useDispatch } from 'react-redux'
import logo from '../img/log_white.svg'
import api, { setAuthToken } from '../services/api'

export default function LoginPage() {
  const dispatch = useDispatch()

  const changeUserData = (data) => {
    dispatch({ type: 'CHANGE_USER_DATA', user: data })
  }

  const changeStatuses = (data) => {
    dispatch({ type: 'CHANGE_STATUS_DATA', statuses: data })
  }

  const [formValue, setFormValue] = useState({ username: '', password: '' })
  const [logging, setLogging] = useState(false)

  function handleChange(val) {
    setFormValue(val)
  }

  async function login() {
    setLogging(true)

    try {
      const { data } = await api.post('/login', formValue)

      setAuthToken(`${data.username}:${data.token}`)

      changeUserData({ logged: true, ...data })

      const { data: statuses } = await api.get('/status')

      changeStatuses(statuses)
    } finally {
      setLogging(false)
    }
  }

  return (
    <div className="App green-bg">
      <Container className="vertical-center horizontal-center full-width">
        <img className="login-brand" src={logo} alt="Logo LOG" />
        <br />
        {/* <FlexboxGrid justify="center">
            <FlexboxGrid.Item className="white-bg round" colspan={8}> */}
        <div className="login-container">
          <Panel className="white-bg" header={<h3>Login</h3>}>
            <Form fluid onChange={handleChange} formValue={formValue}>
              <FormGroup>
                <ControlLabel>Nome de usuário</ControlLabel>
                <FormControl name="username" />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Senha</ControlLabel>
                <FormControl name="password" type="password" />
              </FormGroup>
              <FormGroup>
                <ButtonToolbar>
                  <Button
                    onClick={login}
                    loading={logging}
                    disabled={logging}
                    appearance="primary"
                  >
                    Entrar
                  </Button>
                </ButtonToolbar>
              </FormGroup>
            </Form>
          </Panel>
        </div>
        {/* </FlexboxGrid.Item>
          </FlexboxGrid> */}
      </Container>
    </div>
  )
}
