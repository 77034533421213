import store from '../store'

const user = {
  isTechnician: () => {
    const userData = store.getState().user
    return (
      userData.roles.includes('technician') &&
      (!userData.roles.includes('admin') &&
        !userData.roles.includes('employee'))
    )
  },
  data: store.getState().user,
}

export {
  user
}
