import React from 'react'
import { Notification, ButtonToolbar, Button } from 'rsuite'

export default function ConfirmationPopup(text) {
  return new Promise(resolve => {
    const close = confirm => {
      Notification.remove('confirmationPopup')
      resolve(confirm)
    }

    Notification.open({
      title: 'Aviso',
      duration: 10000,
      key: 'confirmationPopup',
      description: (
        <div>
          <p>{text}</p>
          <small>Essa ação é irreversível.</small>
          <br />
          <br />
          <ButtonToolbar>
            <Button color='red' onClick={() => close(true)}>
              Sim
            </Button>
            <Button color='primary' onClick={() => close(false)}>
              Cancelar
            </Button>
          </ButtonToolbar>
        </div>
      ),
    })
  })
}
