import React, {Component} from 'react'
import '../style/App.css'
import '../style/Log.css'
import NavBar from '../components/Navbar'
import SideBar from '../components/Sidebar'
import Routes from '../Routes'
// import { BrowserRouter as Router } from 'react-router-dom'
import { Row, Col } from 'rsuite'

export default class MainScreen extends Component {
  constructor (props) {
    super (props);
    this.handleSelect = this.handleSelect.bind (this);
    this.state = {
      selected: true,
      sidebar: true,
      fade: true
    };
  }

  handleSelect () {
    this.setState ({
      selected: true,
    });
  }

  handleRedirect () {
    this.setState({
      selected: false
    })
  }

  handleSidebar () {
    this.setState({sidebar: !this.state.sidebar});
  }

  render () {
    const {selected, sidebar} = this.state;
    return (
      <div className="App">
        <NavBar
          appearance="inverse"
          onSelect={this.handleSelect}
          onNavToggle={() => this.handleSidebar()}
          onRedirect={() => this.handleRedirect()}
          user={this.props.user}
        />
        <Row className="siteContent">
          <Col className="sidebar">
            <SideBar expanded={sidebar} selected={selected} handleSelect={()=>this.handleSelect()} />
          </Col>
          <Col className="content">
            <Routes/>
          </Col>
        </Row>
      </div>
    );
  }
}
