import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Modal, Button, Icon } from 'rsuite'

function NotificationComponent({ custom, cancelButton, ...props }) {
  const [open, setOpen] = useState(props.show || false)

  const { title, message } = props

  const close = () => {
    setOpen(false)
  }

  return (
    <Modal show={open} size='xs' onHide={close}>
      <Modal.Header>
        <Modal.Title><Icon icon='exclamation-triangle' style={{color: '#57bf00'}} size='2x' /> {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
        { custom }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} appearance='primary' color='primary'>
          Ok
        </Button>
        {cancelButton && (
          <Button onClick={close} appearance='ghost' color='red'>
            Cancelar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

const NotificationPopup = {
  show: (title, message) => {
    let elem = document.createElement('div')

    document.body.appendChild(elem)
    
    ReactDOM.render(<NotificationComponent show={true} title={title} message={message}/>, elem)
  }
}

export default NotificationPopup