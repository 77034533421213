import axios from 'axios'
import { Alert } from 'rsuite'
import { API_URL } from '../settings/variables'

const TOKEN_ITEM_NAME = '@LogNature/token'

export const getAuthToken = () => {
  if (typeof localStorage === 'undefined') {
    return ''
  }
  return localStorage.getItem(TOKEN_ITEM_NAME)
}

export const setAuthToken = (token) => {
  return localStorage.setItem(TOKEN_ITEM_NAME, token)
}

const api = new axios.create({
  baseURL: API_URL,
  responseType: 'json',
  transformResponse: (res) => {
    if (res) {
      if (res.error) Alert.error(res.error)
    }
    return res ? res : ''
  },
})

api.interceptors.request.use((config) => {
  try {
    const token = getAuthToken()

    if (token) {
      config.headers.Authorization = `${token}`
    }
  } catch {}
  return config
})

export default api
