import React from 'react'
import jsPDF from 'jspdf'
import './Quicksand-Bold-bold'
import './Quicksand-Regular-normal'
import logo_img from '../img/wenature.png'

const logo = new Image()
logo.src = logo_img

jsPDF.API.Text = function (txt, x, y, opt) {
  this.text(txt, x, y + 10, opt)
}

const date = new Date()

const months = [
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro'
]

const generatePDF = (laudo, save = false) => {
  return new Promise((resolve, reject) => {
    try {

      const doc = new jsPDF('p', 'pt', [595, 842])
      
      doc.addImage(logo, 'PNG', 205, 41, 185, 73)
      
      doc.setFontSize(10)

      doc.setFont('Quicksand-Bold', 'bold')
      doc.Text(`Att: ${laudo.name},`, 25, 125)
      
      doc.setFont('Quicksand-Regular', 'normal')
      doc.Text(`Conforme solicitado, segue orçamento para`, 25, 146)
      doc.setFont('Quicksand-Bold', 'bold')
      doc.Text(`assistência técnica`, 231, 146)
      doc.setFont('Quicksand-Regular', 'normal')
      doc.Text(`da câmera abaixo:`, 324, 146)

      doc.Text(`Laudo nº #${laudo.id}`, 25, 167)

      doc.setDrawColor('#707070')
      doc.rect(25, 189, 545, 52)
      doc.line(25, 215, 570, 215)
      doc.line(104, 189, 104, 241)
      doc.line(219, 189, 219, 241)
      doc.line(425, 189, 425, 241)

      doc.setFont('Quicksand-Bold', 'bold')
      doc.Text(`Nota Fiscal`, 37, 196)
      doc.Text(`Nº de Série`, 135, 196)
      doc.Text(`Modelo`, 304, 196)
      doc.Text(`Garantia`, 479, 196)

      doc.setFont('Quicksand-Regular', 'normal')
      doc.Text(`${laudo.cameraNFE}`, 63, 223, { align: 'center' })
      doc.Text(`${laudo.serial_number}`, 161, 223, { align: 'center' })
      doc.Text(`${laudo.model}`, 322, 223, { align: 'center' })
      doc.Text(`${laudo.warranty}`, 500, 223, { align: 'center' })

      const tHeight = 26 + (laudo.flaws.length * 26)
      
      doc.rect(25, 273, 545, tHeight)
      doc.line(25, 299, 570, 299)
      doc.line(425, 273, 425, 273 + tHeight)
      
      doc.setFont('Quicksand-Bold', 'bold')
      doc.Text(`Serviços da assistência`, 219, 280, { align: 'center' })
      doc.Text(`Valor`, 500, 280, { align: 'center' })
      
      doc.setFont('Quicksand-Regular', 'normal')
      laudo.flaws.forEach((v, i) => {
        doc.Text(v.description, 37, 307 + (26 * i))
        doc.Text(laudo.warranty === 'Válida' ? 'Gratuito' : `R$ ${v.customerValue.toFixed(2).toLocaleString()}`, 500, 307 + (26 * i), { align: 'center' })
        if (i + 1 < laudo.flaws.length)
        doc.line(25, 326 + (26 * i), 570, 326 + (26 * i))
      })

      let offset = (laudo.flaws.length * 26) - 26

      const replaceLast = (x, y, z) => {
        var a = x.split("")
        a[x.lastIndexOf(y)] = z
        return a.join("")
      }

      let offsetDesc = 0
      let finalString = ''
      if (laudo.logDescription.length > 108) {
        for (let i = 0; i < laudo.logDescription.length / 108; i++) { 
          let element = laudo.logDescription.slice(i + (108*i), i + (108*i) + 108 + 1);

          const hasBreakline = element.includes('\n')
          
          if (element.length >= 108 && !hasBreakline) {
            element = replaceLast(element, ' ', '\n')
          }

          if (hasBreakline) {
            offsetDesc += 24
          }

          finalString += element
        }
      } else {
        finalString = laudo.logDescription
      }
      
      doc.rect(25, 357 + offset, 545, 52 + Math.floor(12 * (finalString.length / 108) + 12) + offsetDesc)
      doc.line(25, 383 + offset, 570, 383 + offset)

      doc.setFont('Quicksand-Bold', 'bold')
      doc.Text(`Observações`, 287, 364 + offset, { align: 'center' })

      doc.setFont('Quicksand-Regular', 'normal')
      doc.Text(finalString, 37, 390 + offset)
      
      offset += Math.floor(12 * (finalString.length / 108))

      doc.setFont('Quicksand-Bold', 'bold')
      doc.Text('Garantia de 6 meses referente aos consertos realizados previamente.', 37, 390 + offset + 12 + offsetDesc)

      doc.setFont('Quicksand-Regular', 'normal')
      doc.Text(`Belo Horizonte, ${date.getDate() < 9 ? '0' + date.getDate() : date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}.`, 26, 533 + offset - 80)

      doc.setFont('Quicksand-Bold', 'bold')
      doc.Text(`Forma de Pagamento:`, 135, 593 + offset - 80, { align: 'right' })
      doc.Text(`Validade da Proposta:`, 135, 609 + offset - 80, { align: 'right' })
      doc.Text(`Frete:`, 135, 625 + offset - 80, { align: 'right' })

      doc.setFont('Quicksand-Regular', 'normal')
      doc.Text(`à vista (depósito bancário)`, 155, 593 + offset - 80)
      doc.Text(`10 dias`, 155, 609 + offset - 80)
      doc.Text(`${laudo.shippingType === 0 ? 'Não incluso' : laudo.shippingType === 1 ? `R$ ${laudo.shipping.toFixed(2)}` : 'Gratuito'}`, 155, 625 + offset - 80)

      doc.Text('Desde já agradecemos a atenção dispensada.', 26, 676 + offset - 80)
      doc.Text('Estamos à disposição para quaisquer esclarecimentos que se fizerem necessários.', 26, 689 + offset - 80)

      doc.Text('Atenciosamente,', 26, 744 + offset - 80)
      doc.setFont('Quicksand-Bold', 'bold')
      doc.Text('Assistência Técnica', 26, 758 + offset - 80)
      doc.setFont('Quicksand-Regular', 'normal')
      doc.Text('(31) 3222-8012 | (31) 97187-6936', 26, 772 + offset - 80)
      doc.Text('assistencia@wenature.com.br', 26, 786 + offset - 80)
      doc.Text('www.lognature.com.br', 26, 800 + offset - 80)

      let totalValue = 0

      if (laudo.warranty !== 'Válida') {
        for (let i = 0; i < laudo.flaws.length; i++) {
          const element = laudo.flaws[i]
          totalValue += element.customerValue
        }
      
        if (laudo.shippingType === 1)
          totalValue += laudo.shipping
      } else {
        totalValue = 'Gratuito'
      }

      doc.setFillColor(0,0,0,25)
      doc.rect(470, 593 + offset - 80, 100, 50, 'FD')
      doc.setFont('Quicksand-Bold', 'bold')
      doc.Text('Valor total', 520, 593 + offset - 70, { align: 'center' })
      doc.setFont('Quicksand-Regular', 'normal')
      doc.Text(`${laudo.warranty !== 'Válida' ? 'R$ ' : ''}${totalValue.toLocaleString()}`, 520, 593 + offset - 70 + 15, { align: 'center' })

      doc.setFontSize(7)
      doc.Text('WENATURE TECNOLOGIA LTDA', 570, 768 + offset - 80, { align: 'right' })
      doc.Text('CNPJ: 43.336.996/0001-13', 570, 778 + offset - 80, { align: 'right' })
      doc.Text('Rua dos Guajajaras, 870/1401 | Centro', 570, 790 + offset - 80, { align: 'right' })
      doc.Text('www.lognature.com.br | assistencia@wenature.com.br', 570, 802 + offset - 80, { align: 'right' })
    
      if (!save) {
        var string = doc.output('datauristring');
        var embed = <embed type="application/pdf" width="100%" height="500" src={string}/>
      
        resolve(embed)
      } else {
        resolve(doc.save(`Laudo #${laudo.id} WeNature`))
      }

    } catch (e) {
      reject(e)
    }
  })
}

export default generatePDF