import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

// Pages
import ProductsPage from './pages/ProductsPage'
import UsersPage from './pages/Settings/UsersPage'
import UserSettingsPage from './pages/Settings/UserSettings'
import CDNPage from './pages/CDNPage'
import DashboardSetttingsPage from './pages/Settings/DashboardSettings'
import WarningsPage from './pages/ecommerce/WarningsPage'
import SearchesPage from './pages/reports/SearchesPage'
import WarrantiesPage from './pages/technical-assistance/WarrantiesPage'
import NovosLaudosPage from './pages/technical-assistance/NovosLaudosPage'
import LaudosPage from './pages/technical-assistance/LaudosPage'
import LaudoPage from './pages/technical-assistance/LaudoPage'
import CustomersPage from './pages/customer/CustomersPage'
import CustomerPage from './pages/customer/CustomerPage'
import FlawsPage from './pages/technical-assistance/FlawsPage'
import AssistancePage from './pages/reports/AssistancePage'
import RentalProductsPage from './pages/rental/RentalProductsPage'
import RentalProductPage from './pages/rental/RentalProductPage'
import RentalModelsPage from './pages/rental/RentalModelsPage'
import RentalModelPage from './pages/rental/RentalModelPage'
import RentalRequestsPage from './pages/rental/RentalRequestsPage'
import RentalBookingsPage from './pages/rental/RentalBookingsPage'
import RentalBookingPage from './pages/rental/RentalBookingPage'

class Dashboard extends Component {
  componentDidMount() {
    document.title = 'Dashboard | LOG-in'
  }

  render() {
    return (
      <iframe
        title="dashboard"
        src="https://dashboard.lognature.com.br/"
        height="600"
        width="100%"
        scrolling="no"
        frameborder="0"
      />
    )
  }
}

export default class Routes extends Component {
  render() {
    return (
      <Route
        render={({ location }) => (
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/products" component={ProductsPage} />
            <Route exact path="/cdn" component={CDNPage} />
            <Route exact path="/users" component={UsersPage} />
            <Route
              exact
              path="/dashboard-settings"
              component={DashboardSetttingsPage}
            />
            <Route exact path="/user-settings" component={UserSettingsPage} />
            <Route exact path="/ecommerce/warnings" component={WarningsPage} />
            <Route
              exact
              path="/rental/requests"
              component={RentalRequestsPage}
            />
            <Route
              exact
              path="/rental/bookings"
              component={RentalBookingsPage}
            />
            <Route
              exact
              path="/rental/bookings/:id"
              component={RentalBookingPage}
            />
            <Route
              exact
              path="/rental/products"
              component={RentalProductsPage}
            />
            <Route
              exact
              path="/rental/products/:id"
              component={RentalProductPage}
            />
            <Route exact path="/rental/models" component={RentalModelsPage} />
            <Route
              exact
              path="/rental/models/:id"
              component={RentalModelPage}
            />
            <Route
              exact
              path="/technical-assistance/warranties"
              component={WarrantiesPage}
            />
            <Route
              exact
              path="/technical-assistance/solicitacoes"
              component={NovosLaudosPage}
            />
            <Route
              exact
              path="/technical-assistance/laudos"
              component={LaudosPage}
            />
            <Route
              exact
              path="/technical-assistance/laudos/:id"
              component={LaudoPage}
            />
            <Route
              exact
              path="/technical-assistance/servicos"
              component={FlawsPage}
            />
            <Route exact path="/customers" component={CustomersPage} />
            <Route exact path="/customers/:id" component={CustomerPage} />
            <Route exact path="/reports/searches" component={SearchesPage} />
            <Route
              exact
              path="/reports/technical-assistance"
              component={AssistancePage}
            />
          </Switch>
        )}
      ></Route>
    )
  }
}
