import React, { Component } from 'react'
import { Alert, Button, CheckPicker, ControlLabel, Form, FormControl, FormGroup, Modal, SelectPicker } from 'rsuite'
import { ReadableDate } from '../helper/date'
import api from '../services/api'

class UserModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false,
      overflow: true,
      loading: true
    }
    this.close = this.close.bind(this)
    this.open = this.open.bind(this)
  }
  close () {
    this.props.handleClose()
    this.setState({ show: false, rows: 0 })
  }
  open (event) {
    this.setState({ show: true })
  }
  componentDidMount () {
    this.setState({ show: this.props.show ? this.props.show : false })
  }
  componentWillReceiveProps (nextProps) {
    this.setState({
      show: nextProps.show ? nextProps.show : false,
      user: nextProps.user
    })
  }
  render () {
    const { show } = this.state

    let { user } = this.props

    user = user ? user.rowData : null

    return (
      <div className='modal-container'>
        <Modal show={show} overflow={false} onHide={this.close}>
          <Modal.Header>
            <h1>{user ? user.name : null}</h1>
          </Modal.Header>
          <Modal.Body>
            {user ? (
              <React.Fragment>
                <h3>
                  <b>Dados</b>
                </h3>
                <h4>Permissões: </h4>
                <ul>
                  {user.roles.map((v, i) => {
                    let x
                    switch (v) {
                      case 'admin':
                        x = 'Administrador'
                        break
                      case 'technician':
                        x = 'Técnico'
                        break
                      case 'employee':
                        x = 'Funcionário'
                        break
                      default:
                        x = v
                        break
                    }
                    return <li>{x}</li>
                  })}
                </ul>
                <h4>Nome de usuário: {user.username}</h4>
                <h4>E-mail: {user.email}</h4>
                <h4>
                  Possui foto:{' '}
                  {user.picture && user.picture.length > 0 ? 'Sim' : 'Não'}
                </h4>
                <hr />
                <h3>
                  <b>Detalhes técnicos</b>
                </h3>
                <h4>ID: {user._id}</h4>
                <h4>Desde: {ReadableDate(user.createdAt)}</h4>
                <h4>Atualizado em: {ReadableDate(user.updatedAt)}</h4>
              </React.Fragment>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close} appearance='primary'>
              Ok
            </Button>
            <Button onClick={this.close} appearance='subtle'>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

class CreateModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false,
      overflow: true,
      loading: true,
      formValue: null
    }
    this.close = this.close.bind(this)
    this.open = this.open.bind(this)
    this.submit = this.submit.bind(this)
    this.update = this.update.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  close () {
    this.props.handleClose()
    this.setState({ show: false, rows: 0 })
  }
  open (event) {
    this.setState({ show: true })
  }
  componentDidMount () {
    this.setState({ show: this.props.show ? this.props.show : false })
  }
  componentWillReceiveProps (nextProps) {
    this.setState({
      show: nextProps.show ? nextProps.show : false,
      user: nextProps.user
    })
  }

  handleChange (val) {
    // console.log(val)
    this.setState({ formValue: val })
  }

  update () {
    this.props.update()
    this.setState({ formValue: null })
  }

  submit () {
    let { formValue } = this.state

    if (!formValue) return Alert.error('Preencha o formulário')

    if (
      !formValue['name'] ||
      !formValue['username'] ||
      !formValue['email'] ||
      !formValue['password']
    ) {
      return Alert.error('Preencha todos os campos')
    }

    let { name, username, email, password, roles } = formValue

    let toSendData = {
      name,
      username,
      email,
      password,
      roles
    }

    api
      .post('/users/new', toSendData)
      .then(response => response.data)
      .then(res => {
        if (res.error) return Alert.error(res.error)
        else Alert.success('Usuário adicionado com sucesso')

        this.close()
        this.update()
      })
      .catch(error => {
        Alert.error(error.response.data.error)
      })
  }

  render () {
    const { show } = this.state

    return (
      <div className='modal-container'>
        <Modal show={show} overflow={false} onHide={this.close}>
          <Modal.Header>
            <h1>Adicionar usuário</h1>
          </Modal.Header>
          <Modal.Body>
            <Form
              fluid
              onChange={this.handleChange}
              formValue={this.state.formValue}
            >
              <FormGroup>
                <ControlLabel>Nome de usuário</ControlLabel>
                <FormControl name='username' />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Nome completo</ControlLabel>
                <FormControl name='name' />
              </FormGroup>

              <FormGroup>
                <ControlLabel>E-mail</ControlLabel>
                <FormControl name='email' type='email' />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Senha</ControlLabel>
                <FormControl name='password' type='password' />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Permissões</ControlLabel>
                <FormControl
                  accepter={CheckPicker}
                  name='roles'
                  placement='auto'
                  placeholder='Selecione...'
                  block
                  data={[
                    { label: 'Funcionário', value: 'employee' },
                    { label: 'Técnico', value: 'technician' },
                    { label: 'Administrador', value: 'admin' }
                  ]}
                />
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.submit} appearance='primary'>
              Ok
            </Button>
            <Button onClick={this.close} appearance='subtle'>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

class EditModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false,
      overflow: true,
      loading: true,
      formValue: null
    }
    this.close = this.close.bind(this)
    this.open = this.open.bind(this)
    this.submit = this.submit.bind(this)
    this.update = this.update.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  close () {
    this.props.handleClose()
    this.setState({ show: false, rows: 0 })
  }
  open (event) {
    this.setState({ show: true })
  }
  componentDidMount () {
    this.setState({ show: this.props.show ? this.props.show : false })
  }
  componentWillReceiveProps (nextProps) {
    this.setState({
      show: nextProps.show ? nextProps.show : false,
      formValue: nextProps.data
    })
  }

  handleChange (val) {
    // console.log(val)
    this.setState({ formValue: val })
  }

  update () {
    this.props.update()
    this.setState({ formValue: null })
  }

  submit () {
    let { formValue } = this.state

    if (!formValue) return Alert.error('Preencha o formulário')

    if (!formValue['name'] || !formValue['username'] || !formValue['email'] || formValue['active'] == null) {
      return Alert.error('Os campos "nome", "nome de usuário" e "email" são obrigatórios.')
    }

    let { name, email, password, roles, _id, active } = formValue

    let toSendData = {
      name,
      email,
      password,
      roles,
      _id,
      active
    }

    api
      .put('/user', toSendData)
      .then(response => response.data)
      .then(res => {
        if (res.error) return Alert.error(res.error)
        else Alert.success('Usuário editado com sucesso')

        this.close()
        this.update()
      })
      .catch(error => {
        Alert.error(error.response.data.error)
      })
  }

  render () {
    const { show } = this.state

    return (
      <div className='modal-container'>
        <Modal show={show} overflow={false} onHide={this.close}>
          <Modal.Header>
            <h1>Editar usuário</h1>
          </Modal.Header>
          <Modal.Body>
            <Form
              fluid
              onChange={this.handleChange}
              formValue={this.state.formValue}
            >
              <input type='hidden' autoComplete={false} />
              <FormGroup>
                <ControlLabel>Nome de usuário</ControlLabel>
                <FormControl name='username' />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Nome completo</ControlLabel>
                <FormControl name='name' />
              </FormGroup>

              <FormGroup>
                <ControlLabel>E-mail</ControlLabel>
                <FormControl name='email' type='email' />
              </FormGroup>

              <FormGroup>
                <ControlLabel>
                  Senha (ao deixar em branco, não haverá modificações)
                </ControlLabel>
                <FormControl
                  autocomplete='new-password'
                  name='password'
                  type='password'
                />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Permissões</ControlLabel>
                <FormControl
                  accepter={CheckPicker}
                  name='roles'
                  placement='auto'
                  placeholder='Selecione...'
                  block
                  data={[
                    { label: 'Funcionário', value: 'employee' },
                    { label: 'Técnico', value: 'technician' },
                    { label: 'Administrador', value: 'admin' }
                  ]}
                />
              </FormGroup>

              <FormGroup>
                <ControlLabel>Status</ControlLabel>
                <FormControl
                  accepter={SelectPicker}
                  name='active'
                  placement='auto'
                  placeholder='Selecione...'
                  block
                  data={[
                    { label: 'Ativo', value: true },
                    { label: 'Inativo', value: false }
                  ]}
                />
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.submit} appearance='primary'>
              Ok
            </Button>
            <Button onClick={this.close} appearance='subtle'>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export { UserModal, CreateModal, EditModal }

