import React, { Component } from 'react'
import { Sidenav, Nav, Dropdown, Icon } from 'rsuite'
import { withRouter } from 'react-router-dom'

const items = [
  {
    name: 'Dashboard',
    key: 'dashboard',
    icon: 'dashboard',
    link: '/',
  },
  {
    name: 'Loja virtual',
    key: 'ecommerce',
    icon: 'shopping-cart',
    children: [
      {
        name: 'Avisos',
        key: 'ecommerce-warnings',
        link: '/ecommerce/warnings',
      },
    ],
  },
  {
    name: 'Locação',
    key: 'rental',
    icon: 'cube',
    children: [
      {
        name: 'Solicitações',
        key: 'rental-requests',
        link: '/rental/requests',
      },
      {
        name: 'Contratos',
        key: 'rental-bookings',
        link: '/rental/bookings',
      },
      {
        name: 'Produtos',
        key: 'rental-products',
        link: '/rental/products',
      },
      {
        name: 'Modelos',
        key: 'rental-models',
        link: '/rental/models',
      },
    ],
  },
  {
    name: 'Assistência técnica',
    key: 'technical-assistance',
    icon: 'medkit',
    children: [
      {
        name: 'Solicitações',
        key: 'solicitacoes',
        link: '/technical-assistance/solicitacoes',
      },
      {
        name: 'Laudos',
        key: 'laudos',
        link: '/technical-assistance/laudos',
      },
      {
        name: 'Garantias',
        key: 'warranties',
        link: '/technical-assistance/warranties',
      },
      {
        name: 'Serviços',
        key: 'servicos',
        link: '/technical-assistance/servicos',
      },
    ],
  },
  {
    name: 'Produtos',
    key: 'products',
    icon: 'archive',
    link: '/products',
  },
  {
    name: 'Clientes',
    key: 'customers',
    icon: 'user-info',
    link: '/customers',
  },
  {
    name: 'CDN',
    key: 'cdn',
    icon: 'cloud-upload',
    link: '/cdn',
  },
  {
    name: 'Configurações',
    key: 'settings',
    icon: 'gear-circle',
    children: [
      {
        name: 'Usuários',
        key: 'settings-users',
        link: '/users',
      },
      {
        name: 'Dashboard',
        key: 'settings-dashboard',
        link: '/dashboard-settings',
      },
    ],
  },
  {
    name: 'Relatórios',
    key: 'reports',
    icon: 'line-chart',
    children: [
      {
        name: 'Buscador do site',
        key: 'reports-searches',
        link: '/reports/searches',
      },
      {
        name: 'Assistência técnica',
        key: 'reports-technical-assistance',
        link: '/reports/technical-assistance',
      },
    ],
  },
]

class SideBar extends Component {
  constructor() {
    super()
    this.state = {
      expanded: true,
      activeKey: 'dashboard',
    }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }
  componentDidMount() {
    this.setState({ expanded: this.props.expanded })
  }
  handleToggle() {
    this.setState({
      expanded: !this.state.expanded,
    })
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    })
    this.props.handleSelect()
  }
  render() {
    if (this.props.selected === false) {
      this.handleSelect('')
    }

    return (
      <Sidenav
        expanded={this.props.expanded}
        defaultOpenKeys={[]}
        activeKey={this.state.activeKey}
        onSelect={this.handleSelect}
      >
        <Sidenav.Body>
          <Nav>
            {items.map((v, i) => {
              if (v.children)
                return (
                  <Dropdown
                    placement="rightTop"
                    eventKey={v.key}
                    title={v.name}
                    icon={<Icon icon={v.icon} />}
                  >
                    {v.children.map((v) => (
                      <Dropdown.Item
                        onClick={() => this.props.history.push(v.link)}
                        eventKey={v.key}
                      >
                        {v.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                )

              return (
                <Nav.Item
                  onClick={() => this.props.history.push(v.link)}
                  eventKey={v.key}
                  icon={<Icon icon={v.icon} />}
                >
                  {v.name}
                </Nav.Item>
              )
            })}
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    )
  }
}

export default withRouter(SideBar)
