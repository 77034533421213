import React, { Component } from 'react';
import { Alert, Button, ButtonToolbar, Container, Divider, Icon, Panel, PanelGroup } from 'rsuite';
import ConfirmationPopup from '../../components/ConfirmationPopup';
import { CreateModal, EditModal, UserModal } from '../../components/UserModal';
import UsersTable from '../../components/UsersTable';
import api from '../../services/api';

export default class UsersPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      userModal: false,
      createModal: false,
      editModal: false,
      user: null,
      editUser: null
    }

    this.handleDelete = this.handleDelete.bind(this)
  }  
  handleDelete(rowData) {
    ConfirmationPopup(`Deseja excluir o usuário ${rowData.username}?`)
    .then(deleted => {
      if (deleted)
        api.delete(`/user/${rowData._id}`)
        .then(response => response.data)
        .then(response => {
          if (!response.error)
            Alert.success('Usuário excluído com sucesso.')

          this.loadUsers()
        })
    })
  }
  loadUsers() {
    api.get('/users')
    .then((response) => {
      return response.data
    })
    .then((response) => {
      this.setState({data: response})
    })
  }
  componentDidMount() {
    document.title = 'Usuários | LOG-in'
    this.loadUsers()
  }
  render() {
    let { data, userModal, createModal, editModal, user, editUser } = this.state
    return (
      <Container className="padding-left">
        <h1>Usuários</h1>
        <Divider />
        <PanelGroup bordered>
          <Panel header='Ações'>
            <ButtonToolbar>
              <Button color="primary" onClick={() => this.setState({createModal: true})}>
                <Icon icon="plus"/>
                <span className="padding-left">
                  Adicionar usuário
                </span>
              </Button>
            </ButtonToolbar>
          </Panel>
          <Panel>
            <UsersTable handleDelete={this.handleDelete} handleModal={(user) => this.setState({userModal: true, user})} handleEdit={(user) => this.setState({editModal: true, editUser: user})} modal={userModal} data={data}/>
          </Panel>
        </PanelGroup>
        <br/>
        <CreateModal update={() => this.loadUsers()} show={createModal} handleClose={() => this.setState({createModal: false})} />
        <EditModal data={editUser} update={() => this.loadUsers()} show={editModal} handleClose={() => this.setState({editModal: false})} />
        <UserModal user={user} show={userModal} handleClose={() => this.setState({userModal: false})} />
      </Container>
    )
  }
}