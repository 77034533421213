import React, { Component } from 'react'
import {
  Divider,
  Col,
  Row,
  Container,
  Alert,
  Panel,
  SelectPicker,
  Button,
  Icon,
  ButtonToolbar,
  Whisper,
  Tooltip,
  PanelGroup,
} from 'rsuite'
import ProductsTable from '../components/ProductsTable'
import ProductModal from '../components/ProductModal'
import api from '../services/api'

export default class ProductsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products: null,
      product: null,
      modal: false,
      source: 'log',
      loading: true,
      syncingStock: false,
      syncingProducts: false,
    }
    this.handleModal = this.handleModal.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.syncProducts = this.syncProducts.bind(this)
    this.syncStock = this.syncStock.bind(this)
  }
  componentDidMount() {
    this.getProducts()
    document.title = 'Produtos | LOG-in'
  }
  getProducts() {
    this.setState({ loading: true })
    const { source } = this.state
    // console.log(source)
    api
      .get('/products/' + source)
      .then(products => {
        return products.data
      })
      .then(products => {
        this.setState({ products, loading: false })
      })
  }
  handleModal(product) {
    product = product.rowData
    this.setState({ product: product })
    // console.log(this.state.product)
    setTimeout(() => this.setState({ modal: true }), 0)
  }
  handleSourceChange(source) {
    this.setState({ source })
    setTimeout(() => {
      this.getProducts()
    }, 500)
  }
  syncStock() {
    this.setState({ syncingStock: true })
    api.get('/sync/stock')
      .then(response => {
        return response.data
      })
      .then(response => {
        if (response.error) Alert.error(response.error)
        else Alert.success(response.data)
        this.setState({ syncingStock: false })
      })
  }
  syncProducts() {}
  render() {
    const syncStockTooltip = (
      <Tooltip>
        Atualiza o estoque da XTech de acordo com o estoque do VHSYS.
      </Tooltip>
    )
    const syncProductsTooltip = (
      <Tooltip>
        Atualiza os produtos do VHSYS de acordo com os produtos da XTech.
      </Tooltip>
    )
    var {
      products,
      product,
      modal,
      loading,
      source,
      syncingProducts,
      syncingStock,
    } = this.state
    return (
      <Container className='padding-left'>
        <h1>Produtos</h1>
        <Divider />
        <div className=''>
          <PanelGroup bordered>
            <Panel bordered>
              <Row>
                <Col md={5}>
                  <h4>Mostrar produtos de:</h4>
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    onChange={this.handleSourceChange}
                    defaultValue='vhsys'
                    data={[
                      { label: 'VHSYS', value: 'vhsys' }
                    ]}
                  />
                </Col>
                <Col>
                  <br />
                  <ButtonToolbar>
                    <Whisper
                      placement='top'
                      trigger='hover'
                      speaker={syncStockTooltip}
                    >
                      <Button
                        color='primary'
                        disabled={syncingStock}
                        onClick={this.syncStock}
                      >
                        <Icon
                          icon={syncingStock ? 'spinner' : 'archive'}
                          pulse={syncingStock}
                        />
                        <span className='padding-left'>
                          Sincronizar estoque
                        </span>
                      </Button>
                    </Whisper>
                    <Whisper
                      placement='top'
                      trigger='hover'
                      speaker={syncProductsTooltip}
                    >
                      <Button
                        color='blue'
                        disabled={syncingProducts}
                        onClick={this.syncProducts}
                      >
                        <Icon
                          icon={syncingProducts ? 'spinner' : 'refresh'}
                          pulse={syncingProducts}
                        />
                        <span className='padding-left'>
                          Sincronizar produtos
                        </span>
                      </Button>
                    </Whisper>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Panel>
            <Panel>
              <ProductsTable
                loading={loading}
                data={products}
                handleModal={this.handleModal}
              />
            </Panel>
          </PanelGroup>
          <ProductModal
            source={source}
            show={modal}
            product={product}
            handleClose={() => this.setState({ modal: false })}
          />
        </div>
      </Container>
    )
  }
}
