/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'rsuite'
import { ReadableDate } from '../helper/date'
import api from '../services/api'

const { Cell, HeaderCell, Column, Pagination: TablePagination } = Table

export default function LaudoUpdatesTable({ ...props }) {
  const [data, setData] = useState([])
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortType, setSortType] = useState('asc')
  const [displayLength, setDisplayLength] = useState(10)
  const [page, setPage] = useState(1)
  const [users, setUsers] = useState([])

  useEffect(() => {
    async function resolve(nextProps) {
      if (nextProps.data) {
        setData(nextProps.data)
      }
    }

    resolve(props)
  }, [props.data])

  useEffect(() => {
    ;(async () => {
      const { data: users } = await api.get('/users')
      setUsers(users)
    })()
  }, [data])

  function getData() {
    let resultData = [...data]

    if (sortColumn && sortType) {
      resultData = resultData.sort((a, b) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (typeof x === 'number' && typeof y === 'number') {
          if (sortType === 'asc') return x < y
          else return x > y
        }

        if (typeof x === 'string') {
          x = x.charCodeAt()
        }
        if (typeof y === 'string') {
          y = y.charCodeAt()
        }
        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }

    return resultData
  }

  function handleSortColumn(sortColumn, sortType) {
    setSortColumn(sortColumn)
    setSortType(sortType)
  }

  function handleChangePage(dataKey) {
    setPage(dataKey)
  }

  function handleChangeLength(dataKey) {
    setPage(1)
    setDisplayLength(dataKey)
  }

  const originalData = getData()

  const newData = originalData.filter((v, i) => {
    const start = displayLength * (page - 1)
    const end = start + displayLength
    return i >= start && i < end
  })

  const status = useSelector((state) => state.statuses)

  const getStatus = useCallback(
    (id) => {
      return status.find((x) => x._id === id) || { name: 'N/A' }
    },
    [status]
  )

  return (
    <div>
      <Table
        data={newData}
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={handleSortColumn}
        loading={props.loading}
        height={500}
      >
        <Column width={250}>
          <HeaderCell>Status anterior</HeaderCell>
          <Cell dataKey="oldStatus">
            {(row) => getStatus(row.oldStatus).name}
          </Cell>
        </Column>
        <Column width={250}>
          <HeaderCell>Novo status</HeaderCell>
          <Cell dataKey="newStatus">
            {(row) => getStatus(row.newStatus).name}
          </Cell>
        </Column>
        <Column width={200}>
          <HeaderCell>Usuário</HeaderCell>
          <Cell dataKey="user">
            {(row) => {
              const user = users.find((x) => x._id === row.user)

              if (user) {
                return user.name
              }

              return row.user
            }}
          </Cell>
        </Column>
        <Column width={200}>
          <HeaderCell>Data</HeaderCell>
          <Cell dataKey="createdAt">
            {(row) => ReadableDate(row.createdAt)}
          </Cell>
        </Column>
      </Table>
      <TablePagination
        lengthMenu={[
          {
            value: 10,
            label: 10,
          },
          {
            value: 20,
            label: 20,
          },
        ]}
        activePage={page}
        displayLength={displayLength}
        total={originalData.length}
        onChangePage={handleChangePage}
        onChangeLength={handleChangeLength}
      />
    </div>
  )
}
