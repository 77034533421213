import React, { useState, useEffect } from 'react'
import { Icon } from 'rsuite'

const LoadingScreen = ({transition, ...props}) => {

  const [loading, setLoading] = useState(1)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      if (transition && loading === 1) setLoading(0)
      setTimeout(() => setVisible(false), 1000)
    }, 1)
  })

  if (visible)
  return (
    <div className='loading-screen' style={{opacity: loading}}>
      <Icon icon='spinner' size='3x' pulse style={{color: 'white'}}/>
    </div>
  )

  return null
}

export default LoadingScreen