import React, { useState, useEffect } from 'react'
import {
  Divider,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Container,
  Alert,
  Panel,
  SelectPicker,
  Button,
  Icon,
  ButtonToolbar,
  PanelGroup,
  Input,
  InputGroup,
  Modal,
  Schema,
} from 'rsuite'
import RentalProductsTable from '../../components/RentalProductsTable'
import api from '../../services/api'
import { ReadableDate } from '../../helper/date'
import ConfirmationPopup from '../../components/ConfirmationPopup'

function CreateModal({ ...props }) {
  const show = props.show ? props.show : false
  const [formValue, setFormValue] = useState({
    name: '',
    email: '',
    document: '',
    telephone: '',
  })
  const handleClose = props.onClose
  const handleRefresh = props.onRefresh

  const { StringType, NumberType } = Schema.Types

  const model = Schema.Model({
    model: StringType().isRequired('O campo "modelo" é obrigatório.'),
    serialNumber: StringType().isRequired(
      'O campo "número de série" é obrigatório.'
    ),
    fabYear: NumberType().isRequired(
      'O campo "ano de fabricação" é obrigatório.'
    ),
    description: StringType(),
    picture: StringType().isRequired('O campo "foto" é obrigatório.'),
    dailyPrice: NumberType().isRequired(
      'O campo "preço diário" é obrigatório.'
    ),
    monthlyPrice: NumberType().isRequired(
      'O campo "preço mensal" é obrigatório.'
    ),
    weeklyPrice: NumberType().isRequired(
      'O campo "preço quinzenal" é obrigatório.'
    ),
    weight: NumberType().isRequired('O campo "peso" é obrigatório.'),
    height: NumberType().isRequired('O campo "altura" é obrigatório.'),
    width: NumberType().isRequired('O campo "comprimento" é obrigatório.'),
    length: NumberType().isRequired('O campo "largura" é obrigatório.'),
    ledType: StringType().isOneOf([
      'Low glow',
      'No glow',
      'Low glow com visor',
      'No glow com visor',
    ]),
  })

  const [, setFormModel] = useState(model)

  const handleSubmit = async () => {
    const errors = model.check(formValue)
    let hasError = false

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key]
        if (element.hasError === true) {
          hasError = element.errorMessage
          break
        }
      }
    }

    if (hasError) {
      return Alert.error(hasError)
    }

    await api.post('/rental/products', formValue)

    Alert.success('Produto inserido com sucesso!')

    handleRefresh()
    handleClose()
  }

  return (
    <Modal show={show} overflow={false} onHide={handleClose}>
      <Modal.Header>
        <h1>Adicionar produto</h1>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          onChange={setFormValue}
          formValue={formValue}
          onCheck={setFormModel}
          model={model}
        >
          <FormGroup>
            <ControlLabel>Modelo</ControlLabel>
            <FormControl name="model" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Número de série</ControlLabel>
            <FormControl name="serialNumber" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Ano de fabricação</ControlLabel>
            <FormControl name="fabYear" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Descrição</ControlLabel>
            <FormControl name="description" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Foto</ControlLabel>
            <FormControl name="picture" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Preço diário</ControlLabel>
            <FormControl name="dailyPrice" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Preço quinzenal</ControlLabel>
            <FormControl name="weeklyPrice" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Preço mensal</ControlLabel>
            <FormControl name="monthlyPrice" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Peso (gramas)</ControlLabel>
            <FormControl name="weight" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Altura (cm)</ControlLabel>
            <FormControl name="height" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Comprimento (cm)</ControlLabel>
            <FormControl name="width" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Largura (cm)</ControlLabel>
            <FormControl name="length" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Tipo de LED</ControlLabel>
            <FormControl
              name="ledType"
              block
              accepter={SelectPicker}
              searchable={false}
              data={[
                { value: 'No glow', label: 'No glow' },
                { value: 'Low glow', label: 'Low glow' },
                { value: 'No glow com visor', label: 'No glow com visor' },
                { value: 'Low glow com visor', label: 'Low glow com visor' },
              ]}
            />
          </FormGroup>

          <br />
          <br />
          <br />
          <br />
          <br />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} appearance="primary">
          Adicionar
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function ViewModal({ ...props }) {
  if (!props.data) return null

  const handleLink = () => {
    props.history.push('/rental/products/' + props.data._id)
  }

  const { id, model, serialNumber, createdAt, updatedAt } = props.data

  return (
    <Modal
      size="md"
      overflow={false}
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Header>
        <h2>Produto #{id}</h2>
      </Modal.Header>

      <Modal.Body>
        <h2>Dados do produto</h2>
        <h3>
          <b>Modelo:</b> {model.name}
        </h3>
        <h3>
          <b>Número de série:</b> {serialNumber}
        </h3>
        <h3>
          <b>Ano de fabricação:</b> {model.fabYear}
        </h3>
        <h3>
          <b>Tipo de LED:</b> {model.ledType}
        </h3>
        <h3>
          <b>Descrição:</b>
          <p>{model.description}</p>
        </h3>
        <h3>
          <b>Foto:</b> <a href={model.picture}>{model.picture}</a>
        </h3>
        <h3>
          <b>Preço diário:</b> {model.dailyPrice}
        </h3>
        <h3>
          <b>Preço quinzenal:</b> {model.weeklyPrice}
        </h3>
        <h3>
          <b>Preço mensal:</b> {model.monthlyPrice}
        </h3>
        <h3>
          <b>Peso (gramas):</b> {model.weight}
        </h3>
        <h3>
          <b>Altura (cm):</b> {model.height}
        </h3>
        <h3>
          <b>Comprimento (cm):</b> {model.width}
        </h3>
        <h3>
          <b>Largura (cm):</b> {model.length}
        </h3>
        <Divider />
        <h2>Informações gerais</h2>
        <h3>Criado em: {ReadableDate(createdAt)}</h3>
        {!props.new && <h3>Atualizado em: {ReadableDate(updatedAt)}</h3>}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.handleClose} appearance="primary">
          Fechar
        </Button>
        <Button onClick={() => handleLink()} appearance="ghost">
          Editar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function RentalProductsPage({ ...props }) {
  const [products, setProducts] = useState(false)
  const [selected, setSelected] = useState(null)
  const [filters, setFilters] = useState({ search: '' })
  const [createModal, setCreateModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)

  document.title = 'Produtos de locação | LOG-in'

  const getData = () => {
    api
      .get('/rental/products')
      .then((result) => {
        return result.data
      })
      .then((result) => {
        setProducts(result)
      })
  }

  const handleDelete = async (product) => {
    const shouldDelete = await ConfirmationPopup(
      `Deseja excluir o produto #${product.id}?`
    )
    if (shouldDelete) {
      await api.delete(`/rental/products/${product._id}`)
      Alert.success('Produto excluído com sucesso.')
      getData()
    }
  }

  useEffect(() => getData(), [])

  return (
    <Container className="padding-left">
      <h1>Produtos de locação</h1>
      <Divider />
      <PanelGroup bordered>
        <Panel header="Ações">
          <Row>
            <Col md={8} sm={12}>
              <ButtonToolbar>
                <Button color="primary" onClick={() => setCreateModal(true)}>
                  <Icon icon="plus" />
                  <span className="padding-left">Adicionar produto</span>
                </Button>
              </ButtonToolbar>
            </Col>
            <Col md={8} sm={12} mdOffset={8}>
              <InputGroup inside>
                <Input
                  data={filters.search}
                  onChange={(search) => {
                    setFilters({ ...filters, search })
                  }}
                  placeholder="Pesquisar"
                />
                <InputGroup.Button disabled>
                  <Icon icon="search" />
                </InputGroup.Button>
              </InputGroup>
            </Col>
          </Row>
        </Panel>
        <Panel>
          <RentalProductsTable
            handleShow={(data) => {
              setSelected(data)
              setViewModal(true)
            }}
            handleDelete={handleDelete}
            filers={filters}
            data={products}
            edit
            history={props.history}
          />
        </Panel>
      </PanelGroup>
      <CreateModal
        history={props.history}
        show={createModal}
        onClose={() => setCreateModal(false)}
        onRefresh={() => getData()}
      />
      <ViewModal
        history={props.history}
        data={selected}
        show={viewModal}
        handleClose={() => setViewModal(false)}
      />
    </Container>
  )
}
