/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Divider, Col, Row, Container, Panel, TagPicker, Form, FormControl, FormGroup, ControlLabel, PanelGroup, SelectPicker} from 'rsuite'
import api from '../../services/api'
import FlawsTable from '../../components/FlawsTable'

import { useSelector } from 'react-redux'

export default function AssistancePage({...props}) {

  document.title = 'Relatórios da assistência técnica | LOG-in'

  const [services, setServices] = useState([])
  const [flaws, setFlaws] = useState([])
  const [servicesFilters, setServicesFilters] = useState({})
  const [updating, setUpdating] = useState()

  const statuses = useSelector(state => state.statuses).map((v) => ({ ...v, label: v.name, value: v._id }))

  const getServices = () => {
    let getParams = () => {
      let x = ''
      for (const key in servicesFilters) {
        if (servicesFilters.hasOwnProperty(key)) {
          const value = servicesFilters[key];
          x+=`${key}=${value}&`
        }
      }
      return x
    }
    api.get('/reports/technical-assistance/services?'+getParams())
    .then(response => response.data)
    .then(setServices)
  }

  const getData = () => {
    api
      .get('/flaws')
      .then(response => {
        return response.data
      })
      .then(response => {
        if (response.length > 0) {
          response.forEach((v, i) => {
            response[i] = { ...v, label: v.description, value: v._id }
          })
        }
        setFlaws(response)
      })
  }

  useEffect(() => {
    if (!updating)
      setUpdating(setTimeout(getServices, 500))
    else {
      clearTimeout(updating)
      setUpdating(setTimeout(getServices, 500))
    }
  }, [servicesFilters])

  useEffect(() => {
    getServices()
    getData()
  }, [])

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();
  
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  return (
    <Container className="padding-left">
      <h1>Relatórios da assistência técnica</h1>
      <Divider />
      <PanelGroup accordion bordered>
        <Panel header='Serviços prestados' defaultExpanded>
          <Panel bordered>
            <Form 
              formValue={servicesFilters}
              onChange={setServicesFilters}
            >
              <Row>
                <Col sm={8}>
                  <FormGroup>
                    <ControlLabel>Data mínima</ControlLabel>
                    <FormControl name="start" type="date" />
                  </FormGroup>
                </Col>
                <Col sm={8}>
                  <FormGroup>
                    <ControlLabel>Data máxima</ControlLabel>
                    <FormControl name="end" type="date" />
                  </FormGroup>
                </Col>
                <Col sm={8}>
                  <FormGroup>
                    <ControlLabel>Termo da busca</ControlLabel>
                    <FormControl name="text" />
                  </FormGroup>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm={8}>
                  <FormGroup>
                    <ControlLabel>Descrição exata do serviço</ControlLabel>
                    <TagPicker
                      data={flaws.length > 0 ? flaws : []}
                      onChange={services => {
                        setServicesFilters({ ...servicesFilters, services })
                      }}
                      placeholder='Serviço'
                      style={{ width: '300px' }}
                      sort={() => {
                        return (a, b) => {
                          return compare(a.description, b.description)
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={8}>
                  <FormGroup>
                    <ControlLabel>Status do laudo</ControlLabel>
                    <TagPicker
                      data={statuses.length > 0 ? statuses : []}
                      onChange={status => {
                        setServicesFilters({ ...servicesFilters, status })
                      }}
                      placeholder='Status'
                      style={{ width: '300px' }}
                      sort={() => {
                        return (a, b) => {
                          return compare(a.name, b.name)
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={8}>
                  <FormGroup>
                    <ControlLabel>Garantia</ControlLabel>
                    <SelectPicker
                      data={[
                        {value: 'Não válida', label: 'Não válida'},
                        {value: 'Válida', label: 'Válida'},
                        {value: 'Expirada', label: 'Expirada'}
                      ]}
                      onChange={warranty => {
                        setServicesFilters({ ...servicesFilters, warranty })
                      }}
                      placeholder='Garantia'
                      style={{ width: '300px' }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <br/>
            <small>Valores de data referentes à criação do laudo.</small>
          </Panel>
          <Panel bordered>
            <FlawsTable reports data={services.data ? services.data : services}/>
          </Panel>
          <Panel bordered>
            {services.abstract && (
              <>
                <h2><b>Resumo</b></h2>
                <h4>Quantidade de serviços: {services.abstract.found}</h4>
                <h4>Total arrecadado (clientes): R$ {services.abstract.totalCustomer.toFixed(2)}</h4>
                <h4>Total gasto (técnico): R$ {services.abstract.totalTechnician.toFixed(2)}</h4>
              </>
            )}
          </Panel>
        </Panel>
      </PanelGroup>
    </Container>
  )
}