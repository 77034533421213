import React, { useState } from 'react'
import {
  Schema,
  Divider,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Container,
  Alert,
  Panel,
  Button,
  Icon,
  ButtonToolbar,
  PanelGroup,
  Modal,
} from 'rsuite'
import api from '../../services/api'
import { withRouter } from 'react-router-dom'

// eslint-disable-next-line no-unused-vars
import LaudosTable from '../../components/LaudosTable'
import LaudoModal from '../../components/LaudoModal'

const LaudoModalWithRouter = withRouter(LaudoModal)

function CreateModal({ ...props }) {
  const show = props.show ? props.show : false
  const [formValue, setFormValue] = useState({})
  const handleClose = props.onClose
  const handleRefresh = props.onRefresh

  const { StringType } = Schema.Types

  let model = Schema.Model({
    name: StringType().isRequired('O campo "nome" é obrigatório.'),
    email: StringType()
      .isRequired('O campo "email" é obrigatório.')
      .isEmail('Por favor, insira um e-mail válido.'),
    telephone: StringType(),
    serial_number: StringType()
      .minLength(10, 'O campo "número de série" exige 10 caracteres.')
      .maxLength(10, 'O campo "número de série" exige 10 caracteres.')
      .isRequired('O campo "número de série" é obrigatório.'),
    model: StringType().isRequired('O campo "modelo" é obrigatório.'),
    description: StringType().isRequired('O campo "descrição" é obrigatório.'),
  })

  const [, setFormModel] = useState(model)

  const handleSubmit = () => {
    const errors = model.check(formValue)
    let hasError = []

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key]
        if (element.hasError === true) {
          hasError.push(element.errorMessage)
        }
      }
    }

    if (hasError.length > 0) {
      hasError.forEach((v, i) => {
        setTimeout(() => {
          Alert.error(v)
        }, 100)
      })
      return
    }

    api
      .post('/laudos/new', formValue)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        if (!response.error) Alert.success('Laudo inserido com sucesso!')

        handleRefresh()
        handleClose()
      })
  }

  return (
    <Modal show={show} overflow={false} onHide={handleClose}>
      <Modal.Header>
        <h1>Adicionar solicitação</h1>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          onChange={setFormValue}
          formValue={formValue}
          onCheck={setFormModel}
          model={model}
        >
          <h2>Dados do cliente</h2>
          <FormGroup>
            <ControlLabel>Nome</ControlLabel>
            <FormControl name="name" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>E-mail</ControlLabel>
            <FormControl name="email" type="email" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Telefone</ControlLabel>
            <FormControl name="telephone" />
          </FormGroup>

          <Divider />

          <h2>Dados da câmera</h2>

          <FormGroup>
            <ControlLabel>Número de série</ControlLabel>
            <FormControl name="serial_number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Modelo</ControlLabel>
            <FormControl name="model" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Descrição</ControlLabel>
            <FormControl name="description" />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} appearance="primary">
          Adicionar
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function DenialModal({ ...props }) {
  const { show, data, onClose } = props
  const [formValue, setFormValue] = useState({})

  const close = () => {
    onClose()
  }

  const submit = () => {
    let { year } = formValue

    if (!year) return Alert.error('Insira o ano de fabricação da câmera.')

    api
      .delete(`/solicitacoes/${data._id}/${year}`)
      .then((response) => response.data)
      .then((response) => {
        if (response.error) return

        Alert.success('Solicitação negada com sucesso!')

        props.handleRefresh()
        close()
      })
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header>
        <Modal.Title>Insira as informações da câmera</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form formValue={formValue} onChange={(val) => setFormValue(val)}>
          <FormGroup style={{ width: '100%' }}>
            <ControlLabel>Insira o ano da câmera</ControlLabel>
            <FormControl
              name="year"
              placeholder="Ano da câmera"
              style={{ width: '100%' }}
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={submit} appearance="primary" color="primary">
          Negar solicitação
        </Button>
        <Button onClick={close} appearance="ghost" color="red">
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function NovosLaudosPage({ ...props }) {
  document.title = 'Solicitações | LOG-in'

  const [laudos, setLaudos] = useState(false)
  const [selected, setSelected] = useState(null)
  const [modal, setModal] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [denialModal, setDenialModal] = useState(false)

  const getData = async () => {
    await api
      .get('/laudos/novos')
      .then((response) => {
        return response.data
      })
      .then((response) => {
        setLaudos(response)
      })
  }

  if (laudos === false) getData()

  return (
    <Container className="padding-left">
      <h1>Solicitações</h1>
      <Divider />
      <PanelGroup bordered>
        <Panel header="Ações">
          <Row>
            <Col md={8} sm={12}>
              <ButtonToolbar>
                <Button color="primary" onClick={() => setCreateModal(true)}>
                  <Icon icon="plus" />
                  <span className="padding-left">Adicionar solicitação</span>
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Panel>
        <Panel bordered>
          <LaudosTable
            handleShow={(data) => {
              setSelected(data)
              setModal(true)
            }}
            handleEdit={() => {}}
            data={laudos}
          />
        </Panel>
      </PanelGroup>

      <DenialModal
        show={denialModal}
        handleRefresh={getData}
        data={selected}
        onClose={() => setDenialModal(false)}
      />
      <CreateModal
        show={createModal}
        onClose={() => setCreateModal(false)}
        onRefresh={() => getData()}
      />
      <LaudoModalWithRouter
        handleDenial={() => setDenialModal(true)}
        new
        refresh={getData}
        show={modal}
        handleClose={() => setModal(false)}
        data={selected}
      />
    </Container>
  )
}
