const INITIAL_STATE = {
  page: 1
}

const laudos_status = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_LAUDOS_TABLE_DATA':
      return { page: action.page }
    default:
      return state
  }
}

export default laudos_status