const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.lognature.com.br'
    : 'http://localhost:3100'

const API_OPTIONS = {
  method: 'get',
  withCredentials: true,
}

const CDN_LINK = 'https://cdn.lognature.com.br/'

module.exports = {
  API_URL,
  API_OPTIONS,
  CDN_LINK,
}
