import React, { Component } from 'react'
import {
  Divider,
  Container,
  Alert,
  Panel,
  Button,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  PanelGroup,
  Table,
  TagPicker,
} from 'rsuite'
import api from '../../services/api'
import { format } from 'date-fns'

const { Column, HeaderCell, Cell } = Table

export default class DashboardSettingsPage extends Component {
  constructor(props) {
    super(props)

    document.title = 'Configurações do dashboard | LOG-in'

    this.state = {
      metas: {},
      responsavel: {},
      responsaveis: [{}],
    }

    this.getData = this.getData.bind(this)
    this.handleChangeMetas = this.handleChangeMetas.bind(this)
    this.handleChangeResponsavel = this.handleChangeResponsavel.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    api
      .get('/settings/get_metas')
      .then((response) => {
        return response.data
      })
      .then((response) => {
        let meta_mensal, meta_trimestral, meta_anual

        meta_mensal = response.data[0] ? response.data[0].value : null
        meta_trimestral = response.data[1] ? response.data[1].value : null
        meta_anual = response.data[2] ? response.data[2].value : null

        let metas = { meta_mensal, meta_trimestral, meta_anual }

        this.setState({ metas: metas })
      })

    api
      .get('/users')
      .then((response) => {
        return response.data
      })
      .then((response) => {
        this.setState({ usuarios: response })
      })

    api
      .get('/settings/responsavel_semana/lista')
      .then((response) => {
        return response.data
      })
      .then((response) => {
        this.setState({ responsaveis: response })
      })
  }

  handleChangeMetas() {
    const { metas } = this.state

    this.setState({ changingMetas: true })

    api
      .post('/settings/set_metas', metas)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        this.setState({ changingMetas: false })
        if (response.error) Alert.error(response.error)
        else Alert.success('Metas atualizadas com sucesso')
      })
  }

  handleChangeResponsavel() {
    const { responsavel } = this.state

    this.setState({ changingResponsavel: true })

    api
      .post('/settings/responsavel_semana', responsavel)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        this.setState({ changingResponsavel: false })
        if (response.error) Alert.error(response.error)
        else {
          Alert.success('Responsável inserido com sucesso')
          this.getData()
        }
      })
  }

  render() {
    const { metas, responsavel, responsaveis, usuarios } = this.state

    return (
      <Container className="padding-left">
        <h1>Configurações do dashboard</h1>
        <Divider />
        <PanelGroup bordered>
          <Panel header="Metas">
            <Form
              fluid
              formValue={metas}
              onChange={(metas) => {
                this.setState({ metas })
              }}
            >
              <FormGroup>
                <ControlLabel>Meta mensal</ControlLabel>
                <FormControl type="number" name="meta_mensal" />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Metal trimestral</ControlLabel>
                <FormControl type="number" name="meta_trimestral" />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Meta anual</ControlLabel>
                <FormControl type="number" name="meta_anual" />
              </FormGroup>
              <Button
                loading={this.state.changingMetas}
                appearance="primary"
                onClick={this.handleChangeMetas}
              >
                Atualizar
              </Button>
            </Form>
          </Panel>

          <Panel header="Responsável da semana">
            <Form
              fluid
              formValue={responsavel}
              onChange={(responsavel) => {
                this.setState({ responsavel })
              }}
            >
              <FormGroup>
                <ControlLabel>Segunda-feira de início</ControlLabel>
                <FormControl
                  style={{ display: 'inline-block', width: '100%' }}
                  format="YYYY-MM-DD"
                  type="date"
                  name="start"
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Usuário</ControlLabel>
                <FormControl
                  style={{ display: 'inline-block', width: '100%' }}
                  accepter={TagPicker}
                  labelKey={'name'}
                  valueKey="username"
                  data={usuarios}
                  fluid
                  name="usernames"
                />
              </FormGroup>
              <Button
                loading={this.state.changingResponsavel}
                appearance="primary"
                onClick={this.handleChangeResponsavel}
              >
                Inserir/atualizar
              </Button>
            </Form>

            <br />
            <br />

            <div>
              <Table
                virtualized
                height={400}
                width="100%"
                data={responsaveis}
                sortColumn={'dateStart'}
                sortType="asc"
              >
                <Column width={140} align="center" fixed>
                  <HeaderCell>Data de início</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      if (!rowData || !rowData.dateStart) return null
                      try {
                        return format(
                          new Date(`${rowData.dateStart}T12:00:00.000Z`),
                          'dd/MM/yyyy'
                        )
                      } catch (error) {
                        console.log(rowData)
                        console.error(error)
                        return rowData.dateStart
                      }
                    }}
                  </Cell>
                </Column>

                <Column width={130}>
                  <HeaderCell>Usuário(s)</HeaderCell>
                  <Cell>
                    {(rowData) =>
                      rowData.usernames && rowData.usernames.length > 0
                        ? rowData.usernames.join(', ')
                        : rowData.username
                        ? rowData.username
                        : '-'
                    }
                  </Cell>
                </Column>

                <Column width={200}>
                  <HeaderCell>Criado em</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      if (!rowData || !rowData.createdAt) return null
                      return format(new Date(rowData.createdAt), 'Pp')
                    }}
                  </Cell>
                </Column>

                <Column width={200}>
                  <HeaderCell>Atualizado em</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      if (!rowData || !rowData.updatedAt) return null
                      return format(new Date(rowData.updatedAt), 'Pp')
                    }}
                  </Cell>
                </Column>
              </Table>
            </div>
          </Panel>
        </PanelGroup>
      </Container>
    )
  }
}
