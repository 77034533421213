import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  Divider,
  Col,
  Row,
  Container,
  Alert,
  Panel,
  Button,
  Icon,
  PanelGroup,
  Input,
  InputGroup,
  Modal,
  FormControl,
  FormGroup,
  ControlLabel,
  SelectPicker,
  Form,
  TagPicker,
} from 'rsuite'
import api from '../../services/api'
import { ReadableDate } from '../../helper/date'
import ConfirmationPopup from '../../components/ConfirmationPopup'
import RentalBookingsTable from '../../components/RentalBookingsTable'

const statuses = [
  {
    key: 3,
    value: 3,
    label: 'A enviar',
    color: '#cc0011',
  },
  {
    key: 4,
    value: 4,
    label: 'Em locação',
    color: '#fcba03',
  },
  {
    key: 5,
    value: 5,
    label: 'Prorrogado',
    color: '#2750cc',
  },
  {
    key: 6,
    value: 6,
    label: 'A receber',
    color: '#6d3780',
  },
  {
    key: 7,
    value: 7,
    label: 'Finalizado',
    color: '#119a2b',
  },
]

function ViewModal({ ...props }) {
  const { data: request } = props

  const [formValue, setFormValue] = useState({})
  const [products, setProducts] = useState([])

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get('/rental/products')
      setProducts(data)
    })()
  }, [])

  useEffect(() => {
    if (props.data) {
      setFormValue(props.data)
    }
  }, [props.data])

  const handleSave = useCallback(async () => {
    await api.patch(`/rental/requests/${request._id}`, {
      status: formValue.status,
    })
    if (props.onSave) {
      props.onSave()
    }
    props.handleClose()
    Alert.success('Status alterado com sucesso.')
  }, [formValue, props, request])

  const cameras = useMemo(() => {
    if (request && request.rentalProductIds) {
      return products.filter((x) => request.rentalProductIds.includes(x._id))
    }
    return []
  }, [products, request])

  if (!props.data) return null

  return (
    <Modal
      size="md"
      overflow={false}
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Header>
        <h2>Solicitação #{request.id}</h2>
      </Modal.Header>

      <Modal.Body>
        <h2>Dados da solicitação</h2>
        <Form fluid onChange={(v) => setFormValue(v)} formValue={formValue}>
          <FormGroup>
            <ControlLabel>
              <b>Status</b>
            </ControlLabel>
            <FormControl
              name="status"
              block
              accepter={SelectPicker}
              searchable={false}
              data={statuses}
            />
          </FormGroup>
        </Form>
        <h3>
          <b>Nome do cliente:</b> {request.customerName}
        </h3>
        <h3>
          <b>E-mail do cliente:</b> {request.customerEmail}
        </h3>
        <h3>
          <b>Telefone do cliente:</b> {request.customerTelephone}
        </h3>
        <h3>
          <b>
            Documento ({String(request.customerDocumentType).toUpperCase()}):
          </b>{' '}
          {request.customerDocument}
        </h3>
        <h3>
          <b>Inscrição municipal:</b>{' '}
          {request.customerISS ? request.customerISS : 'N/A'}
        </h3>
        <h3>
          <b>Retém ISS:</b> {request.customerRetainsISS ? 'Sim' : 'Não'}
        </h3>

        <hr />
        <h2>Endereço de entrega</h2>
        <h3>
          <b>CEP:</b> {request.customerZipCode}
        </h3>
        <h3>
          <b>Logradouro:</b> {request.customerStreet}
        </h3>
        <h3>
          <b>Número:</b> {request.customerNumber}
        </h3>
        <h3>
          <b>Complemento:</b> {request.customerComplement}
        </h3>
        <h3>
          <b>Bairro:</b> {request.customerNeighborhood}
        </h3>
        <h3>
          <b>Cidade:</b> {request.customerCity}
        </h3>
        <h3>
          <b>Estado:</b> {request.customerState}
        </h3>

        <hr />
        <h2>Dados de pagamento</h2>
        <h3>
          <b>Método de pagamento:</b> {request.paymentMethod}
        </h3>

        <hr />
        <h2>Endereço de faturamento</h2>
        <h3>
          <b>CEP:</b> {request.invoiceZipCode}
        </h3>
        <h3>
          <b>Logradouro:</b> {request.invoiceStreet}
        </h3>
        <h3>
          <b>Número:</b> {request.invoiceNumber}
        </h3>
        <h3>
          <b>Complemento:</b> {request.invoiceComplement}
        </h3>
        <h3>
          <b>Bairro:</b> {request.invoiceNeighborhood}
        </h3>
        <h3>
          <b>Cidade:</b> {request.invoiceCity}
        </h3>
        <h3>
          <b>Estado:</b> {request.invoiceState}
        </h3>

        <hr />
        <h2>Dados da locação</h2>
        <h3>
          <b>Envio:</b> {ReadableDate(request.sendDate, { showHours: false })}
        </h3>
        <h3>
          <b>Início:</b> {ReadableDate(request.startDate, { showHours: false })}
        </h3>
        <h3>
          <b>Término:</b> {ReadableDate(request.endDate, { showHours: false })}
        </h3>
        <h3>
          <b>Retorno:</b>{' '}
          {ReadableDate(request.returnDate, { showHours: false })}
        </h3>
        <h3>
          <b>Câmeras:</b>
        </h3>
        <ul>
          {cameras.map((cam) => (
            <li>
              {cam.model.name} (#{cam.id})
            </li>
          ))}
        </ul>
        <Divider />
        <h2>Informações gerais</h2>
        <h3>Criado em: {ReadableDate(request.createdAt)}</h3>
        {!props.new && (
          <h3>Atualizado em: {ReadableDate(request.updatedAt)}</h3>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleSave} appearance="ghost">
          Salvar
        </Button>
        <Button onClick={props.handleClose} appearance="primary">
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function RentalBookingsPage({ ...props }) {
  const [requests, setRequests] = useState(false)
  const [selected, setSelected] = useState(null)
  const [filters, setFilters] = useState({ status: [], search: '' })
  const [viewModal, setViewModal] = useState(false)

  document.title = 'Contratos de locação | LOG-in'

  const getData = useCallback(() => {
    api
      .get('/rental/requests/approved')
      .then((result) => {
        return result.data
      })
      .then((result) => {
        setRequests(result)
      })
  }, [])

  const handleDenial = useCallback(
    async (request) => {
      const shouldDeny = await ConfirmationPopup(
        `Deseja excluir o contrato #${request.id}?`
      )
      if (shouldDeny) {
        await api.delete(`/rental/requests/${request._id}`)
        Alert.success('Contrato excluído com sucesso.')
        getData()
      }
    },
    [getData]
  )

  useEffect(() => getData(), [getData])

  return (
    <Container className="padding-left">
      <h1>Contratos de locação</h1>
      <Divider />
      <PanelGroup bordered>
        <Panel header="Filtros">
          <Row>
            <Col md={8} sm={12}>
              <TagPicker
                data={statuses}
                onChange={(status) => {
                  setFilters({ ...filters, status })
                }}
                placeholder="Status"
                style={{ width: '100%' }}
              />
            </Col>
            <Col md={8} sm={12} mdOffset={8}>
              <InputGroup inside>
                <Input
                  data={filters.search}
                  onChange={(search) => {
                    setFilters({ ...filters, search })
                  }}
                  placeholder="Pesquisar"
                />
                <InputGroup.Button disabled>
                  <Icon icon="search" />
                </InputGroup.Button>
              </InputGroup>
            </Col>
          </Row>
        </Panel>
        <Panel>
          <RentalBookingsTable
            handleShow={(data) => {
              setSelected(data)
              setViewModal(true)
            }}
            handleDelete={handleDenial}
            filters={filters}
            data={requests}
            edit
            history={props.history}
          />
        </Panel>
      </PanelGroup>
      <ViewModal
        onSave={getData}
        history={props.history}
        data={selected}
        show={viewModal}
        handleClose={() => setViewModal(false)}
      />
    </Container>
  )
}
