import React, { useMemo, useState } from 'react'
import {
  Divider,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Container,
  Alert,
  Panel,
  SelectPicker,
  Button,
  Icon,
  ButtonToolbar,
  PanelGroup,
  IconButton,
  Notification,
  Nav,
} from 'rsuite'
import RentalCamerasTable from '../../components/RentalCamerasTable'
import RentalUpdatesTable from '../../components/RentalUpdatesTable'
import { ReadableDate } from '../../helper/date'
import api from '../../services/api'

export default function RentalBookingPage({ match, ...props }) {
  const [request, setRequest] = useState(false)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [activeTab, setActiveTab] = useState('customer')
  const [products, setProducts] = useState([])
  const [updates, setUpdates] = useState([])

  const duration = useMemo(() => {
    const { startDate, endDate } = request
    return (
      Math.ceil(
        (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)
      ) + 1
    )
  }, [request])

  document.title = `Contrato de locação #${request ? request.id : ''} | LOG-in`

  const getData = async () => {
    await api
      .get('/rental/requests/' + match.params.id)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        response.sendDate = response.sendDate.split('T')[0]
        response.startDate = response.startDate.split('T')[0]
        response.endDate = response.endDate.split('T')[0]
        response.returnDate = response.returnDate.split('T')[0]
        setRequest(response)
        ;(async () => {
          const { rentalProductIds } = response
          const _products = []

          for (const id of rentalProductIds) {
            _products.push(await api.get(`/rental/products/${id}`))
          }

          setProducts(_products.map((x) => x.data))

          const { data: _updates } = await api.get('/rental/updates', {
            params: {
              rentalRequestId: response._id,
            },
          })

          setUpdates(
            _updates.sort((a, b) => (new Date(a) > new Date(b) ? 1 : -1))
          )
        })()
      })
  }

  const handleSave = () => {
    setSaving(true)

    const data = { ...request }
    data.startDate = request.startDate.split('T')[0]
    data.endDate = request.endDate.split('T')[0]
    data.sendDate = `${request.sendDate.split('T')[0]}T12:00:00.000Z`
    data.returnDate = `${request.returnDate.split('T')[0]}T12:00:00.000Z`

    api
      .put(`/rental/requests/${request._id}`, data)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        if (response.error) return Alert.error(response.error)

        Alert.success('Contrato atualizado com sucesso!')
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const deleteModel = () => {
    api
      .delete('/rental/requests/' + request._id)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        Notification.remove('sureNotification')

        if (response.error) return Alert.error(response.error)

        Alert.success('Contrato excluído com sucesso!')

        props.history.push('/rental/products')
      })
      .finally(() => {
        setDeleting(false)
      })
  }

  const handleDelete = () => {
    setDeleting(true)
    Notification.open({
      title: 'Aviso',
      duration: 10000,
      key: 'sureNotification',
      description: (
        <div>
          <p>Você realmente deseja remover esse contrato?</p>
          <small>Essa ação é irreversível.</small>
          <br />
          <br />
          <ButtonToolbar>
            <Button color="red" onClick={deleteModel}>
              Sim
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setDeleting(false)
                Notification.remove('sureNotification')
              }}
            >
              Cancelar
            </Button>
          </ButtonToolbar>
        </div>
      ),
    })
  }

  if (request === false) getData()

  if (!request) return <Container className="padding-left" />

  return (
    <Container className="padding-left">
      <React.Fragment>
        <h1>Contrato #{request.id}</h1>
        <Divider />
        <Form
          fluid
          formValue={request}
          onChange={(val) => {
            setRequest(val)
          }}
        >
          <PanelGroup bordered>
            <Panel header="Ações">
              <ButtonToolbar>
                <IconButton
                  onClick={handleSave}
                  appearance="primary"
                  loading={saving}
                  style={{ marginBottom: '10px' }}
                >
                  <Icon icon="save" />
                  Salvar
                </IconButton>

                <IconButton
                  onClick={handleDelete}
                  appearance="primary"
                  color="red"
                  loading={deleting}
                  style={{ marginBottom: '10px' }}
                >
                  <Icon icon="trash" />
                  Excluir
                </IconButton>
              </ButtonToolbar>
            </Panel>

            <Panel>
              <Nav
                appearance="subtle"
                activeKey={activeTab}
                onSelect={(active) => setActiveTab(active)}
              >
                <Nav.Item eventKey="customer">Dados do cliente</Nav.Item>
                <Nav.Item eventKey="request">Dados do contrato</Nav.Item>
                <Nav.Item eventKey="logistics">Logística</Nav.Item>
                <Nav.Item eventKey="invoice">Faturamento</Nav.Item>
                <Nav.Item eventKey="updates">Atualizações</Nav.Item>
              </Nav>
              <br />

              <PanelGroup bordered>
                {/* CUSTOMER */}
                {activeTab === 'customer' && (
                  <Panel
                    header="Dados do cliente"
                    style={activeTab !== 'customer' ? { display: 'none' } : {}}
                  >
                    <Row>
                      <Col sm={24} md={6}>
                        <FormGroup>
                          <ControlLabel>Nome/Razão social</ControlLabel>
                          <FormControl name="customerName" />
                        </FormGroup>
                      </Col>
                      <Col sm={24} md={3}>
                        <FormGroup>
                          <ControlLabel>Tipo do documento</ControlLabel>
                          <FormControl
                            name="customerDocumentType"
                            block
                            accepter={SelectPicker}
                            searchable={false}
                            data={[
                              { value: 'cpf', label: 'CPF' },
                              { value: 'cnpj', label: 'CNPJ' },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={24} md={3}>
                        <FormGroup>
                          <ControlLabel>Documento</ControlLabel>
                          <FormControl name="customerDocument" />
                        </FormGroup>
                      </Col>
                      <Col sm={24} md={6}>
                        <FormGroup>
                          <ControlLabel>Inscrição municipal</ControlLabel>
                          <FormControl name="customerISS" />
                        </FormGroup>
                      </Col>
                      <Col sm={24} md={6}>
                        <FormGroup>
                          <ControlLabel>Retém ISS</ControlLabel>
                          <FormControl
                            name="customerRetainsISS"
                            block
                            accepter={SelectPicker}
                            searchable={false}
                            data={[
                              { value: true, label: 'Sim' },
                              { value: false, label: 'Não' },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col sm={24} md={6}>
                        <FormGroup>
                          <ControlLabel>E-mail</ControlLabel>
                          <FormControl name="customerEmail" />
                        </FormGroup>
                      </Col>
                      <Col sm={24} md={6}>
                        <FormGroup>
                          <ControlLabel>Telefone</ControlLabel>
                          <FormControl name="customerTelephone" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Panel>
                )}

                {/* REQUEST */}
                {activeTab === 'request' && (
                  <>
                    <Panel header="Controle">
                      <Row>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Status</ControlLabel>
                            <FormControl
                              name="status"
                              block
                              accepter={SelectPicker}
                              searchable={false}
                              data={[
                                {
                                  key: 3,
                                  value: 3,
                                  label: 'A enviar',
                                  color: '#cc0011',
                                },
                                {
                                  key: 4,
                                  value: 4,
                                  label: 'Em locação',
                                  color: '#fcba03',
                                },
                                {
                                  key: 5,
                                  value: 5,
                                  label: 'Prorrogado',
                                  color: '#2750cc',
                                },
                                {
                                  key: 6,
                                  value: 6,
                                  label: 'A receber',
                                  color: '#6d3780',
                                },
                                {
                                  key: 7,
                                  value: 7,
                                  label: 'Finalizado',
                                  color: '#119a2b',
                                },
                              ]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col sm={24} md={8}>
                          <h4>
                            <b>Iniciado em:</b>
                          </h4>
                          <h5 style={{ lineHeight: '20px' }}>
                            {ReadableDate(request.createdAt)}
                          </h5>
                        </Col>
                        <Col sm={24} md={8}>
                          <h4>
                            <b>Atualizado em:</b>
                          </h4>
                          <h5 style={{ lineHeight: '20px' }}>
                            {ReadableDate(request.updatedAt)}
                          </h5>
                        </Col>
                      </Row>
                    </Panel>

                    <Panel header="Câmeras">
                      <RentalCamerasTable duration={duration} data={products} />
                    </Panel>
                  </>
                )}

                {/* LOGISTICS */}
                {activeTab === 'logistics' && (
                  <>
                    <Panel header="Prazos">
                      <Row>
                        <Col sm={12} md={3}>
                          <FormGroup>
                            <ControlLabel>Frete (dias)</ControlLabel>
                            <FormControl name="shippingTime" />
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={3}>
                          <FormGroup>
                            <ControlLabel>Envio:</ControlLabel>
                            <FormControl name="sendDate" type="date" />
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={3}>
                          <FormGroup>
                            <ControlLabel>Início:</ControlLabel>
                            <FormControl name="startDate" type="date" />
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={3}>
                          <FormGroup>
                            <ControlLabel>Término:</ControlLabel>
                            <FormControl name="endDate" type="date" />
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={3}>
                          <FormGroup>
                            <ControlLabel>Retorno:</ControlLabel>
                            <FormControl name="returnDate" type="date" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Endereço de entrega">
                      <Row>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>CEP</ControlLabel>
                            <FormControl name="customerZipCode" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Rua</ControlLabel>
                            <FormControl name="customerStreet" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Número</ControlLabel>
                            <FormControl name="customerNumber" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Complemento</ControlLabel>
                            <FormControl name="customerComplement" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Bairro</ControlLabel>
                            <FormControl name="customerNeighborhood" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Cidade</ControlLabel>
                            <FormControl name="customerCity" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Estado</ControlLabel>
                            <FormControl name="customerState" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Códigos de rastreio">
                      <Row>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Envio</ControlLabel>
                            <FormControl name="sendShippingCode" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Retorno</ControlLabel>
                            <FormControl name="returnShippingCode" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Panel>
                  </>
                )}

                {/* INVOICE */}
                {activeTab === 'invoice' && (
                  <>
                    <Panel header="Informações de pagamento">
                      <Row>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Método de pagamento</ControlLabel>
                            <FormControl
                              name="paymentMethod"
                              block
                              accepter={SelectPicker}
                              searchable={false}
                              data={[
                                {
                                  key: 1,
                                  value: 'Boleto',
                                  label: 'Boleto',
                                },
                                {
                                  key: 2,
                                  value: 'TED',
                                  label: 'TED',
                                },
                                {
                                  key: 3,
                                  value: 'Cartão',
                                  label: 'Cartão',
                                },
                              ]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Endereço de faturamento">
                      <Row>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>CEP</ControlLabel>
                            <FormControl name="invoiceZipCode" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Rua</ControlLabel>
                            <FormControl name="invoiceStreet" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Número</ControlLabel>
                            <FormControl name="invoiceNumber" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Complemento</ControlLabel>
                            <FormControl name="invoiceComplement" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Bairro</ControlLabel>
                            <FormControl name="invoiceNeighborhood" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Cidade</ControlLabel>
                            <FormControl name="invoiceCity" />
                          </FormGroup>
                        </Col>
                        <Col sm={24} md={6}>
                          <FormGroup>
                            <ControlLabel>Estado</ControlLabel>
                            <FormControl name="invoiceState" />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Valores">
                      <Row>
                        <Col sm={8} md={4}>
                          <h5>Valor da locação</h5>
                          <h3>
                            R${' '}
                            {(
                              request.totalPrice - request.shippingPrice
                            ).toLocaleString()}
                          </h3>
                        </Col>
                        <Col sm={8} md={4}>
                          <h5>Valor do frete</h5>
                          <h3>R$ {request.shippingPrice.toLocaleString()}</h3>
                        </Col>
                        <Col sm={8} md={4}>
                          <h5>Valor total</h5>
                          <h3>R$ {request.totalPrice.toLocaleString()}</h3>
                        </Col>
                      </Row>
                    </Panel>
                  </>
                )}

                {/* UPDATES */}
                {activeTab === 'updates' && (
                  <Panel
                    header="Registros"
                    style={activeTab !== 'updates' ? { display: 'none' } : {}}
                  >
                    <RentalUpdatesTable data={updates} />
                  </Panel>
                )}
              </PanelGroup>
            </Panel>
          </PanelGroup>
        </Form>
      </React.Fragment>
    </Container>
  )
}
