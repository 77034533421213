/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Alert,
  Checkbox,
  AutoComplete,
  Uploader,
  Button,
  ButtonToolbar,
  Col,
  Container,
  ControlLabel,
  Divider,
  Form,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  InputNumber,
  Modal,
  Nav,
  Notification,
  Panel,
  PanelGroup,
  Row,
  Schema,
  SelectPicker,
  Table,
  Tag,
  TagGroup,
} from 'rsuite'
import ConfirmationPopup from '../../components/ConfirmationPopup'
import LaudoUpdatesTable from '../../components/LaudoUpdatesTable'
import NotificationPopup from '../../components/NotificationPopup'
import { ReadableDate } from '../../helper/date'
import api, { getAuthToken } from '../../services/api'
import { user } from '../../services/info'
import generatePDF from '../../services/laudoPDF'
import { API_URL, CDN_LINK } from '../../settings/variables'

const { Column, HeaderCell, Cell } = Table

class File extends React.Component {
  constructor(props) {
    super(props)

    this.copyToClipboard = this.copyToClipboard.bind(this)
    this.deleteFile = this.deleteFile.bind(this)
    this.sure = this.sure.bind(this)
  }

  copyToClipboard() {
    var textField = document.createElement('textarea')
    textField.innerText = encodeURI(
      CDN_LINK + this.props.file.type + '/' + this.props.file.name
    )
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    Alert.success('Copiado para a área de transferência')
  }

  sure() {
    Notification.open({
      title: 'Aviso',
      duration: 10000,
      key: 'sureNotification',
      description: (
        <div>
          <p>Você realmente deseja remover esse arquivo?</p>
          <small>Essa ação é irreversível.</small>
          <br />
          <br />
          <ButtonToolbar>
            <Button color="red" onClick={this.deleteFile}>
              Sim
            </Button>
            <Button
              color="primary"
              onClick={() => Notification.remove('sureNotification')}
            >
              Cancelar
            </Button>
          </ButtonToolbar>
        </div>
      ),
    })
  }

  deleteFile() {
    Notification.remove('sureNotification')

    let { _id } = this.props.file

    api
      .delete(`/cdn/${_id}`)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        if (response.error) Alert.error(response.error)
        else {
          Alert.success('Arquivo excluído com sucesso')
          this.props.reload()
        }
      })
  }

  render() {
    let { type, name, size, date, time, author } = this.props.file
      ? this.props.file
      : {}

    return (
      <>
        <Panel bordered>
          <Row>
            <Col md={2}>
              {type ? (
                <Icon
                  size="5x"
                  icon={
                    type === 'podcasts'
                      ? 'file-audio-o'
                      : type === 'docs'
                      ? 'file-text-o'
                      : type === 'images'
                      ? 'file-image-o'
                      : 'file'
                  }
                />
              ) : null}
            </Col>
            <Col md={17}>
              <h4>
                <b>{name ? name : null}</b>
              </h4>
              <h5>
                {size ? size : null}MB | por {author ? author : 'Desconhecido'},
                em {date ? date : null}, às {time ? time : null}
              </h5>
            </Col>
            <Col md={5}>
              <a style={{ cursor: 'pointer' }} onClick={this.copyToClipboard}>
                <h5>
                  <Icon icon="paperclip" size="1x" /> Copiar link
                </h5>
              </a>
              <a
                href={encodeURI(CDN_LINK + type + '/' + name)}
                target="_blank"
                rel="noopener noreferrer"
                download={name}
              >
                <h5>
                  <Icon icon="download" size="1x" /> Baixar arquivo
                </h5>
              </a>
              <a style={{ cursor: 'pointer' }} onClick={this.sure}>
                <h5>
                  <Icon icon="trash" size="1x" /> Excluir arquivo
                </h5>
              </a>
            </Col>
          </Row>
        </Panel>
        {this.props.spacing ? <br /> : null}
      </>
    )
  }
}

function AddFlawModal({ ...props }) {
  const show = props.show ? props.show : false
  const [formValue, setFormValue] = useState({})
  const handleClose = props.onClose

  const { ObjectType } = Schema.Types

  let model = Schema.Model({
    flaw: ObjectType().isRequired('O campo "serviço" é obrigatório.'),
  })

  const [, setFormModel] = useState(model)

  const handleSubmit = () => {
    const errors = model.check(formValue)
    let hasError = false

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key]
        if (element.hasError === true) {
          hasError = element.errorMessage
          break
        }
      }
    }

    if (hasError) {
      return Alert.error(hasError)
    }

    props.onSubmit(formValue.flaw)
    props.onClose()
    formValue.flaw = null
  }

  return (
    <Modal show={show} overflow={false} onHide={handleClose}>
      <Modal.Header>
        <h1>Adicionar serviço</h1>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          onChange={setFormValue}
          formValue={formValue}
          onCheck={setFormModel}
          model={model}
        >
          <FormGroup>
            <ControlLabel>Serviço</ControlLabel>
            <FormControl
              style={{ width: '100%' }}
              accepter={SelectPicker}
              name="flaw"
              data={props.flaws}
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} appearance="primary">
          Adicionar
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function LaudoPage({ match, ...props }) {
  const [laudo, setLaudo] = useState(false)

  const userData = useSelector((state) => state.user)

  const [activeTab, setActive] = useState(
    userData.roles.includes('admin') || userData.roles.includes('employee')
      ? 'customer'
      : 'laudo'
  )
  const status = useSelector((state) => state.statuses)
  const [customer, setCustomer] = useState(false)
  const [syncing, setSyncing] = useState(true)
  const [customers, setCustomers] = useState(false)
  const [, setFlaws] = useState([])
  const [nextFlaws, setNextFlaws] = useState([])
  const [modalFlaws, setModalFlaws] = useState([])
  const [searching, setSearching] = useState(false)
  const [saving, setSaving] = useState(false)
  const [printing, setPrinting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [nfe, setNFE] = useState(false)
  const [updatedBy, setUpdatedBy] = useState(false)
  const [createdBy, setCreatedBy] = useState(false)
  const [addFlaw, setAddFlaw] = useState(false)
  const [values, setValues] = useState({ customer: 0, technician: 0 })
  const [pdf, setPDF] = useState(false)
  const [editingServices, setEditingServices] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [updates, setUpdates] = useState([])

  useEffect(() => {
    status.forEach((v, i) => {
      status[i].label = v.name
      status[i].value = v._id
    })
  }, [status])

  const updateValues = () => {
    let val = { customer: 0, technician: 0 }

    if (val) {
      if (laudo.flaws) {
        laudo.flaws.forEach((v) => {
          if (laudo.warranty !== 'Válida') val.customer += v.customerValue
          val.technician += v.technicianValue
        })
      }

      if (laudo.shippingType !== 2 && laudo.shipping)
        val.customer += Number.parseFloat(
          laudo.shipping.length === 0 ? 0 : laudo.shipping
        )

      setValues(val)
    }
  }

  useEffect(updateValues, [laudo.flaws])

  document.title = `Laudo #${laudo ? laudo.id : ''} | LOG-in`

  const setnfe = (nf, status) => {
    if (nf == null) return

    nf.data_pedido = nf.data_pedido
      ? nf.data_pedido
      : nf.createdAt.split('T')[0]

    setNFE(nf)

    setLaudo({
      ...laudo,
      cameraNFE: nf.id_pedido ? nf.id_pedido : '',
      warranty: status,
    })
  }

  const getData = async () => {
    await api
      .get('/laudos/' + match.params.id)
      .then((response) => {
        return response.data
      })
      .then(async (response) => {
        response.flaws.forEach((v, i) => {
          response.flaws[i] = { ...v, id: i }
        })

        setLaudo(response)

        const laudoResponse = response

        await api
          .get('/user/' + response.updatedBy)
          .then((response) => {
            return response.data
          })
          .then((response) => setUpdatedBy(response))
          .catch((res) => true)

        await api
          .get('/user/' + response.createdBy)
          .then((response) => {
            return response.data
          })
          .then((response) => setCreatedBy(response))
          .catch((err) => true)

        await api
          .get('/customer?email=' + response.email)
          .then((response) => {
            return response.data
          })
          .then((response) => {
            setSyncing(false)
            if (response.error) setCustomer(null)
            else {
              setCustomer(response)

              setLaudo({
                ...laudoResponse,
                name: response.name,
                telephone: response.telephone,
                profile: response.profile,
                profile_final: response.profile_final,
                institution: response.institution,
              })
            }
          })
          .catch((err) => {
            setSyncing(false)
            setCustomer(null)
          })

        await api
          .get('/customers')
          .then((response) => {
            return response.data
          })
          .then((response) => {
            response.forEach((v, i) => {
              response[i] = v.email
            })
            setCustomers(response)
          })

        await api
          .get('/flaws')
          .then((response) => {
            return response.data
          })
          .then((response) => {
            setFlaws(response)
            if (Array.isArray(response)) {
              let temp = []
              for (let i = 0; i < response.length; i++) {
                let element = response[i]
                element = {
                  label: element.description,
                  value: element,
                }
                temp.push(element)
              }
              setModalFlaws(temp)
            }
          })

        getFiles(response._id)

        const { data: _updates } = await api.get('/laudos/updates', {
          params: {
            laudoId: response._id,
          },
        })

        setUpdates(
          _updates.sort((a, b) => (new Date(a) > new Date(b) ? 1 : -1))
        )

        Alert.success('Laudo carregado com sucesso')
      })
  }

  useEffect(() => {
    getData()

    return () => {
      setLaudo(null)
    }
  }, [])

  const getFiles = (id = laudo._id) => {
    api
      .get('/cdn/technical-assistance', { params: { object_related: id } })
      .then((response) => response.data)
      .then(setAttachments)
  }

  const handleSearch = () => {
    if (!laudo.serial_number || !laudo.serial_number.length > 0) {
      return Alert.error('Insira um número de série')
    }
    setSearching(true)
    api
      .get(`/garantias/${laudo.serial_number}`)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        setSearching(false)

        if (
          response.login &&
          response.login._id !== laudo._id &&
          !response.expired
        ) {
          NotificationPopup.show(
            'Garantia encontrada',
            'Um laudo foi encontrado!\nNº do laudo: #' + response.login.id
          )
          setnfe(response.login, 'Válida')
        } else if (
          response.notasFiscais &&
          response.notasFiscais.length > 0 &&
          !response.expired
        ) {
          if (response.notasFiscais[0].status_pedido !== 'Atendido') {
            return Alert.error('Garantia não encontrada!')
          }
          NotificationPopup.show(
            'Garantia encontrada',
            'Uma nota fiscal foi encontrada!\nNº da nota: #' +
              response.notasFiscais[0].id_pedido
          )
          setnfe(response.notasFiscais[0], 'Válida')
        } else if (response.assistencia && !response.expired) {
          return Alert.error('Garantia não encontrada!')
        } else {
          if (response.expired) {
            Alert.warning('Garantia expirada!')
            let nfe = response.notasFiscais[0]

            if (nfe) return setnfe(nfe, 'Expirada')

            let x = laudo
            laudo.warranty = 'Expirada'
            if (response.id_nota) x.cameraNFE = response.id_nota
            setLaudo(x)
          } else {
            Alert.error(response.error)
          }
        }
      })
  }

  const handleSave = () => {
    setSaving(true)
    api
      .put('/laudos/update', { laudo })
      .then((response) => {
        return response.data
      })
      .then((response) => {
        setSaving(false)

        if (response.error) return Alert.error(response.error)

        Alert.success('Laudo atualizado com sucesso!')

        getData()
      })
  }

  const deleteLaudo = () => {
    api
      .delete('/laudos/delete/' + laudo._id)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        setDeleting(false)
        Notification.remove('sureNotification')

        if (response.error) return Alert.error(response.error)

        Alert.success('Laudo excluído com sucesso!')

        props.history.push('/technical-assistance/laudos')
      })
  }

  const handleDelete = () => {
    setDeleting(true)
    Notification.open({
      title: 'Aviso',
      duration: 10000,
      key: 'sureNotification',
      description: (
        <div>
          <p>Você realmente deseja remover esse laudo?</p>
          <small>Essa ação é irreversível.</small>
          <br />
          <br />
          <ButtonToolbar>
            <Button color="red" onClick={deleteLaudo}>
              Sim
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setDeleting(false)
                Notification.remove('sureNotification')
              }}
            >
              Cancelar
            </Button>
          </ButtonToolbar>
        </div>
      ),
    })
  }

  const handleAddFlaw = (flaw) => {
    let temp = laudo.flaws

    temp.push(flaw)

    updateValues()

    setLaudo({ ...laudo, temp })
  }

  const handleEditServices = () => {
    if (editingServices) {
      setFlaws(nextFlaws)
    } else {
      setNextFlaws(laudo.flaws)
    }

    setEditingServices(!editingServices)
  }

  if (!laudo) return <Container className="padding-left" />

  const handleChange = (v, e, u) => {
    let temp = laudo
    // console.log(e.target)
    try {
      temp[e.target ? e.target.name : e] = v

      if (temp.shippingType !== 2)
        values.customer += Number.parseFloat(
          temp.shipping.length === 0 ? 0 : temp.shipping
        )

      setLaudo(temp)
    } catch (e) {
      Alert.error(e.message)
    }

    if (u) updateValues()
  }

  const handlePrint = () => {
    setPDF(null)
    generatePDF(laudo).then((pdf) => {
      setPDF(pdf)
      setPrinting(false)
    })
  }

  const handleDownload = () => {
    generatePDF(laudo, true)
  }

  const withHandlerSelectPicker = SelectPicker
  withHandlerSelectPicker.onChange = handleChange

  function compare(a, b) {
    let nameA = a.toUpperCase()
    let nameB = b.toUpperCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }

  return (
    <Container className="padding-left">
      <React.Fragment>
        <h1>Laudo #{laudo.id}</h1>
        <Divider />
        <Form
          fluid
          formValue={laudo}
          onChange={(val) => {
            // setLaudo(val)
            // DO NOTHING! PERFOMANCE ISSUES DOING THAT ...
          }}
        >
          <PanelGroup bordered>
            {pdf && <Panel header="Laudo gerado">{pdf}</Panel>}
            <Panel header="Ações">
              <ButtonToolbar>
                <IconButton
                  onClick={handleSave}
                  appearance="primary"
                  loading={saving}
                  style={{ marginBottom: '10px' }}
                >
                  <Icon icon="save" />
                  Salvar
                </IconButton>

                <IconButton
                  onClick={handleDelete}
                  appearance="primary"
                  color="red"
                  loading={deleting}
                  style={{ marginBottom: '10px' }}
                >
                  <Icon icon="trash" />
                  Excluir
                </IconButton>

                {!user.isTechnician() && (
                  <>
                    <IconButton
                      onClick={() => {
                        setPrinting(true)
                        handlePrint()
                      }}
                      appearance="primary"
                      loading={printing}
                      style={{ marginBottom: '10px' }}
                      color="blue"
                    >
                      <Icon icon="print" />
                      Imprimir
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        handleDownload()
                      }}
                      appearance="primary"
                      loading={printing}
                      style={{ marginBottom: '10px' }}
                      color="green"
                    >
                      <Icon icon="download" />
                      Baixar
                    </IconButton>
                  </>
                )}
              </ButtonToolbar>
            </Panel>
            <Panel>
              <Nav
                appearance="subtle"
                activeKey={activeTab}
                onSelect={(active) => setActive(active)}
              >
                {(userData.roles.includes('admin') ||
                  userData.roles.includes('employee')) && (
                  <Nav.Item eventKey="customer">Dados do cliente</Nav.Item>
                )}
                <Nav.Item eventKey="laudo">Dados do laudo</Nav.Item>
                {(userData.roles.includes('admin') ||
                  userData.roles.includes('employee')) && (
                  <Nav.Item eventKey="billing">Detalhes financeiros</Nav.Item>
                )}
                <Nav.Item eventKey="attachments">Anexos</Nav.Item>
                <Nav.Item eventKey="updates">Atualizações</Nav.Item>
              </Nav>
              <br />

              <PanelGroup
                bordered
                className={activeTab !== 'customer' && 'hide'}
              >
                <Panel header="Contato">
                  {syncing ? (
                    <TagGroup>
                      <Tag color="red">Sincronizando...</Tag>
                    </TagGroup>
                  ) : !customer ? (
                    <>
                      <TagGroup>
                        <Tag color="red">Cliente não encontrado</Tag>
                      </TagGroup>
                      <small>
                        Caso já esteja cadastrado no sistema, insira o e-mail e
                        salve para atualizar
                      </small>
                    </>
                  ) : (
                    <TagGroup>
                      <Tag color="green">Cliente sincronizado</Tag>
                    </TagGroup>
                  )}
                  <br />
                  <Row>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Nome</ControlLabel>
                        <FormControl onChange={handleChange} name="name" />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>E-mail</ControlLabel>
                        <AutoComplete
                          data={customers}
                          value={laudo.email}
                          onChange={(v) => setLaudo({ ...laudo, email: v })}
                        />
                        {/* <FormControl  onChange={handleChange} name='email' /> */}
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Telefone</ControlLabel>
                        <FormControl onChange={handleChange} name="telephone" />
                      </FormGroup>
                    </Col>
                  </Row>
                </Panel>
                <Panel header="Ocupação">
                  <Row>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Instituição</ControlLabel>
                        <FormControl
                          onChange={handleChange}
                          name="institution"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Perfil</ControlLabel>
                        <FormControl
                          onChange={(v) => handleChange(v, 'profile', true)}
                          name="profile"
                          block
                          accepter={SelectPicker}
                          searchable={false}
                          data={[
                            { value: 'biologist', label: 'Biólogo' },
                            {
                              value: 'observer',
                              label: 'Observador/Interessado pela natureza',
                            },
                            {
                              value: 'manager',
                              label:
                                'Setor de compras/Administrativo/Logística/Suprimentos',
                            },
                            { value: 'other', label: 'Personalizado' },
                          ]}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      {laudo.profile === 'biologist' && (
                        <FormGroup>
                          <ControlLabel>Cargo</ControlLabel>
                          <FormControl
                            onChange={(v) => handleChange(v, 'profile_final')}
                            name="profile_final"
                            block
                            accepter={SelectPicker}
                            searchable={false}
                            data={[
                              {
                                value: 'Setor Administrativo',
                                label: 'Setor Administrativo',
                              },
                              {
                                value: 'Consultoria Ambiental',
                                label: 'Consultoria Ambiental',
                              },
                              {
                                value: 'Órgão Público',
                                label: 'Órgão Público',
                              },
                              {
                                value:
                                  'Professor e/ou Pesquisador vinculado a instituição de ensino e pesquisa',
                                label:
                                  'Professor e/ou Pesquisador vinculado a instituição de ensino e pesquisa',
                              },
                              {
                                value: 'Aluno graduação',
                                label: 'Aluno graduação',
                              },
                            ]}
                          />
                        </FormGroup>
                      )}

                      {laudo.profile === 'other' && (
                        <FormGroup>
                          <ControlLabel>Cargo</ControlLabel>
                          <FormControl name="profile_final" />
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                </Panel>

                <Panel header="Endereço">
                  <Form formValue={customer ? customer.address : null}>
                    <Row>
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <ControlLabel>CEP</ControlLabel>
                          <FormControl
                            onChange={handleChange}
                            disabled
                            name="cep"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={8}>
                        <FormGroup>
                          <ControlLabel>Logradouro</ControlLabel>
                          <FormControl
                            onChange={handleChange}
                            disabled
                            name="street"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={24} md={5}>
                        <FormGroup>
                          <ControlLabel>Número</ControlLabel>
                          <FormControl
                            onChange={handleChange}
                            disabled
                            name="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={24} md={5}>
                        <FormGroup>
                          <ControlLabel>Complemento</ControlLabel>
                          <FormControl
                            onChange={handleChange}
                            disabled
                            name="complement"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col sm={24} md={8}>
                        <FormGroup>
                          <ControlLabel>Bairro</ControlLabel>
                          <FormControl
                            onChange={handleChange}
                            disabled
                            name="district"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={24} md={8}>
                        <FormGroup>
                          <ControlLabel>Cidade</ControlLabel>
                          <FormControl
                            onChange={handleChange}
                            disabled
                            name="town"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={24} md={8}>
                        <FormGroup>
                          <ControlLabel>Estado</ControlLabel>
                          <FormControl
                            onChange={handleChange}
                            disabled
                            name="state"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Panel>
              </PanelGroup>

              {/* DADOS DO LAUDO */}
              <PanelGroup bordered className={activeTab !== 'laudo' && 'hide'}>
                <Panel header="Controle">
                  <Row>
                    <Col sm={12} md={12}>
                      <FormGroup>
                        <FormControl
                          onChange={handleChange}
                          accepter={Checkbox}
                          name="deletable"
                          checked={laudo.deletable}
                          onClick={() =>
                            setLaudo({ ...laudo, deletable: !laudo.deletable })
                          }
                        >
                          Este laudo pode ser deletado automaticamente pelo
                          sistema
                        </FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={4} md={4}>
                      <FormGroup>
                        <ControlLabel>ID do laudo</ControlLabel>
                        <FormControl
                          onChange={handleChange}
                          disabled
                          name="id"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={12} md={6}>
                      <FormGroup>
                        <ControlLabel>Status</ControlLabel>
                        <FormControl
                          onChange={(v) => handleChange(v, 'status', true)}
                          name="status"
                          searchable={false}
                          accepter={SelectPicker}
                          data={status}
                          style={{ width: '100%' }}
                          sort={() => {
                            return (a, b) => {
                              return compare(a.name, b.name)
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {laudo.status === '5d5c662027e501140c68e728' && (
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <ControlLabel>Status da reciclagem</ControlLabel>
                          <FormControl
                            onChange={(v) =>
                              handleChange(v, 'status_reciclagem')
                            }
                            name="status_reciclagem"
                            searchable={false}
                            accepter={SelectPicker}
                            data={[
                              { label: 'Comprou', value: 'Comprou' },
                              { label: 'Doação', value: 'Não comprou' },
                              { label: 'Carência', value: 'Carência' },
                            ]}
                            style={{ width: '100%' }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {laudo.status === '5d5c631b1935653e4822d7d4' && (
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <ControlLabel>Status da avaliação</ControlLabel>
                          <FormControl
                            onChange={(v) =>
                              handleChange(v, 'status_avaliacao')
                            }
                            name="status_avaliacao"
                            searchable={false}
                            accepter={SelectPicker}
                            data={[
                              { label: 'LOG', value: 'LOG' },
                              { label: 'Assistência', value: 'Assistência' },
                            ]}
                            style={{ width: '100%' }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {laudo.status === '5d5c68bb27e501140c68e730' && (
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <ControlLabel>Status do teste</ControlLabel>
                          <FormControl
                            onChange={(v) => handleChange(v, 'status_testada')}
                            name="status_testada"
                            searchable={false}
                            accepter={SelectPicker}
                            data={[
                              { label: 'LOG', value: 'LOG' },
                              { label: 'Assistência', value: 'Assistência' },
                            ]}
                            style={{ width: '100%' }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {laudo.status === '5d5c68d227e501140c68e731' && (
                      <Col sm={12} md={6}>
                        <FormGroup>
                          <ControlLabel>Status da garantia</ControlLabel>
                          <FormControl
                            onChange={(v) => handleChange(v, 'status_garantia')}
                            name="status_garantia"
                            searchable={false}
                            accepter={SelectPicker}
                            data={[
                              { label: 'Substituição', value: 'Substituição' },
                              { label: 'Reparo', value: 'Reparo' },
                            ]}
                            style={{ width: '100%' }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <br />
                  <Row>
                    <Col sm={24} md={8}>
                      <h4>
                        <b>Iniciado em:</b>
                      </h4>
                      <h5 style={{ lineHeight: '20px' }}>
                        {ReadableDate(laudo.createdAt)}
                        {createdBy && (
                          <span>
                            <br />
                            {createdBy.name && ' por ' + createdBy.name}
                          </span>
                        )}
                      </h5>
                    </Col>
                    <Col sm={24} md={8}>
                      <h4>
                        <b>Atualizado em:</b>
                      </h4>
                      <h5 style={{ lineHeight: '20px' }}>
                        {ReadableDate(laudo.updatedAt)}
                        {updatedBy && (
                          <span>
                            <br />
                            {updatedBy.name && ' por ' + updatedBy.name}
                          </span>
                        )}
                      </h5>
                    </Col>
                  </Row>
                </Panel>
                <Panel header="Dados da câmera">
                  <Row>
                    <Col sm="24" md="8">
                      <FormGroup>
                        <ControlLabel>Número de série</ControlLabel>
                        <FormControl
                          disabled={user.isTechnician()}
                          onChange={handleChange}
                          name="serial_number"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="24" md="8">
                      <Button
                        appearance="primary"
                        style={{ marginTop: '24px' }}
                        loading={searching}
                        disabled={user.isTechnician()}
                        onClick={() => handleSearch()}
                      >
                        Verificar garantia
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Modelo</ControlLabel>
                        <FormControl
                          disabled={user.isTechnician()}
                          onChange={handleChange}
                          name="model"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Nº nota fiscal</ControlLabel>
                        <FormControl
                          disabled={user.isTechnician()}
                          onChange={handleChange}
                          name="cameraNFE"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Garantia</ControlLabel>
                        <FormControl
                          onChange={(v) => handleChange(v, 'warranty', true)}
                          name="warranty"
                          searchable={false}
                          accepter={SelectPicker}
                          disabled={user.isTechnician()}
                          data={[
                            { label: 'Válida', value: 'Válida' },
                            { label: 'Não válida', value: 'Não válida' },
                            { label: 'Expirada', value: 'Expirada' },
                          ]}
                          style={{ width: '100%' }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  {nfe && (
                    <div>
                      <h4>
                        <b>Data de emissão:</b>{' '}
                        {ReadableDate(nfe.data_pedido, { showHours: false })}
                      </h4>
                      <h4>
                        <b>Validade da garantia:</b>{' '}
                        {ReadableDate(nfe.data_pedido, {
                          offset: {
                            years: nfe.id_solicitacao ? 0 : 1,
                            months: nfe.id_solicitacao ? 6 : 0,
                          },
                          showHours: false,
                        })}
                      </h4>
                    </div>
                  )}
                </Panel>
                <Panel header="Observações">
                  <Row>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Observações do cliente</ControlLabel>
                        <FormControl
                          onChange={handleChange}
                          rows={5}
                          name="description"
                          componentClass="textarea"
                          disabled={user.isTechnician()}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Observações do técnico</ControlLabel>
                        <FormControl
                          onChange={handleChange}
                          rows={5}
                          name="technicianDescription"
                          componentClass="textarea"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Observações da LOG</ControlLabel>
                        <FormControl
                          onChange={handleChange}
                          rows={5}
                          name="logDescription"
                          componentClass="textarea"
                          disabled={user.isTechnician()}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Panel>
                <Panel header="Serviços da assistência">
                  <PanelGroup bordered>
                    <Panel header="Ações">
                      <ButtonToolbar>
                        <Button
                          color="primary"
                          onClick={() => setAddFlaw(true)}
                        >
                          <Icon icon="plus" />
                          <span className="padding-left">
                            Adicionar serviço
                          </span>
                        </Button>
                      </ButtonToolbar>
                    </Panel>
                    <Panel>
                      <Table data={laudo.flaws} height={500}>
                        <Column width={350}>
                          <HeaderCell>Descrição</HeaderCell>
                          <Cell dataKey="description" />
                        </Column>
                        <Column width={220}>
                          <HeaderCell>Valor técnico</HeaderCell>
                          <Cell dataKey="technicianValue">
                            {(rowData) => {
                              if (editingServices) {
                                return (
                                  <input
                                    className="rs-input"
                                    style={{ marginTop: '-8px' }}
                                    defaultValue={rowData['technicianValue']}
                                    type="number"
                                    onChange={(event) => {
                                      let temp = nextFlaws
                                      temp[rowData.id] = {
                                        ...rowData,
                                        technicianValue: Number.parseFloat(
                                          event.target.value
                                        ),
                                      }
                                      setNextFlaws(temp)
                                    }}
                                  />
                                )
                              }
                              if (rowData.technicianValue)
                                return (
                                  'R$ ' + rowData.technicianValue.toFixed(2)
                                )
                              return 'R$ 0,00'
                            }}
                          </Cell>
                        </Column>
                        {(userData.roles.includes('admin') ||
                          userData.roles.includes('employee')) && (
                          <Column width={180}>
                            <HeaderCell>Valor cliente</HeaderCell>
                            <Cell dataKey="customerValue">
                              {(rowData) => {
                                if (editingServices) {
                                  return (
                                    <input
                                      className="rs-input"
                                      style={{ marginTop: '-8px' }}
                                      defaultValue={rowData['customerValue']}
                                      type="number"
                                      onChange={(event) => {
                                        let temp = nextFlaws
                                        temp[rowData.id] = {
                                          ...rowData,
                                          customerValue: Number.parseFloat(
                                            event.target.value
                                          ),
                                        }
                                        setNextFlaws(temp)
                                      }}
                                    />
                                  )
                                }
                                if (rowData.customerValue)
                                  return (
                                    'R$ ' + rowData.customerValue.toFixed(2)
                                  )
                                return 'R$ 0,00'
                              }}
                            </Cell>
                          </Column>
                        )}
                        <Column width={220}>
                          <HeaderCell>Ações</HeaderCell>
                          <Cell className="link-group">
                            {(rowData) => {
                              return (
                                <>
                                  <ButtonToolbar>
                                    <IconButton
                                      appearance="subtle"
                                      onClick={() => {
                                        let objs = []

                                        let deleted = false

                                        laudo.flaws.forEach((v, i) => {
                                          if (!deleted) {
                                            if (
                                              v._id !== rowData._id ||
                                              v.customerValue !==
                                                rowData.customerValue ||
                                              v.technicianValue !==
                                                rowData.technicianValue
                                            )
                                              objs.push(v)
                                            else deleted = true
                                          } else {
                                            objs.push(v)
                                          }
                                        })

                                        setLaudo({ ...laudo, flaws: objs })
                                      }}
                                      icon={<Icon icon="trash" />}
                                    />
                                    {user.isTechnician() ? (
                                      <IconButton
                                        appearance="subtle"
                                        onClick={() => {
                                          ConfirmationPopup(
                                            'Tem certeza que deseja zerar o valor do técnico para esse serviço?'
                                          ).then((confirm) => {
                                            if (confirm) {
                                              let temp = laudo.flaws
                                              temp[
                                                rowData.id
                                              ].technicianValue = 0
                                              setLaudo({
                                                ...laudo,
                                                flaws: temp,
                                              })
                                            }
                                          })
                                        }}
                                        // style={{ color: '#f44336' }}
                                        icon={<Icon icon="close" />}
                                      >
                                        Zerar valor técnico
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        appearance="subtle"
                                        onClick={handleEditServices}
                                        icon={
                                          <Icon
                                            icon={
                                              editingServices
                                                ? 'save'
                                                : 'pencil'
                                            }
                                          />
                                        }
                                      />
                                    )}
                                  </ButtonToolbar>
                                </>
                              )
                            }}
                          </Cell>
                        </Column>
                      </Table>
                    </Panel>
                  </PanelGroup>
                </Panel>
              </PanelGroup>

              {/* BILLING INFO */}
              <PanelGroup
                bordered
                className={activeTab !== 'billing' && 'hide'}
              >
                <Panel header="Assistência">
                  <Row>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Avaliação</ControlLabel>
                        <FormControl
                          onChange={(v) => handleChange(v, 'rating', true)}
                          name="rating"
                          searchable={false}
                          accepter={SelectPicker}
                          data={[
                            { label: 'Não se aplica', value: 0 },
                            { label: 'Pago', value: 1 },
                          ]}
                          style={{ width: '100%' }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Técnico</ControlLabel>
                        <FormControl
                          onChange={(v) => handleChange(v, 'technicianPaid')}
                          name="technicianPaid"
                          searchable={false}
                          accepter={SelectPicker}
                          data={[
                            { label: 'Não pago', value: false },
                            { label: 'Pago', value: true },
                          ]}
                          style={{ width: '100%' }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Nº nota fiscal</ControlLabel>
                        <FormControl
                          onChange={handleChange}
                          name="assistanceNFE"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Panel>

                <Panel header="Frete">
                  <Row>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Valor</ControlLabel>
                        <FormControl
                          onChange={(v) =>
                            handleChange(v, 'shippingType', true)
                          }
                          name="shippingType"
                          searchable={false}
                          accepter={SelectPicker}
                          data={[
                            { label: 'Não incluso', value: 0 },
                            { label: 'Incluso', value: 1 },
                            { label: 'Gratuito', value: 2 },
                          ]}
                          style={{ width: '100%' }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Valor</ControlLabel>
                        <FormControl
                          onChange={(v) => handleChange(v, 'shipping', true)}
                          min={0}
                          accepter={InputNumber}
                          name="shipping"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={24} md={8}>
                      <FormGroup>
                        <ControlLabel>Código de rastreio</ControlLabel>
                        <FormControl
                          onChange={handleChange}
                          name="trackingCode"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Panel>

                <Panel header="Valores">
                  <Row>
                    <Col sm={12} md={8}>
                      <h5>Custo do cliente</h5>
                      <h3>
                        R$ {Number.parseFloat(values.customer).toFixed(2)}
                      </h3>
                    </Col>
                    <Col sm={12} md={8}>
                      <h5>Custo do técnico</h5>
                      <h3>
                        R$ {Number.parseFloat(values.technician).toFixed(2)}
                      </h3>
                    </Col>
                    <Col sm={12} md={8}>
                      <h5>Saldo</h5>
                      <h3>
                        R${' '}
                        {(
                          Number.parseFloat(values.customer) -
                          Number.parseFloat(values.technician)
                        ).toFixed(2)}
                      </h3>
                    </Col>
                  </Row>
                </Panel>
              </PanelGroup>

              {/* ATTACHMENTS */}
              <PanelGroup
                bordered
                className={activeTab !== 'attachments' && 'hide'}
                style={{ padding: 20, paddingBottom: 10 }}
              >
                <Uploader
                  action={
                    API_URL + '/cdn/upload/technical-assistance/' + laudo.id
                  }
                  headers={{
                    authorization: getAuthToken(),
                  }}
                  onSuccess={() => getFiles()}
                  multiple
                >
                  <Button
                    bordered
                    style={{ backgroundColor: '#57bf00', color: 'white' }}
                  >
                    <Icon icon="plus" size="1x" />
                    <span className="padding-left">Adicionar</span>
                  </Button>
                </Uploader>

                {attachments.map((v, i) => {
                  return (
                    <File
                      key={i}
                      file={{
                        name: v.filename,
                        type: v.type,
                        size: (v.size / 1024 / 1024).toFixed(2),
                        date: v.updatedAt
                          .split('T')[0]
                          .replace('-', '/')
                          .replace('-', '/'),
                        time:
                          parseInt(
                            v.updatedAt
                              .split('T')[1]
                              .split('.')[0]
                              .split(':')[0]
                          ) -
                          3 +
                          ':' +
                          v.updatedAt.split('T')[1].split('.')[0].split(':')[1],
                        author:
                          v.author &&
                          v.author.charAt(0).toUpperCase() + v.author.slice(1),
                        _id: v._id,
                      }}
                      spacing={attachments.length - 1 !== i}
                      reload={() => getFiles()}
                    />
                  )
                })}
              </PanelGroup>
            </Panel>

            {/* UPDATES */}
            {activeTab === 'updates' && (
              <Panel
                header="Registros"
                style={activeTab !== 'updates' ? { display: 'none' } : {}}
              >
                <LaudoUpdatesTable data={updates} />
              </Panel>
            )}
          </PanelGroup>

          <AddFlawModal
            show={addFlaw}
            flaws={modalFlaws}
            onSubmit={handleAddFlaw}
            onClose={() => setAddFlaw(false)}
          />
        </Form>
      </React.Fragment>
    </Container>
  )
}
