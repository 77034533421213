import React, { useState } from 'react'
import {
  Divider,
  Col,
  Row,
  Container,
  Panel,
  Icon,
  PanelGroup,
  TagPicker,
  InputGroup,
  Input
} from 'rsuite'
import api from '../../services/api'

import { useSelector } from 'react-redux';

// eslint-disable-next-line no-unused-vars
import LaudosTable from '../../components/LaudosTable'
import LaudoModal from '../../components/LaudoModal'

export default function LaudosPage ({ ...props }) {
  document.title = 'Laudos | LOG-in'

  const [laudos, setLaudos] = useState(false)
  const [selected, setSelected] = useState(null)
  const [modal, setModal] = useState(false)
  const [filtros, setFiltros] = useState({ status: [], search: '' })

  const statuses = useSelector(state => state.statuses).map((v) => ({ ...v, label: v.name, value: v._id }))

  const getData = async () => {
    await api
      .get('/laudos')
      .then(response => {
        return response.data
      })
      .then(response => {
        setLaudos(response)
      })
  }

  if (laudos === false) getData()

  function compare(a, b) {
    let nameA = a.toUpperCase();
    let nameB = b.toUpperCase();
  
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  return (
    <Container className='padding-left'>
      <h1>Laudos</h1>
      <Divider />
      <PanelGroup bordered>
        <Panel header='Filtros'>
          <Row>
            <Col md={8} sm={12}>
              <TagPicker
                data={statuses.length > 0 ? statuses : []}
                onChange={status => {
                  setFiltros({ ...filtros, status })
                }}
                placeholder='Status'
                style={{ width: '100%' }}
                sort={() => {
                  return (a, b) => {
                    return compare(a.name, b.name)
                  }
                }}
              />
            </Col>
            <Col md={8} sm={12} mdOffset={8}>
              <InputGroup inside>
                <Input
                  data={filtros.search}
                  onChange={search => {
                    setFiltros({ ...filtros, search })
                  }}
                  placeholder='Pesquisar'
                />
                <InputGroup.Button disabled>
                  <Icon icon='search' />
                </InputGroup.Button>
              </InputGroup>
            </Col>
          </Row>
        </Panel>
        <Panel>
          <LaudosTable
            status={statuses}
            history={props.history}
            edit
            handleShow={data => {
              setSelected(data)
              setModal(true)
            }}
            handleEdit={() => {}}
            filtros={filtros}
            data={laudos}
          />
        </Panel>
      </PanelGroup>

      <LaudoModal
        status={statuses}
        history={props.history}
        refresh={getData}
        show={modal}
        handleClose={() => setModal(false)}
        data={selected}
      />
    </Container>
  )
}
