import React, { useState, useEffect } from 'react'
import {
  Divider,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Container,
  Alert,
  Panel,
  SelectPicker,
  Button,
  Icon,
  ButtonToolbar,
  PanelGroup,
  Input,
  InputGroup,
  Modal,
  Schema
} from 'rsuite'
import CustomersTable from '../../components/CustomersTable'
import api from '../../services/api'
import { ReadableDate } from '../../helper/date'

function CreateModal ({ ...props }) {

  const show = props.show ? props.show : false
  const [formValue, setFormValue] = useState({name: '', email: '', document: '', telephone: ''})
  const handleClose = props.onClose
  const handleRefresh = props.onRefresh

  const { StringType } = Schema.Types

  let model = Schema.Model({
    name: StringType().isRequired('O campo "nome" é obrigatório.'),
    email: StringType().isRequired('O campo "email" é obrigatório.').isEmail('Por favor, insira um e-mail válido.'),
    document: StringType(),
    telephone: StringType(),
    institution: StringType(),
    profile: StringType(),
    profile_final: StringType()
  })

  useEffect(() => {
    if (formValue.profile) {
      if (formValue.profile !== 'biologist' && formValue.profile !== 'other')
        formValue.profile_final = formValue.profile
    }
  }, [formValue])

  const [, setFormModel] = useState(model)

  const handleSubmit = () => {
    const errors = model.check(formValue)
    let hasError = false
    
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key];
        if (element.hasError === true) {
          hasError = element.errorMessage
          break
        }
      }
    }

    if (hasError) {
      return Alert.error(hasError)
    }

    api.post('/customer', formValue)
    .then(response => { return response.data })
    .then(response => { 
      if (response.error)
        return Alert.error(response.error)
      
      Alert.success('Cliente inserido com sucesso!')

      handleRefresh()
      handleClose()
    })
  }

  const { profile } = formValue

  return (
    <Modal show={show} overflow={false} onHide={handleClose}>
      <Modal.Header>
        <h1>
          Adicionar cliente
        </h1>
      </Modal.Header>
      <Modal.Body>
        <Form 
        fluid
        onChange={setFormValue}
        formValue={formValue}
        onCheck={setFormModel}
        model={model} >
          <FormGroup>
            <ControlLabel>Nome completo</ControlLabel>
            <FormControl name="name" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>E-mail</ControlLabel>
            <FormControl name="email" type="email" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Telefone</ControlLabel>
            <FormControl name="telephone" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Documento</ControlLabel>
            <FormControl name="document" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Instituição/Empresa</ControlLabel>
            <FormControl name="institution" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Perfil</ControlLabel>
            <FormControl name="profile" block accepter={SelectPicker} searchable={false} data={[
              { value: 'biologist', label: 'Biólogo' },
              { value: 'observer', label: 'Observador/Interessado pela natureza' },
              { value: 'manager', label: 'Setor de compras/Administrativo/Logística/Suprimentos' },
              { value: 'other', label: 'Outro (digite abaixo)' }
              ]} />
          </FormGroup>

          { profile === 'biologist' && (
            <>
              <FormGroup>
                <ControlLabel>Cargo</ControlLabel>
                <FormControl name="profile_final" block searchable={false} accepter={SelectPicker} data={[
                  { value: 'Setor Administrativo', label: 'Setor Administrativo' },
                  { value: 'Consultoria Ambiental', label: 'Consultoria Ambiental' },
                  { value: 'Órgão Público', label: 'Órgão Público' },
                  { value: 'Professor e/ou Pesquisador vinculado a instituição de ensino e pesquisa', label: 'Professor e/ou Pesquisador vinculado a instituição de ensino e pesquisa' },
                  { value: 'Aluno graduação', label: 'Aluno graduação' }
                  ]} />
              </FormGroup>
            </>
          )}
          
          { profile === 'other' && (
            <FormGroup>
              <ControlLabel>Cargo</ControlLabel>
              <FormControl name="profile_final" />
            </FormGroup>
          )}

          <br/><br/><br/><br/><br/>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} appearance="primary">
          Adicionar
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function ViewModal ({ ...props }) {
  if (!props.data)
  return null

  const handleLink = () => {
    props.history.push('/customers/'+props.data._id)
  }

  return (
    <Modal
      size='md'
      overflow={false}
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Header>
        <h2>
          Cliente #{props.data.id}
        </h2>
      </Modal.Header>

      <Modal.Body>
        <h2>Contato</h2>
        <h3>
          <b>Nome:</b> {props.data.name}
        </h3>
        <h3>
          <b>Email:</b> {props.data.email}
        </h3>
        <h3>
          <b>Documento:</b> {props.data.document}
        </h3>
        <h3>
          <b>Telefone:</b> {props.data.telephone}
        </h3>
        <h3>
          <b>Perfil:</b> {props.data.profile}
        </h3>
        <h3>
          <b>Cargo:</b> {props.data.profile_final}
        </h3>
        { props.data.address && (
          <>
            <Divider />
            <h2>Endereço</h2>
            { props.data.address.cep && (
              <h3>
                <b>CEP:</b> {props.data.address.cep}
              </h3>
            )}
            { props.data.address.street && (
              <h3>
                <b>Rua:</b> {props.data.address.street}
              </h3>
            )}
            { props.data.address.number && (
              <h3>
                <b>Número:</b> {props.data.address.number}
              </h3>
            )}
            { props.data.address.complement && (
              <h3>
                <b>Complemento:</b> {props.data.address.complement}
              </h3>
            )}
            { props.data.address.district && (
              <h3>
                <b>Bairro:</b> {props.data.address.district}
              </h3>
            )}
            { props.data.address.town && (
              <h3>
                <b>Cidade:</b> {props.data.address.town}
              </h3>
            )}
            { props.data.address.state && (
              <h3>
                <b>Estado:</b> {props.data.address.state}
              </h3>
            )}
          </>
        ) }
        <Divider />
        <h2>Informações gerais</h2>
        <h3>
          Criado em:{' '}
          {ReadableDate(props.data.createdAt)}
        </h3>
        {!props.new && (
          <h3>
            Atualizado em:{' '}
            {ReadableDate(props.data.updatedAt)}
          </h3>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.handleClose} appearance='primary'>
          Fechar
        </Button>
        <Button onClick={() => handleLink()} appearance='ghost'>
          Editar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function CustomersPage ({ ...props }) {
  const [customers, setCustomers] = useState(false)
  const [selected, setSelected] = useState(null)
  const [filtros, setFiltros] = useState({ search: '' })
  const [createModal, setCreateModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)

  document.title = 'Clientes | LOG-in'

  const getData = () => {
    api
      .get('/customers')
      .then(result => {
        return result.data
      })
      .then(result => {
        setCustomers(result)
      })
  }

  if (!customers) getData()

  return (
    <Container className='padding-left'>
      <h1>Clientes</h1>
      <Divider />
      <PanelGroup bordered>
        <Panel header='Ações'>
          <Row>
            <Col md={8} sm={12}>
              <ButtonToolbar>
                <Button color='primary' onClick={() => setCreateModal(true)}>
                  <Icon icon='plus' />
                  <span className='padding-left'>Adicionar cliente</span>
                </Button>
              </ButtonToolbar>
            </Col>
            <Col md={8} sm={12} mdOffset={8}>
              <InputGroup inside>
                <Input
                  data={filtros.search}
                  onChange={search => {
                    setFiltros({ ...filtros, search })
                  }}
                  placeholder='Pesquisar'
                />
                <InputGroup.Button disabled>
                  <Icon icon='search' />
                </InputGroup.Button>
              </InputGroup>
            </Col>
          </Row>
        </Panel>
        <Panel>
          <CustomersTable
            filtros={filtros}
            data={customers}
            edit
            handleShow={(rowData) => {setSelected(rowData); setViewModal(true)}}
            history={props.history}
          />
        </Panel>
      </PanelGroup>
      <CreateModal history={props.history} show={createModal} onClose={() => setCreateModal(false)} onRefresh={() => getData()} />
      <ViewModal history={props.history} data={selected} show={viewModal} handleClose={() => setViewModal(false)}/>
    </Container>
  )
}
