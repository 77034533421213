import React, { useCallback, useEffect, useState } from 'react'
import {
  Divider,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Container,
  Alert,
  Panel,
  SelectPicker,
  Button,
  Icon,
  ButtonToolbar,
  PanelGroup,
  IconButton,
  Notification,
} from 'rsuite'
import api from '../../services/api'

export default function RentalProductPage ({ match, ...props }) {
  const [product, setProduct] = useState(false)
  const [models, setModels] = useState([])
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)

  document.title = `Produto de locação #${product ? product.id : ''} | LOG-in`

  const updateModelData = useCallback(() => {
    const model = models.find(x => x._id === product.modelId)
    if (model && product.model.id !== model.id) {
      const x = { ...model }
      delete x.id
      delete x._id
      setProduct({
        ...product,
        ...x
      })
    }
  }, [models, product]);

  useEffect(() => {
    updateModelData();
  }, [models, product, product.modelId, updateModelData])

  const getData = async () => {
    await api.get('/rental/models').then(response => setModels(response.data))

    await api
      .get('/rental/products/' + match.params.id)
      .then(response => {
        return response.data
      })
      .then(response => {
        setProduct(response)
      })
  }

  const handleSave = () => {
    setSaving(true)
    api
      .put(`/rental/products/${product._id}`, product)
      .then(response => {
        return response.data
      })
      .then(response => {
        setSaving(false)

        if (response.error) return Alert.error(response.error)

        Alert.success('Produto atualizado com sucesso!')
      })
  }

  const deleteProduct = () => {
    api
      .delete('/rental/products/' + product._id)
      .then(response => {
        return response.data
      })
      .then(response => {
        setDeleting(false)
        Notification.remove('sureNotification')

        if (response.error) return Alert.error(response.error)

        Alert.success('Produto excluído com sucesso!')

        props.history.push('/rental/products')
      })
  }

  const handleDelete = () => {
    setDeleting(true)
    Notification.open({
      title: 'Aviso',
      duration: 10000,
      key: 'sureNotification',
      description: (
        <div>
          <p>Você realmente deseja remover esse produto?</p>
          <small>Essa ação é irreversível.</small>
          <br />
          <br />
          <ButtonToolbar>
            <Button color='red' onClick={deleteProduct}>
              Sim
            </Button>
            <Button
              color='primary'
              onClick={() => {
                setDeleting(false)
                Notification.remove('sureNotification')
              }}
            >
              Cancelar
            </Button>
          </ButtonToolbar>
        </div>
      )
    })
  }

  if (product === false) getData()

  if (!product) return <Container className='padding-left' />

  return (
    <Container className='padding-left'>
      <React.Fragment>
        <h1>Produto #{product.id}</h1>
        <Divider />
        <Form
          fluid
          formValue={product}
          onChange={val => {
            setProduct(val)
          }}
        >
          <PanelGroup bordered>
            <Panel header='Ações'>
              <ButtonToolbar>
                <IconButton
                  onClick={handleSave}
                  appearance='primary'
                  loading={saving}
                  style={{ marginBottom: '10px' }}
                >
                  <Icon icon='save' />
                  Salvar
                </IconButton>

                <IconButton
                  onClick={handleDelete}
                  appearance='primary'
                  color='red'
                  loading={deleting}
                  style={{ marginBottom: '10px' }}
                >
                  <Icon icon='trash' />
                  Excluir
                </IconButton>
              </ButtonToolbar>
            </Panel>

            <Panel header='Características'>
              <Row>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Modelo</ControlLabel>
                    <FormControl name="modelId" block accepter={SelectPicker} searchable={false} data={models.map(x => ({ value: x._id, label: x.name }))} />
                  </FormGroup>
                </Col>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Número de série</ControlLabel>
                    <FormControl name='serialNumber' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Ano de fabricação</ControlLabel>
                    <FormControl disabled name='fabYear' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Tipo de LED</ControlLabel>
                    <FormControl disabled name="ledType" block accepter={SelectPicker} searchable={false} data={[
                      { value: 'No glow', label: 'No glow' },
                      { value: 'Low glow', label: 'Low glow' },
                      { value: 'No glow com visor', label: 'No glow com visor' },
                      { value: 'Low glow com visor', label: 'Low glow com visor' }
                    ]} />
                  </FormGroup>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm={24} md={24}>
                  <FormGroup>
                    <ControlLabel>Imagem</ControlLabel>
                    <FormControl disabled name='picture' />
                  </FormGroup>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm={24} md={24}>
                  <FormGroup>
                    <ControlLabel>Descrição</ControlLabel>
                    <FormControl disabled name='description' componentClass='textarea' rows={3} />
                  </FormGroup>
                </Col>
              </Row>
            </Panel>

            <Panel header='Entrega'>
              <Row>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Peso (gramas)</ControlLabel>
                    <FormControl disabled name='weight' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Altura (cm)</ControlLabel>
                    <FormControl disabled name='height' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Comprimento (cm)</ControlLabel>
                    <FormControl disabled name='width' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Largura (cm)</ControlLabel>
                    <FormControl disabled name='length' />
                  </FormGroup>
                </Col>
              </Row>
            </Panel>

            <Panel header='Faturamento'>
              <Row>
                <Col sm={24} md={8}>
                  <FormGroup>
                    <ControlLabel>Preço diário (R$)</ControlLabel>
                    <FormControl disabled type='number' name='dailyPrice' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={8}>
                  <FormGroup>
                    <ControlLabel>Preço quinzenal (R$)</ControlLabel>
                    <FormControl disabled type='number' name='weeklyPrice' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={8}>
                  <FormGroup>
                    <ControlLabel>Preço mensal (R$)</ControlLabel>
                    <FormControl disabled type='number' name='monthlyPrice' />
                  </FormGroup>
                </Col>
              </Row>
            </Panel>
          </PanelGroup>
        </Form>
      </React.Fragment>
    </Container>
  )
}
