/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Table } from 'rsuite'

const { Cell, HeaderCell, Column, Pagination: TablePagination } = Table

export default function RentalCamerasTable({ ...props }) {
  const [data, setData] = useState([])
  const [sortColumn, setSortColumn] = useState('description')
  const [sortType, setSortType] = useState('asc')
  const [displayLength, setDisplayLength] = useState(10)
  const [page, setPage] = useState(1)

  const { duration } = props

  useEffect(() => {
    async function resolve(nextProps) {
      if (nextProps.data) {
        setData(nextProps.data)
      }
    }

    resolve(props)
  }, [props.data])

  function getData() {
    let resultData = [...data]

    if (sortColumn && sortType) {
      resultData = resultData.sort((a, b) => {
        let x = a[sortColumn]
        let y = b[sortColumn]

        if (typeof x === 'number' && typeof y === 'number') {
          if (sortType === 'asc') return x < y
          else return x > y
        }

        if (typeof x === 'string') {
          x = x.charCodeAt()
        }
        if (typeof y === 'string') {
          y = y.charCodeAt()
        }
        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }

    return resultData
  }

  function handleSortColumn(sortColumn, sortType) {
    setSortColumn(sortColumn)
    setSortType(sortType)
  }

  function handleChangePage(dataKey) {
    setPage(dataKey)
  }

  function handleChangeLength(dataKey) {
    setPage(1)
    setDisplayLength(dataKey)
  }

  const originalData = getData()

  const newData = originalData.filter((v, i) => {
    const start = displayLength * (page - 1)
    const end = start + displayLength
    return i >= start && i < end
  })

  return (
    <div>
      <Table
        data={newData}
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={handleSortColumn}
        loading={props.loading}
        height={500}
      >
        <Column sortable width={110}>
          <HeaderCell>ID da câmera</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column sortable width={500}>
          <HeaderCell>Modelo</HeaderCell>
          <Cell dataKey="model.name" />
        </Column>
        <Column sortable width={200}>
          <HeaderCell>Valor de referência</HeaderCell>
          <Cell dataKey="model.price">
            {(row) => {
              if (duration >= 30) {
                return 'R$ ' + row.monthlyPrice.toLocaleString()
              } else if (duration >= 15) {
                return 'R$ ' + row.weeklyPrice.toLocaleString()
              } else {
                return 'R$ ' + row.dailyPrice.toLocaleString()
              }
            }}
          </Cell>
        </Column>
        <Column sortable width={200}>
          <HeaderCell>Valor total</HeaderCell>
          <Cell dataKey="model.price">
            {(row) => {
              if (duration >= 30) {
                return 'R$ ' + (row.monthlyPrice * duration).toLocaleString()
              } else if (duration >= 15) {
                return 'R$ ' + (row.weeklyPrice * duration).toLocaleString()
              } else {
                return 'R$' + (row.dailyPrice * duration).toLocaleString()
              }
            }}
          </Cell>
        </Column>
      </Table>
      <TablePagination
        lengthMenu={[
          {
            value: 10,
            label: 10,
          },
          {
            value: 20,
            label: 20,
          },
        ]}
        activePage={page}
        displayLength={displayLength}
        total={originalData.length}
        onChangePage={handleChangePage}
        onChangeLength={handleChangeLength}
      />
    </div>
  )
}
