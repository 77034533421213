const INITIAL_STATE = {
  logged: 'fetching',
  username: '',
  roles: []
}

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_USER_DATA':
      return action.user
    default:
      return state
  }
}

export default user