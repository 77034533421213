/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import MainScreen from './view/MainScreen'
import { BrowserRouter } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import api from './services/api'
import { useSelector, useDispatch } from 'react-redux'
import LoadingScreen from './view/LoadingScreen'

const App = ({ ...props }) => {
  const userData = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [showContent, setShowContent] = useState(false)

  const changeUserData = (data) => {
    dispatch({ type: 'CHANGE_USER_DATA', user: data })
  }

  const changeStatuses = (data) => {
    dispatch({ type: 'CHANGE_STATUS_DATA', statuses: data })
  }

  useEffect(() => {
    api
      .get('/logged')
      .then((response) => response.data)
      .then((response) => {
        if (response.error) {
          return changeUserData({ logged: false })
        }
        changeUserData({ logged: true, ...response })

        api
          .get('/status')
          .then((response) => response.data)
          .then((response) => {
            changeStatuses(response)
          })
      })
  }, [])

  let { logged } = userData

  if (logged === true)
    return (
      <BrowserRouter>
        <LoadingScreen transition={loading} />
        <MainScreen />
      </BrowserRouter>
    )

  if (logged === false) return <LoginPage />

  if (logged === true && showContent === false) {
    setShowContent('changing')
    setTimeout(() => {
      setShowContent(true)
      setLoading(false)
    }, 1000)
  }

  return <LoadingScreen />
}

export default App
