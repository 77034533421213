import React from 'react';
import {Table, IconButton, Icon} from 'rsuite';
const {Column, HeaderCell, Cell, Pagination} = Table;

class ActionCell extends React.Component {
  render() {
    return (
      <Cell {... this.props} className="table-button">
        <IconButton
          appearance="subtle"
          onClick={() => this.props.handleModal({ ...this.props })}
          icon={<Icon icon="eye" />}
        />
      </Cell>
    );
  }
};

export default class ProductsTable extends React.Component {
  constructor (props) {
    super (props);
    const data = [{}];
    this.state = {
      data,
      displayLength: 20,
      addColumn: false,
      loading: true,
      page: 1,
      sortColumn: 'name',
      sortType: 'asc'
    };
    this.handleSortColumn = this.handleSortColumn.bind (this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeLength = this.handleChangeLength.bind(this);
    this.handleModal = this.props.handleModal;
  }
  handleChangePage(dataKey) {
    this.setState({
      page: dataKey
    });
  }
  handleChangeLength(dataKey) {
    this.setState({
      page: 1,
      displayLength: dataKey
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({data: nextProps.data})
    }
  }
  componentDidMount () {
    this.checkProps = setInterval (() => {
      if (this.props.data != null) {
        this.setState ({data: this.props.data, loading: false});
        clearInterval(this.checkProps)
      }
    }, 250);
  }

  // handleModal({rowData, dataKey}) {
  //   this.props.handleModal({rowData, dataKey})
  // }

  getData () {

    let { displayLength, page, data } = this.state;

    const {sortColumn, sortType} = this.state;

    if (sortColumn && sortType) {
      data = data.sort ((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt ();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt ();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }

    return data.filter((v, i) => {
      const start = displayLength * (page - 1);
      const end = start + displayLength;
      return i >= start && i < end;
    });
  }

  handleSortColumn (sortColumn, sortType) {
    this.setState ({
      loading: true,
    });

    setTimeout (() => {
      this.setState ({
        sortColumn,
        sortType,
        loading: false,
      });
    }, 500);
  }

  render () {
    const {page, displayLength, data} = this.state;
    const getData = this.getData ();

    let loading = this.props.loading ? (this.props.loading === true ? true : this.state.loading) : this.state.loading

    return (
      <div>
        <Table
          height={420}
          data={getData}
          sortColumn={this.state.sortColumn}
          sortType={this.state.sortType}
          onSortColumn={this.handleSortColumn}
          loading={loading}
        >
          <Column width={150} align="center" resizable sortable>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="id" />
          </Column>

          <Column width={100} align="center" resizable>
            <HeaderCell>SKU</HeaderCell>
            <Cell dataKey="sku" />
          </Column>

          <Column width={500} resizable sortable>
            <HeaderCell>Nome</HeaderCell>
            <Cell dataKey="name" />
          </Column>

          <Column width={100} resizable>
            <HeaderCell>Preço</HeaderCell>
            <Cell dataKey="price" />
          </Column>

          <Column width={150} resizable>
            <HeaderCell>Quantidade</HeaderCell>
            <Cell dataKey="quantity" />
          </Column>

          <Column fixed="right">
            <HeaderCell>Visualizar</HeaderCell>
            <ActionCell handleModal={this.handleModal} dataKey={'id'} />
          </Column>

        </Table>
        <Pagination
          lengthMenu={[
            {
              value: 10,
              label: 10
            },
            {
              value: 20,
              label: 20
            }
          ]}
          activePage={page}
          displayLength={displayLength}
          total={data.length}
          onChangePage={this.handleChangePage}
          onChangeLength={this.handleChangeLength}
        />
      </div>
    );
  }
}
