import React from 'react'
import {
  Divider,
  Alert,
  Button,
  ButtonToolbar,
  Modal,
} from 'rsuite'
import api from '../services/api'
import { ReadableDate } from '../helper/date'
import ConfirmationPopup from './ConfirmationPopup'

export default class LaudoModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: null,
    }

    this.handleInit = this.handleInit.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ data: nextProps.data })
    }
  }

  handleInit() {
    api
      .put('/laudos/init', {
        id: this.props.data.id,
      })
      .then(response => {
        return response.data
      })
      .then(response => {
        if (response.error) return Alert.error(response.error)

        this.props.refresh()
        this.props.handleClose()

        Alert.success('Laudo iniciado com sucesso')

        this.props.history.push('/technical-assistance/laudos/' + response._id)
      })
  }

  handleDenial() {
    this.props.handleDenial()
    // ConfirmationPopup('Deseja negar essa solicitação?').then(confirm => {
    //   if (confirm) {
    //     api.delete(`/solicitacoes/${this.props.data._id}`)
    //     .then(response => response.data)
    //     .then(response => {
    //       if (!response.error) {
    //         Alert.success('Solicitação negada com sucesso.')
    //         this.props.handleClose()
    //         this.props.refresh()
    //       }
    //     })
    //   }
    // })
  }

  handleDenialWithoutReason() {
    ConfirmationPopup('Deseja negar essa solicitação?').then(confirm => {
      if (confirm) {
        api.delete(`/solicitacoes/${this.props.data._id}`)
        .then(response => response.data)
        .then(response => {
          if (!response.error) {
            Alert.success('Solicitação negada com sucesso.')
            this.props.handleClose()
            this.props.refresh()
          }
        })
      }
    })
  }

  handleLink() {
    this.props.history.push(
      '/technical-assistance/laudos/' + this.props.data._id
    )
  }

  async handleChangeStatus() {
    const currentStatus = this.props.data.request_status
    const status = { color: '#fff' }

    if (currentStatus.name === 'Pendente') {
      status.name = 'Aguardando retorno'
      status.bg_color = '#fbba01'
    } else {
      status.name = 'Pendente'
      status.bg_color = '#cc0011'
    }

    await api.put('/solicitacoes/change_status/' + this.props.data._id, status)

    Alert.success('Status alterado com sucesso')

    this.props.data.request_status = status
  }

  render() {
    if (!this.props.show) return null

    let status

    if (!this.props.new) {
      this.props.status.forEach(element => {
        if (element._id === this.props.data.status) status = element.name
      })
    } else {
      status = this.props.data.request_status ? this.props.data.request_status.name : null
    }

    return (
      <Modal
        size='sm'
        overflow={false}
        show={this.props.show}
        onHide={this.props.handleClose}
      >
        <Modal.Header>
          <h2>
            {this.props.new ? 'Solicitação' : 'Laudo'} #{this.props.data.id}
          </h2>
          {/* <Modal.Title>Laudo </Modal.Title> */}
        </Modal.Header>

        <Modal.Body>
          <h2>Dados do cliente</h2>
          <h3>
            <b>Nome:</b> {this.props.data.name}
          </h3>
          <h3>
            <b>Email:</b> {this.props.data.email}
          </h3>
          <h3>
            <b>Telefone:</b> {this.props.data.telephone}
          </h3>
          {this.props.data.institution && (
            <h3>
              <b>Instituição/Empresa:</b> {this.props.data.institution}
            </h3>
          )}
          {this.props.data.profile && (
            <h3>
              <b>Perfil:</b>&nbsp;
              {(() => {
                switch(this.props.data.profile) {
                  case 'biologist':
                    return 'Biólogo'
                  case 'observer':
                    return 'Observador/Interessado pela natureza'
                  case 'manager':
                    return 'Setor de compras/Administrativo/Logística/Suprimentos'
                  case 'other':
                    return 'Personalizado'
                  default:
                    return 'Personalizado'
                }
              })()}
            </h3>
          )}
          {this.props.data.profile_final && (
            <h3>
              <b>Cargo:</b> {this.props.data.profile_final}
            </h3>
          )}
          <Divider />
          <h2>Dados do laudo</h2>
          <h3>
            <b>Status:</b> {status ? status : 'Pendente'}
            {this.props.new && (
              <>
                <br/><br/>
                <Button onClick={() => this.handleChangeStatus()} appearance='ghost'>Mudar status para "{status === 'Pendente' ? 'Aguardando retorno' : 'Pendente'}"</Button>
              </>
            )}
          </h3>
          {this.props.data.status === '5d5c662027e501140c68e728' && (
            <h3>
              <b>Status da reciclagem:</b> {this.props.data.status_reciclagem}
            </h3>
          )}
          <h3>
            <b>Número de série:</b> {this.props.data.serial_number}
          </h3>
          <h3>
            <b>Modelo:</b> {this.props.data.model}
          </h3>
          <h3>
            <b>Nota fiscal:</b> {this.props.data.cameraNFE}
          </h3>
          <h3>
            <b>Descrição do cliente:</b>
            <br />
          </h3>
          <h4>{this.props.data.description}</h4>
          {!this.props.new && (
            <>
              <h3>
                <b>Descrição do técnico:</b>
                <br />
              </h3>
              <h4>{this.props.data.technicianDescription}</h4>
              <h3>
                <b>Descrição da LOG:</b>
                <br />
              </h3>
              <h4>{this.props.data.logDescription}</h4>
            </>
          )}
          <Divider />
          <h2>Informações gerais</h2>
          <h3>Criado em: {ReadableDate(this.props.data.createdAt)}</h3>
          {!this.props.new && (
            <h3>Atualizado em: {ReadableDate(this.props.data.updatedAt)}</h3>
          )}
        </Modal.Body>

        <Modal.Footer>
          <ButtonToolbar>
            {this.props.new ? (
              <>
                <Button
                  onClick={() => this.handleDenialWithoutReason()}
                  color='red'
                  appearance='ghost'
                >
                  Negar
                </Button>
                <Button
                  onClick={() => this.handleDenial()}
                  color='red'
                  appearance='ghost'
                >
                  Negar por data de fabricação
                </Button>
                <Button onClick={() => this.handleInit()} appearance='ghost'>
                  Dar prosseguimento
                </Button>
              </>
            ) : (
              <Button onClick={() => this.handleLink()} appearance='ghost'>
                Ir para laudo
              </Button>
            )}
            <Button onClick={this.props.handleClose} appearance='primary'>
              Fechar
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
