import React, {Component} from 'react';
import { Divider, Container, Alert, Panel, Button, Form, FormControl, FormGroup, ControlLabel, HelpBlock, PanelGroup } from 'rsuite';
import api from '../../services/api';

export default class UserSettingsPage extends Component{
  constructor(props) {
    super(props)
    
    document.title = 'Configurações da conta | LOG-in'

    this.state = {
      formValue: {
        username: '',
        email: '',
        password: '',
        newPassword: '',

      },
      changingPassword: false,
      changingPublic: false
    }

    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleChangePublic = this.handleChangePublic.bind(this)
  }
  componentDidMount() {
    api.get('/user/info')
    .then(response => { return response.data })
    .then(response => {
      if (response.birthday && response.birthday.split('T').length > 1) response.birthday = response.birthday.split('T')[0]
      this.setState({formValue: response})
    })
  }
  handleChangePassword() {
    this.setState({changingPassword: true})

    api.put('/user/change_password', this.state.formValue)
    .then(response => {return response.data})
    .then(response => {
      this.setState({changingPassword: false})

      if (response.error) {
        return Alert.error(response.error)
      }

      Alert.success('Senha alterada com sucesso')
    })
  }
  handleChangePublic() {
    this.setState({changingPublic: true})

    api.put('/user/change_public_data', this.state.formValue)
    .then(response => {return response.data})
    .then(response => {
      this.setState({changingPublic: false})

      if (response.error) {
        return Alert.error(response.error)
      }

      Alert.success('Dados alterados com sucesso')
    })
  }
  render() {
    const { formValue } = this.state

    return (
      <Container className="padding-left">
        <h1>Configurações da conta</h1>
        <Divider />
        <PanelGroup bordered>
          <Panel header='Dados sensíveis'>
            <Form 
              fluid
              formValue={formValue}
              onChange={formValue => {
                this.setState({ formValue });
              }}
              >
              <FormGroup>
                <ControlLabel>Nome de usuário</ControlLabel>
                <FormControl disabled name="username" />
              </FormGroup>
              <FormGroup>
                <ControlLabel>E-mail</ControlLabel>
                <FormControl disabled name="email" />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Senha</ControlLabel>
                <FormControl type="password" name="password" />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Nova senha</ControlLabel>
                <FormControl type="password" name="newPassword" />
              </FormGroup>
              <Button loading={this.state.changingPassword} appearance="primary" onClick={this.handleChangePassword}>
                Alterar senha
              </Button>
            </Form>
          </Panel>
          
          <Panel header='Outras configurações'>
          <Form 
              fluid
              formValue={formValue}
              onChange={formValue => {
                this.setState({ formValue });
              }}
              >
              <FormGroup>
                <ControlLabel>Foto de perfil</ControlLabel>
                <FormControl name="picture" />
                <HelpBlock>Cole o link gerado ao fazer upload da foto para o nosso CDN</HelpBlock>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Aniversário</ControlLabel>
                <FormControl name="birthday" type="date" />
              </FormGroup>
              <Button loading={this.state.changingPublic} appearance="primary" onClick={this.handleChangePublic}>
                Alterar
              </Button>
            </Form>
          </Panel>
          
        </PanelGroup>
      </Container>
    )
  }
}