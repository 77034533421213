import React, {Component} from 'react';
import { Divider, Container, Alert, Panel, SelectPicker, Button, ButtonToolbar, Form, FormControl, FormGroup, ControlLabel, PanelGroup, TagGroup, Tag } from 'rsuite';
import api from '../../services/api'

export default class WarningsPage extends Component {
  constructor(props) {
    super(props)

    document.title = 'Avisos da loja | LOG-in'

    let d = new Date()

    this.state = {
      formValue: {
        expiresAt: new Date(d.getFullYear(), d.getMonth(), d.getDate()).toISOString().split('T')[0],
        type: 'text'
      },
    }

    this.handleDelete = this.handleDelete.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    api.get('/avisos')
    .then(response => { return response.data })
    .then(response => {
      if (!response.error) {
        response.expiresAt = response.expiresAt.split('T')[0]
        let formValue = {
          title: response.title,
          text: response.text,
          expiresAt: response.expiresAt,
          type: response.type
        }
        this.setState({ formValue, enabled: true })
      } else {
        this.setState({ enabled: false })
      }
    })
  }

  handleTypeChange(v) {
    const { formValue } = this.state;
    this.setState({ formValue: { ...formValue, type: v } })
  }

  handleUpdate() {
    this.setState({updating: true})

    const { formValue } = this.state

    api.post('/avisos/new', formValue)
    .then(response => { return response.data })
    .then(response => {
      if (response.error)
        Alert.error(response.error.message ? response.error.message : response.error)
      else
        Alert.success('Aviso configurado com sucesso')

      this.setState({updating: false})

      this.getData()
    })
  }

  handleDelete() {
    this.setState({deleting: true})

    api.delete('/avisos/delete')
    .then(response => { return response.data })
    .then(response => {
      if (response.error)
        Alert.error(response.error.message ? response.error.message : response.error)
      else
        Alert.success('Aviso excluído com sucesso')

      this.setState({deleting: false, enabled: false})
    })
  }

  render() {

    const { formValue, enabled, formValue: { type } } = this.state

    return (
      <Container className="padding-left">
        <h1>Avisos da loja virtual</h1>
        <Divider />
        <PanelGroup bordered>

          <Panel header='Configurar aviso'>
            <TagGroup>
              {enabled ? (
                <Tag color="blue">
                  Ativo
                </Tag>
              ) : (
                <Tag color="red">
                  Inativo
                </Tag>
              )}
            </TagGroup>
            <br/>
            <Form 
              fluid
              formValue={formValue}
              onChange={formValue => {
                this.setState({ formValue });
            }}>
              <FormGroup>
                <ControlLabel>Título do aviso</ControlLabel>
                <FormControl name="title" />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Tipo do conteúdo</ControlLabel>
                <SelectPicker
                  searchable={false}
                  cleanable={false}
                  onChange={(v) => this.handleTypeChange(v)}
                  defaultValue='text'
                  value={type}
                  data={[
                    { label: 'Texto', value: 'text' },
                    { label: 'Imagem', value: 'image' }
                  ]}
                />
              </FormGroup>
              { type === 'text' ? (
                <FormGroup>
                  <ControlLabel>Texto do aviso</ControlLabel>
                  <FormControl rows={5} name="text" componentClass="textarea" />
                </FormGroup>
              ) : (
                <FormGroup>
                  <ControlLabel>Link da imagem</ControlLabel>
                  <FormControl name="text" />
                </FormGroup>
              )}
              <FormGroup>
                <ControlLabel>Data de validade</ControlLabel>
                <FormControl name="expiresAt" type="date" />
              </FormGroup>
              <ButtonToolbar>
                <Button loading={this.state.updating} appearance="primary" onClick={this.handleUpdate}>
                  Atualizar
                </Button>
                <Button loading={this.state.deleting} appearance="primary" color="red" onClick={this.handleDelete}>
                  Excluir
                </Button>
              </ButtonToolbar>
            </Form>
          </Panel>
        </PanelGroup>
      </Container>
    )
  }
}