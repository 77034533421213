/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import {
  Icon,
  ButtonToolbar,
  IconButton,
  Table,
} from 'rsuite'
import { useSelector } from 'react-redux'

const { Cell, HeaderCell, Column, Pagination: TablePagination } = Table

export default function FlawsTable({ ...props }) {
  const userData = useSelector(state => state.user)
  const [data, setData] = useState([])
  const [sortColumn, setSortColumn] = useState('description')
  const [sortType, setSortType] = useState('asc')
  const [displayLength, setDisplayLength] = useState(10)
  const [page, setPage] = useState(1)
  const [filtros, setFiltros] = useState({ search: '' })

  useEffect(() => {
    async function resolve(nextProps) {
      if (nextProps.data) {
        setData(nextProps.data)
      }
      if (nextProps.filtros) {
        setFiltros(nextProps.filtros)
      }
    }

    resolve(props)
  }, [props.data, props.filtros])

  function getData() {
    let resultData = []

    data.forEach((v, i) => {
      if (filtros.search && filtros.search.length > 0) {
        if (
          v.description.toLowerCase().includes(filtros.search.toLowerCase()) ||
          v.technicianValue.toString().includes(filtros.search) ||
          v.customerValue.toString().includes(filtros.search)
        )
          return resultData.push(v)
        else return
      }

      resultData.push(v)
    })

    if (sortColumn && sortType) {
      resultData = resultData.sort((a, b) => {
        let x = a[sortColumn]
        let y = b[sortColumn]
        
        if (typeof x === 'number' && typeof y === 'number') {
          if (sortType === 'asc')
            return x < y
          else  
            return x > y
        }

        if (typeof x === 'string') {
          x = x.charCodeAt()
        }
        if (typeof y === 'string') {
          y = y.charCodeAt()
        }
        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }

    return resultData
  }

  function handleSortColumn(sortColumn, sortType) {
    setSortColumn(sortColumn)
    setSortType(sortType)
  }

  function handleChangePage(dataKey) {
    setPage(dataKey)
  }

  function handleChangeLength(dataKey) {
    setPage(1)
    setDisplayLength(dataKey)
  }

  const originalData = getData()

  const newData = originalData.filter((v, i) => {
    const start = displayLength * (page - 1)
    const end = start + displayLength
    return i >= start && i < end
  })

  return (
    <div>
      <Table
        data={newData}
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={handleSortColumn}
        loading={props.loading}
        height={500}
      >
        <Column sortable width={450}>
          <HeaderCell>Descrição</HeaderCell>
          <Cell dataKey='description' />
        </Column>
        {props.reports && (
          <Column sortable width={220}>
            <HeaderCell>ID do laudo</HeaderCell>
            <Cell dataKey='laudoId'>
              {rowData => {
                return rowData.laudoId
              }}
            </Cell>
          </Column>
        )}
        <Column sortable width={220}>
          <HeaderCell>Valor técnico</HeaderCell>
          <Cell dataKey='technicianValue'>
            {rowData => {
              return 'R$ ' + rowData.technicianValue.toFixed(2)
            }}
          </Cell>
        </Column>
        {(userData.roles.includes('admin') ||
          userData.roles.includes('employee')) && (
          <Column sortable width={180}>
            <HeaderCell>Valor cliente</HeaderCell>
            <Cell dataKey='customerValue'>
              {rowData => {
                return 'R$ ' + rowData.customerValue.toFixed(2)
              }}
            </Cell>
          </Column>
        )}
        {((userData.roles.includes('admin') ||
          userData.roles.includes('employee')) && !props.reports) && (
          <Column width={180} fixed='right'>
            <HeaderCell>Ações</HeaderCell>
            <Cell className='link-group'>
              {rowData => {
                return (
                  <>
                    <ButtonToolbar>
                      <IconButton
                        appearance='subtle'
                        onClick={() => props.handleEdit(rowData)}
                        icon={<Icon icon='edit2' />}
                      />
                      <IconButton
                        appearance='subtle'
                        onClick={() => props.handleDelete(rowData)}
                        icon={<Icon icon='trash' />}
                      />
                    </ButtonToolbar>
                  </>
                )
              }}
            </Cell>
          </Column>
        )}
      </Table>
      <TablePagination
        lengthMenu={[
          {
            value: 10,
            label: 10,
          },
          {
            value: 20,
            label: 20,
          },
        ]}
        activePage={page}
        displayLength={displayLength}
        total={originalData.length}
        onChangePage={handleChangePage}
        onChangeLength={handleChangeLength}
      />
    </div>
  )
}
