import React, { useState, useEffect } from 'react'
import {
  Divider,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Container,
  Alert,
  Panel,
  SelectPicker,
  Button,
  Icon,
  ButtonToolbar,
  PanelGroup,
  Input,
  InputGroup,
  Modal,
  Schema
} from 'rsuite'
import RentalModelsTable from '../../components/RentalModelsTable'
import api from '../../services/api'
import { ReadableDate } from '../../helper/date'
import ConfirmationPopup from '../../components/ConfirmationPopup'

function CreateModal ({ ...props }) {

  const show = props.show ? props.show : false
  const [formValue, setFormValue] = useState({name: '', email: '', document: '', telephone: ''})
  const handleClose = props.onClose
  const handleRefresh = props.onRefresh

  const { StringType, NumberType } = Schema.Types

  const model = Schema.Model({
    name: StringType().isRequired('O campo "nome" é obrigatório.'),
    fabYear: NumberType().isRequired('O campo "ano de fabricação" é obrigatório.'),
    description: StringType(),
    picture: StringType().isRequired('O campo "foto" é obrigatório.'),
    dailyPrice: NumberType().isRequired('O campo "preço diário" é obrigatório.'),
    monthlyPrice: NumberType().isRequired('O campo "preço mensal" é obrigatório.'),
    weeklyPrice: NumberType().isRequired('O campo "preço quinzenal" é obrigatório.'),
    weight: NumberType().isRequired('O campo "peso" é obrigatório.'),
    height: NumberType().isRequired('O campo "altura" é obrigatório.'),
    width: NumberType().isRequired('O campo "comprimento" é obrigatório.'),
    length: NumberType().isRequired('O campo "largura" é obrigatório.'),
    ledType: StringType().isOneOf(['Low glow', 'No glow', 'Low glow com visor', 'No glow com visor'])
  })

  const [, setFormModel] = useState(model)

  const handleSubmit = async () => {
    const errors = model.check(formValue)
    let hasError = false
    
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key];
        if (element.hasError === true) {
          hasError = element.errorMessage
          break
        }
      }
    }

    if (hasError) {
      return Alert.error(hasError)
    }

    await api.post('/rental/models', formValue)

    Alert.success('Modelo inserido com sucesso!')

    handleRefresh()
    handleClose()
  }

  return (
    <Modal show={show} overflow={false} onHide={handleClose}>
      <Modal.Header>
        <h1>
          Adicionar modelo
        </h1>
      </Modal.Header>
      <Modal.Body>
        <Form 
        fluid
        onChange={setFormValue}
        formValue={formValue}
        onCheck={setFormModel}
        model={model} >
          <FormGroup>
            <ControlLabel>Nome</ControlLabel>
            <FormControl name="name" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Ano de fabricação</ControlLabel>
            <FormControl name="fabYear" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Descrição</ControlLabel>
            <FormControl name="description" componentClass='textarea' rows={3} />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Foto</ControlLabel>
            <FormControl name="picture" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Preço diário</ControlLabel>
            <FormControl name="dailyPrice" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Preço quinzenal</ControlLabel>
            <FormControl name="weeklyPrice" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Preço mensal</ControlLabel>
            <FormControl name="monthlyPrice" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Peso (gramas)</ControlLabel>
            <FormControl name="weight" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Altura (cm)</ControlLabel>
            <FormControl name="height" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Comprimento (cm)</ControlLabel>
            <FormControl name="width" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Largura (cm)</ControlLabel>
            <FormControl name="length" type="number" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Tipo de LED</ControlLabel>
            <FormControl name="ledType" block accepter={SelectPicker} searchable={false} data={[
              { value: 'No glow', label: 'No glow' },
              { value: 'Low glow', label: 'Low glow' },
              { value: 'No glow com visor', label: 'No glow com visor' },
              { value: 'Low glow com visor', label: 'Low glow com visor' }
            ]} />
          </FormGroup>

          <br/><br/><br/><br/><br/>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} appearance="primary">
          Adicionar
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function ViewModal ({ ...props }) {
  if (!props.data)
  return null

  const handleLink = () => {
    props.history.push('/rental/models/'+props.data._id)
  }

  const {
    id,
    name,
    ledType,
    fabYear,
    dailyPrice,
    weeklyPrice,
    monthlyPrice,
    description,
    picture,
    weight,
    height,
    length,
    width,
    createdAt,
    updatedAt
  } = props.data

  return (
    <Modal
      size='md'
      overflow={false}
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Header>
        <h2>
          Modelo #{id}
        </h2>
      </Modal.Header>

      <Modal.Body>
        <h2>Dados do modelo</h2>
        <h3>
          <b>Nome:</b> {name}
        </h3>
        <h3>
          <b>Ano de fabricação:</b> {fabYear}
        </h3>
        <h3>
          <b>Tipo de LED:</b> {ledType}
        </h3>
        <h3>
          <b>Descrição:</b>
          <p>{description}</p>
        </h3>
        <h3>
          <b>Foto:</b> <a href={picture}>{picture}</a>
        </h3>
        <h3>
          <b>Preço diário:</b> {dailyPrice}
        </h3>
        <h3>
          <b>Preço quinzenal:</b> {weeklyPrice}
        </h3>
        <h3>
          <b>Preço mensal:</b> {monthlyPrice}
        </h3>
        <h3>
          <b>Peso (gramas):</b> {weight}
        </h3>
        <h3>
          <b>Altura (cm):</b> {height}
        </h3>
        <h3>
          <b>Comprimento (cm):</b> {width}
        </h3>
        <h3>
          <b>Largura (cm):</b> {length}
        </h3>
        <Divider />
        <h2>Informações gerais</h2>
        <h3>
          Criado em:{' '}
          {ReadableDate(createdAt)}
        </h3>
        {!props.new && (
          <h3>
            Atualizado em:{' '}
            {ReadableDate(updatedAt)}
          </h3>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.handleClose} appearance='primary'>
          Fechar
        </Button>
        <Button onClick={() => handleLink()} appearance='ghost'>
          Editar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function RentalModelsPage ({ ...props }) {
  const [models, setModels] = useState(false)
  const [selected, setSelected] = useState(null)
  const [filters, setFilters] = useState({ search: '' })
  const [createModal, setCreateModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)

  document.title = 'Modelos de locação | LOG-in'

  const getData = () => {
    api
      .get('/rental/models')
      .then(result => {
        return result.data
      })
      .then(result => {
        setModels(result)
      })
  }

  const handleDelete = async (product) => {
    const shouldDelete = await ConfirmationPopup(`Deseja excluir o modelo #${product.id}?`)
    if (shouldDelete) {
      await api.delete(`/rental/models/${product._id}`)
      Alert.success('Produto excluído com sucesso.')
      getData()
    }
  }

  useEffect(() => getData(), [])

  return (
    <Container className='padding-left'>
      <h1>Modelos de locação</h1>
      <Divider />
      <PanelGroup bordered>
        <Panel header='Ações'>
          <Row>
            <Col md={8} sm={12}>
              <ButtonToolbar>
                <Button color='primary' onClick={() => setCreateModal(true)}>
                  <Icon icon='plus' />
                  <span className='padding-left'>Adicionar modelo</span>
                </Button>
              </ButtonToolbar>
            </Col>
            <Col md={8} sm={12} mdOffset={8}>
              <InputGroup inside>
                <Input
                  data={filters.search}
                  onChange={search => {
                    setFilters({ ...filters, search })
                  }}
                  placeholder='Pesquisar'
                />
                <InputGroup.Button disabled>
                  <Icon icon='search' />
                </InputGroup.Button>
              </InputGroup>
            </Col>
          </Row>
        </Panel>
        <Panel>
          <RentalModelsTable
            handleShow={data => {
              setSelected(data)
              setViewModal(true)
            }}
            handleDelete={handleDelete}
            filers={filters}
            data={models}
            edit
            history={props.history}
          />
        </Panel>
      </PanelGroup>
      <CreateModal history={props.history} show={createModal} onClose={() => setCreateModal(false)} onRefresh={() => getData()} />
      <ViewModal history={props.history} data={selected} show={viewModal} handleClose={() => setViewModal(false)}/>
    </Container>
  )
}
