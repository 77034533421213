import React, { useEffect, useState } from 'react';
import { Alert, Button, ButtonToolbar, Container, ControlLabel, Divider, Form, FormControl, FormGroup, Panel, PanelGroup, Tag, TagGroup } from 'rsuite';
import NotificationPopup from '../../components/NotificationPopup';
import { ReadableDate } from '../../helper/date';
import api from '../../services/api';

export default function WarrantiesPage ({...props}) {

  document.title = 'Garantias Bushnell | LOG-in'
  
  let [formValue, setFormValue] = useState({})
  let [notaFiscal, setNotaFiscal] = useState([])
  let [assistencias, setAssistencias] = useState(false)
  let [searching, setSearching] = useState(false)
  let [statuses, setStatuses] = useState([])

  const getStatus = (_id) => {
    for (let i = 0; i < statuses.length; i++) {
      const element = statuses[i];
      
      if (element._id === _id)
        return element
    }
  }

  const loadStatuses = () => {
    api.get('/status')
    .then(response => response.data)
    .then(setStatuses)
  }

  useEffect(() => {
    loadStatuses()
  }, [])

  const handleSearch = () => {
    if (!formValue.serie_number || !formValue.serie_number.length > 0)
      return Alert.error('Insira um número de série')
    setSearching(true)
    api.get(`/garantias/${formValue.serie_number}`)
    .then(response => { return response.data })
    .then(response => {
      setSearching(false)

      let notas = response.notasFiscais
      let assis = response.login ? response.login : response.assistencia
      
      if (notas && notas.length > 0) {
        Alert.success(`Nota${notas.length > 1 ? 's' : ''} fisca${notas.length > 1 ? 'is' : 'l'} encontrada${notas.length > 1 ? 's' : ''}!`)
        setNotaFiscal(notas)
      } else {
        // Alert.error('Nenhuma nota fiscal encontrada!')
        setNotaFiscal([])
      }

      if (assis) {
        Alert.success(`Laudo encontrado!`)
        setAssistencias(assis)
      } else {
        // Alert.error('Nenhum laudo encontrado!')
        setAssistencias(false)
      }

      if (response.id_nota)
        NotificationPopup.show(response.error ? response.error : 'Garantia encontrada', response.id_nota ? `Nº nota fiscal: ${response.id_nota}` : undefined)
    })
  }

  return (
    <Container className="padding-left">
      <h1>Garantias</h1>
      <Divider />
      <PanelGroup
        bordered
      >
        <Panel
          header="Consultar garantia por número de série"
        >
          <Form
            fluid
            formValue={formValue}
            onChange={formValue => {
              setFormValue(formValue)
          }}>
            <FormGroup>
              <ControlLabel>Nº de série</ControlLabel>
              <FormControl name="serie_number" placeholder="BXXXXXXXXX" />
            </FormGroup>
            <ButtonToolbar>
              <Button loading={searching} appearance="primary" onClick={handleSearch}>
                Consultar
              </Button>
            </ButtonToolbar>
          </Form>
          <br/>
        </Panel>
        {(notaFiscal.length !== 0 || assistencias) && (
          <Panel>
            <h2>Foram encontradas {notaFiscal.length} nota{notaFiscal.length > 1 ? 's' : ''} fisca{notaFiscal.length > 1 ? 'is' : 'l'} e {assistencias ? 1 : 0} laudo de assistência técnica</h2>
          </Panel>
        )}
        {notaFiscal && notaFiscal.map((notaFiscal, i) => {
          return (
            
            <Panel
              header={notaFiscal.isPedido ? "Pedido encontrado" : "Nota fiscal encontrada" }
              key={i}
            >
              <TagGroup>
                {notaFiscal.status_pedido === "Atendido" ? (
                  <Tag color="green">Atendido</Tag>
                ) : (
                  <Tag color="red">{notaFiscal.status_pedido}</Tag>
                )}
              </TagGroup>
              <br/>
              <div className="more-spacing">
                <h4><b>Nº {notaFiscal.isPedido ? "do pedido" : "da nota fiscal"}:</b> {notaFiscal.id_pedido}</h4>
                <h4><b>Nome do cliente:</b> {notaFiscal.nome_cliente}</h4>
                <h4><b>Data de emissão:</b> {ReadableDate(notaFiscal.data_pedido, { showHours: false })}</h4>
                <h4><b>Validade da garantia:</b> {ReadableDate(notaFiscal.data_pedido, {showHours: false, offset: { years: 1 }})}</h4>
                <h4><b>Observação do pedido:</b><br/>{notaFiscal.obs_pedido}</h4>
                <h4><b>Observação interna do pedido:</b><br/>{notaFiscal.obs_interno_pedido}</h4>
                <br/>
                ---
                <br/>
                <small>Sincronizado em: {ReadableDate(notaFiscal.updatedAt)}</small>
              </div>
            </Panel>
          )
        }) 
        }
        {assistencias && (
            <Panel
              header="Laudo encontrado"
            >
              <TagGroup>
                <Tag style={{ backgroundColor: getStatus(assistencias.status).bg_color, color: '#fff' }}>{getStatus(assistencias.status).name}</Tag>
              </TagGroup>
              <br/>
              <div className="more-spacing">
                <h4><b>Nº do laudo:</b> {assistencias.id}</h4>
                <h4><b>Nº da solicitação:</b> {assistencias.id_solicitacao}</h4>
                <h4><b>Nome do cliente:</b> {assistencias.nome}</h4>
                <h4><b>Data de início do laudo:</b> {ReadableDate(assistencias.createdAt,{ showHours: false })}</h4>
                <h4><b>Validade da garantia:</b> {ReadableDate(assistencias.createdAt, { offset: { years: 0, days: 0, months: 6 }, showHours: false })}</h4>
                <h4><b>Observação do cliente:</b><br/>{assistencias.description}</h4>
                <h4><b>Observação do técnico:</b><br/>{assistencias.technicianDescription}</h4>
                <h4><b>Observação da LOG:</b><br/>{assistencias.logDescription}</h4>
                <br/>
                ---
                <br/>
                <small>Sincronizado em: {ReadableDate(assistencias.updatedAt)}</small>
              </div>
            </Panel>
          )
        }
      </PanelGroup>
    </Container>
  )
}
