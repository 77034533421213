import React, { Component } from 'react'
import { Modal, Button, Loader, Container, Panel, TagGroup, Tag, Table } from 'rsuite';
import { API_URL } from '../settings/variables';

const { Column, HeaderCell, Cell } = Table;

class VariantsTable extends Component {
  render() {
    return (
      <div>
        <Table
          height={300}
          data={this.props.data}
        >
          <Column align="center" fixed resizable>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="id" />
          </Column>

          <Column align="center" resizable>
            <HeaderCell>SKU</HeaderCell>
            <Cell dataKey="sku" />
          </Column>

          {/* <Column width={400}>
            <HeaderCell>Nome</HeaderCell>
            <Cell dataKey="name" />
          </Column> */}

          <Column resizable>
            <HeaderCell>Preço</HeaderCell>
            <Cell dataKey="price" />
          </Column>

          <Column resizable>
            <HeaderCell>Quantidade</HeaderCell>
            <Cell dataKey="quantity" />
          </Column>

        </Table>
      </div>
    );
  }
}

export default class ProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      overflow: true,
      rows: 0,
      loading: true
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.props.handleClose()
    this.setState({ show: false, rows: 0 });
  }
  open(event) {
    this.setState({ show: true });
    setTimeout(() => {
      this.setState({
        rows: 80
      });
    }, 2000);
  }
  getData() {
    if (this.props.source === 'vhsys') {
      // console.log('from vhsys')
      fetch(API_URL+'/category/'+this.state.product.id_categoria)
      .then((categoria) => {
        try {
          // console.log(categoria)
          categoria = categoria.json()
        } catch (err) {
          // console.log(err)
        }
        return categoria
      })
      .then(async (categoria) => {
        if (categoria.code !== "404") {
          let { product } = await this.state
          product['categories'][0] = {name: categoria.data.nome_categoria}
          this.setState({ product })
          // console.log(product)
        }
        // console.log(categoria)
      })
    }
  }
  componentDidMount() {
    this.setState({ show: this.props.show ? this.props.show : false })
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ show: nextProps.show ? nextProps.show : false, product: nextProps.product })
    // console.log(nextProps.product)
    if (this.state.product && this.state.product.id_categoria)
      this.getData()
  }
  render() {
    const { show, product } = this.state
    // let fields
    let categoryFields

    if (product) {
      // fields = Object.entries(product)
      categoryFields = product.categories
      // console.log(product)
    }

    return (
      <div className="modal-container">
        <Modal show={show} overflow={false} full onHide={this.close}>
          <Modal.Header>
            {/* <Modal.Title>{product ? product.name : null}</Modal.Title> */}
            <h1>{product ? product.name : null}</h1>
            <TagGroup>
              {product ? categoryFields.map((category)=>
                <Tag color="cyan">{category.name}</Tag>
              ) : null}
            </TagGroup>
          </Modal.Header>
          <Modal.Body>
            {product ? (
              <Container>
                <h2>Informações</h2>
                <TagGroup>
                { // eslint-disable-next-line
                  product.enabled ? (product.enabled == 1 ? (
                    <Tag color="blue">Ativo</Tag>
                  ) : <Tag color="red">Inativo</Tag> ) : (
                    null
                  )
                }
                </TagGroup>
                <h4><b>SKU:</b> {product ? product.sku : null}</h4>
                <h4><b>Preço de custo:</b> R$ {product.factory_price}</h4>
                <h4><b>Preço de venda:</b> R$ {product.price}</h4>
                <h4><b>Preço promocional:</b> R$ {product.saleprice}</h4>
                <h4><b>Estoque:</b> {product.quantity}</h4>
                
                {product.variants.length > 0 ? (
                  <React.Fragment>
                    <h2>Variações</h2>
                    <Panel bordered>
                      <VariantsTable data={product.variants}/>
                    </Panel>
                  </React.Fragment>
                ) : null}
                
              </Container>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Loader size="md" />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close} appearance="primary">
              Ok
            </Button>
            <Button onClick={this.close} appearance="subtle">
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}