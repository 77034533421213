/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  Icon,
  ButtonToolbar,
  IconButton,
  Table,
} from 'rsuite'

const { Cell, HeaderCell, Column, Pagination: TablePagination } = Table

export default class LaudosTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      sortColumn: 'id',
      sortType: 'desc',
      displayLength: 10,
      page: 1,
      filtros: {search: '', status: []}
    }

    this.handleSortColumn = this.handleSortColumn.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeLength = this.handleChangeLength.bind(this)
  }

  componentDidMount () {
    // this.setState({loading: false})
  }

  async componentWillReceiveProps (nextProps) {
    if (nextProps.data) {
      this.setState({ data: nextProps.data })
      setTimeout(() => {
        this.setState({ data: this.getData() })
      }, 1000)
    }
    if (nextProps.filtros) {
      this.setState({ filtros: nextProps.filtros })
      setTimeout(() => {
        this.setState({ data: this.getData() })
      }, 1000)
    }
  }

  getData () {
    const { data, sortColumn, sortType, filtros } = this.state

    let resultData = []

    data.forEach((v, i) => {

      if (filtros.search && filtros.search.length > 0) {
        if (v.name.includes(filtros.search) || v.email.includes(filtros.search) || v.telephone.includes(filtros.search) || v.document.includes(filtros.search) || v.id.toString().includes(filtros.search))
          return resultData.push(v)
        else return
      }

      resultData.push(v)
    })

    if (sortColumn && sortType) {
      return resultData.sort((a, b) => {
        let x = a[sortColumn]
        let y = b[sortColumn]
        if (typeof x === 'string') {
          x = x.charCodeAt()
        }
        if (typeof y === 'string') {
          y = y.charCodeAt()
        }
        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return resultData
  }

  handleSortColumn (sortColumn, sortType) {
    this.setState({
      sortColumn,
      sortType,
      // loading: true
    })

    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 500)
  }

  handleChangePage (dataKey) {
    this.setState({
      page: dataKey
    })
  }

  handleChangeLength (dataKey) {
    this.setState({
      page: 1,
      displayLength: dataKey
    })
  }

  handleEdit (rowData) {
    this.props.history.push('/customers/' + rowData._id)
  }

  render () {
    const {
      data: originalData,
      sortType,
      sortColumn,
      loading,
      page,
      displayLength
    } = this.state

    const data = this.getData().filter((v, i) => {
      const start = displayLength * (page - 1);
      const end = start + displayLength;
      return i >= start && i < end;
    })

    return (
      <div>
        <Table
          data={data}
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={this.handleSortColumn}
          loading={loading}
          height={500}
        >
          <Column sortable width={120} align='center'>
            <HeaderCell>Número</HeaderCell>
            <Cell dataKey='id' />
          </Column>
          <Column sortable width={200}>
            <HeaderCell>Nome</HeaderCell>
            <Cell dataKey='name' />
          </Column>
          <Column sortable width={220}>
            <HeaderCell>E-mail</HeaderCell>
            <Cell dataKey='email' />
          </Column>
          <Column width={180}>
            <HeaderCell>Telefone</HeaderCell>
            <Cell dataKey='telephone' />
          </Column>
          <Column width={180}>
            <HeaderCell>Documento</HeaderCell>
            <Cell dataKey='document' />
          </Column>
          <Column width={180} fixed='right'>
            <HeaderCell>Ações</HeaderCell>
            <Cell className='link-group'>
              {rowData => {
                const { handleShow } = this.props
                return (
                  <>
                    {this.props.edit ? (
                      <ButtonToolbar>
                        <IconButton
                          appearance='subtle'
                          onClick={() => this.handleEdit(rowData)}
                          icon={<Icon icon='edit2' />}
                        />
                        
                        <IconButton
                          appearance='subtle'
                          onClick={() => handleShow(rowData)}
                          icon={<Icon icon='eye' />}
                        />
                      </ButtonToolbar>
                    ) : (
                      <a
                        className='pointer'
                        onClick={() => handleShow(rowData)}
                      >
                        {' '}
                        <Icon icon='eye' /> Visualizar{' '}
                      </a>
                    )}
                  </>
                )
              }}
            </Cell>
          </Column>
        </Table>
        <TablePagination
          lengthMenu={[
            {
              value: 10,
              label: 10
            },
            {
              value: 20,
              label: 20
            }
          ]}
          activePage={page}
          displayLength={displayLength}
          total={originalData.length}
          onChangePage={this.handleChangePage}
          onChangeLength={this.handleChangeLength}
        />
      </div>
    )
  }
}
