import React, { useState, useEffect } from 'react';
import { Divider, Col, Row, Container, Panel, Form, FormControl, FormGroup, ControlLabel, PanelGroup } from 'rsuite';
import { ComposedChart, XAxis, YAxis, Bar, Line, CartesianGrid, Tooltip as ChartTooltip, Legend } from 'recharts'
import api from '../../services/api'

export default function SearchesPage({...props}) {

  document.title = 'Relatórios do buscador da loja virtual | LOG-in'

  const [updating, setUpdating] = useState(false)
  const [data, setData] = useState([])
  const [data24, setData24] = useState([])
  const [dataWeek, setDataWeek] = useState([])
  const [dataMonth, setDataMonth] = useState([])
  const [customFilters, setCustomFilters] = useState([])

  const getData = () => {
    let date = new Date()
    date.setDate(date.getDate() - 1)
    
    // 24 HOURS
    api.get(`/searches?start=${date}`)
    .then(response => response.data)
    .then(data => {
      let found = []
      let resultData = []

      data.forEach(element => {
        if (!found[element.text]) {
          found[element.text] = true
          let counter = 0
          let foundMed = 0
          data.forEach(e => {
            if (element.text === e.text) {
              counter++
              foundMed += element.found
            }
          })
          foundMed = foundMed / counter
          resultData.push({ text: element.text, frequency: counter, found: foundMed })
        }
      })

      setData24(resultData)
    })

    date.setDate(new Date().getDate() - 7)

    // WEEK
    api.get(`/searches?start=${date}`)
    .then(response => response.data)
    .then(data => {
      let found = []
      let resultData = []

      data.forEach(element => {
        if (!found[element.text]) {
          found[element.text] = true
          let counter = 0
          let foundMed = 0
          data.forEach(e => {
            if (element.text === e.text) {
              counter++
              foundMed += element.found
            }
          })
          foundMed = foundMed / counter
          resultData.push({ text: element.text, frequency: counter, found: foundMed })
        }
      })

      resultData.sort((a, b) => {
        return a.counter < b.counter
      })

      setDataWeek(resultData)
    })

    date.setMonth(new Date().getMonth() - 1)

    // WEEK
    api.get(`/searches?start=${date.toISOString()}`)
    .then(response => response.data)
    .then(data => {
      let found = []
      let resultData = []

      data.forEach(element => {
        if (!found[element.text]) {
          found[element.text] = true
          let counter = 0
          let foundMed = 0
          data.forEach(e => {
            if (element.text === e.text) {
              counter++
              foundMed += element.found
            }
          })
          foundMed = foundMed / counter
          resultData.push({ text: element.text, frequency: counter, found: foundMed })
        }
      })

      resultData.sort((a, b) => {
        return a.counter < b.counter
      })

      setDataMonth(resultData)
    })
  }

  useEffect(() => {
    const getCustom = () => {

      let getParams = () => {
        let x = ''
        for (const key in customFilters) {
          if (customFilters.hasOwnProperty(key)) {
            const value = customFilters[key];
            x+=`${key}=${value}&`
          }
        }
        return x
      }
      
      let url = `/searches?${getParams()}`
  
      api.get(url)
      .then(response => response.data)
      .then(data => {
        let found = []
        let resultData = []
  
        data.forEach(element => {
          if (!found[element.text]) {
            found[element.text] = true
            let counter = 0
            let foundMed = 0
            data.forEach(e => {
              if (element.text === e.text) {
                counter++
                foundMed += element.found
              }
            })
            foundMed = foundMed / counter
            resultData.push({ text: element.text.toLowerCase(), frequency: counter, found: foundMed })
          }
        })
  
        setData(resultData)
      })
    }

    if (!updating)
      setUpdating(setTimeout(getCustom, 500))
    else {
      clearTimeout(updating)
      setUpdating(setTimeout(getCustom, 500))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFilters])

  useEffect(getData, [])

  return (
    <Container className="padding-left">
      <h1>Relatórios de busca da loja virtual</h1>
      <Divider />
      <PanelGroup accordion bordered>
        <Panel header='Últimas 24 horas' defaultExpanded>
        <ComposedChart
            layout='vertical'
            width={1000}
            height={800}
            data={data24}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis type='number'/>
            <YAxis dataKey='text' type='category'  />
            <ChartTooltip />
            <Legend/>
            <Bar fill='#57bf00' barSize={20} dataKey='found'/>
            <Line type='monotone' fill='#fc8726' dataKey='frequency'/>
          </ComposedChart>
        </Panel>
        <Panel header='Última semana'>
        <ComposedChart
            layout='vertical'
            width={1000}
            height={800}
            data={dataWeek}
            margin={{
              top: 5, right: 30, left: 100, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis type='number'/>
            <YAxis dataKey='text' type='category'  />
            <ChartTooltip />
            <Legend/>
            <Bar fill='#57bf00' barSize={10} dataKey='found'/>
            <Line type='monotone' fill='#fc8726' dataKey='frequency'/>
          </ComposedChart>
        </Panel>
        <Panel header='Último mês'>
          <ComposedChart
            layout='vertical'
            width={1000}
            height={800}
            data={dataMonth}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis type='number'/>
            <YAxis dataKey='text' type='category'  />
            <ChartTooltip />
            <Legend/>
            <Bar fill='#57bf00' barSize={10} dataKey='found'/>
            <Line type='monotone' fill='#fc8726' dataKey='frequency'/>
          </ComposedChart>
        </Panel>
        <Panel header='Personalizado'>
          <PanelGroup bordered accordion={false}>
            <Panel>
              <Form 
                formValue={customFilters}
                onChange={setCustomFilters}
              >
                <Row>
                  <Col sm={8}>
                    <FormGroup>
                      <ControlLabel>Data mínima</ControlLabel>
                      <FormControl name="start" type="date" />
                    </FormGroup>
                  </Col>
                  <Col sm={8}>
                    <FormGroup>
                      <ControlLabel>Data máxima</ControlLabel>
                      <FormControl name="end" type="date" />
                    </FormGroup>
                  </Col>
                  <Col sm={8}>
                    <FormGroup>
                      <ControlLabel>Termo da busca</ControlLabel>
                      <FormControl name="text" />
                    </FormGroup>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col sm={8}>
                    <FormGroup>
                      <ControlLabel>Mínimo de produtos encontrados</ControlLabel>
                      <FormControl name="min_found" />
                    </FormGroup>
                  </Col>
                  <Col sm={8}>
                    <FormGroup>
                      <ControlLabel>Máximo de produtos encontrados</ControlLabel>
                      <FormControl name="max_found" />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Panel>
            <Panel>
              <br/>
              <ComposedChart
                layout='vertical'
                width={1000}
                height={800}
                data={data}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis type='number'/>
                <YAxis dataKey='text' type='category'  />
                <ChartTooltip />
                <Legend/>
                <Bar fill='#57bf00' barSize={10} dataKey='found'/>
                <Line type='monotone' fill='#fc8726' dataKey='frequency'/>
              </ComposedChart>
            </Panel>
          </PanelGroup>
        </Panel>
      </PanelGroup>
    </Container>
  )
}