/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  Icon,
  ButtonToolbar,
  IconButton,
  TagGroup,
  Tag,
  Table,
} from 'rsuite'
import store from '../store'

const { Cell, HeaderCell, Column, Pagination: TablePagination } = Table

export default class LaudosTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      sortColumn: 'id',
      sortType: 'desc',
      displayLength: 10,
      page: 1,
      filtros: {search: '', status: []},
      loading: true
    }

    this.handleSortColumn = this.handleSortColumn.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeLength = this.handleChangeLength.bind(this)
  }

  componentDidMount () {
    // this.setState({loading: false})
  }

  async componentWillReceiveProps (nextProps) {
    // this.setState({loading: true})

    if (nextProps.data) {
      await this.setState({ data: nextProps.data, loading: false })
      setTimeout(() => {
        this.setState({ data: this.getData()})
      }, 1000)
    }
    if (nextProps.filtros) {
      await this.setState({ filtros: nextProps.filtros })
      setTimeout(() => {
        this.setState({ data: this.getData(), loading: false })
      }, 1000)
    }
  }

  getData () {
    const { data, sortColumn, sortType, filtros } = this.state

    let resultData = []

    data.forEach((v, i) => {

      const value = { ...v }

      if (filtros.status && filtros.status.length > 0) {
        if (filtros.status.includes(value.status))
          return resultData.push(value)
        else return
      }

      for (const key in filtros) {
        if (filtros.hasOwnProperty(key)) {
          if (typeof filtros[key] !== 'string') continue
          filtros[key] = filtros[key].toUpperCase()
        }
      }

      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          if (typeof value[key] !== 'string') continue
          value[key] = value[key].toUpperCase()
        }
      }
      
      if (filtros.search && filtros.search.length > 0) {
        if (value.name.includes(filtros.search) || value.email === filtros.search || value.telephone === filtros.search || value.serial_number === filtros.search || value.model === filtros.search || value.id.toString() === filtros.search)
          return resultData.push(v)
        else return
      }

      resultData.push(v)
    })

    if (sortColumn && sortType) {
      return resultData.sort((a, b) => {
        let x = a[sortColumn]
        let y = b[sortColumn]
        if (typeof x === 'string') {
          x = x.charCodeAt()
        }
        if (typeof y === 'string') {
          y = y.charCodeAt()
        }
        if (sortType === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }
    return resultData
  }

  handleSortColumn (sortColumn, sortType) {
    this.setState({
      sortColumn,
      sortType,
      // loading: true
    })

    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 500)
  }

  handleChangePage (dataKey) {
    store.dispatch({ type: 'CHANGE_LAUDOS_TABLE_DATA', page: dataKey })
    this.forceUpdate()
  }

  handleChangeLength (dataKey) {
    this.setState({
      page: 1,
      displayLength: dataKey
    })
  }

  handleEdit (rowData) {
    this.props.history.push('/technical-assistance/laudos/' + rowData._id)
  }

  render () {
    const {
      data: originalData,
      sortType,
      sortColumn,
      loading,
      displayLength
    } = this.state

    const { page } = store.getState().laudos_table

    const { status } = this.props

    const data = this.getData().filter((v, i) => {
      const start = displayLength * (page - 1);
      const end = start + displayLength;
      return i >= start && i < end;
    })

    return (
      <div>
        <Table
          data={data}
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={this.handleSortColumn}
          loading={loading}
          height={500}
        >
          <Column sortable width={120} align='center'>
            <HeaderCell>Número</HeaderCell>
            <Cell dataKey='id' />
          </Column>
          {(
            <Column width={180}>
              <HeaderCell>Status</HeaderCell>
              <Cell>
                { rowData => {
                  if (rowData.request_status) {
                    const this_status = rowData.request_status
                    const styles = { backgroundColor: this_status.bg_color, color: this_status.color }
                    return (
                      <TagGroup>
                        <Tag style={styles}>{this_status.name}</Tag>
                      </TagGroup>
                    )
                  }

                  if (!status) return null

                  const this_status = status.find(x => x._id === rowData.status)

                  if (!this_status) return 'NOT FOUND'

                  const styles = {backgroundColor: this_status.bg_color, color: this_status.color}

                  if (this_status._id !== '5d5c662027e501140c68e728' && this_status._id !== '5d5c631b1935653e4822d7d4' && this_status._id !== '5d5c68d227e501140c68e731')
                  return (
                    <TagGroup>
                      <Tag style={styles}>{this_status.name}</Tag>
                    </TagGroup>
                  )

                  let color = '#fcba03'

                  if (this_status._id === '5d5c662027e501140c68e728') {
                    switch (rowData.status_reciclagem) {
                      case 'Comprou':
                        color = '#119a2b'
                        break;
                      case 'Não comprou':
                        color = '#cc0011'
                        break;
                      default:
                        break;
                    }
                  } else if (this_status._id === '5d5c631b1935653e4822d7d4') {
                    switch (rowData.status_avaliacao) {
                      case 'LOG':
                        color = '#cc0011'
                        break;
                      case 'Assistência':
                        color = '#119a2b'
                        break;
                      default:
                        break;
                    }
                  } else if (this_status._id === '5d5c68d227e501140c68e731') {
                    switch (rowData.status_garantia) {
                      case 'Substituição':
                        color = '#cc0011'
                        break;
                      default:
                        break;
                    }
                  }

                  return (
                    <TagGroup>
                      <Tag style={styles}>{this_status.name}<Tag className='last' style={{backgroundColor: color}}>{'.'}</Tag></Tag>
                    </TagGroup>
                  )

                }}
              </Cell>
            </Column>  
          )}
          <Column width={250}>
            <HeaderCell>Nome do cliente</HeaderCell>
            <Cell dataKey='name' />
          </Column>
          <Column width={180}>
            <HeaderCell>Número de série</HeaderCell>
            <Cell dataKey='serial_number' />
          </Column>
          <Column width={320}>
            <HeaderCell>Modelo</HeaderCell>
            <Cell dataKey='model' />
          </Column>
          <Column width={180} fixed='right'>
            <HeaderCell>Ações</HeaderCell>
            <Cell className='link-group'>
              {rowData => {
                const { handleShow } = this.props
                return (
                  <>
                    {this.props.edit ? (
                      <ButtonToolbar>
                        <IconButton
                          appearance='subtle'
                          href={'/technical-assistance/laudos/' + rowData._id}
                          icon={<Icon icon='edit2' />}
                        />
                        
                        <IconButton
                          appearance='subtle'
                          onClick={() => handleShow(rowData)}
                          icon={<Icon icon='eye' />}
                        />
                      </ButtonToolbar>
                    ) : (
                      <a
                        className='pointer'
                        onClick={() => handleShow(rowData)}
                      >
                        {' '}
                        <Icon icon='eye' /> Visualizar{' '}
                      </a>
                    )}
                  </>
                )
              }}
            </Cell>
          </Column>
        </Table>
        <TablePagination
          lengthMenu={[
            {
              value: 10,
              label: 10
            },
            {
              value: 20,
              label: 20
            }
          ]}
          activePage={page}
          displayLength={displayLength}
          total={originalData.length}
          onChangePage={this.handleChangePage}
          onChangeLength={this.handleChangeLength}
        />
      </div>
    )
  }
}
