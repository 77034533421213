/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import {
  Divider,
  Col,
  Row,
  Container,
  Alert,
  Panel,
  Button,
  Icon,
  ButtonToolbar,
  Nav,
  Uploader,
  Loader,
  Notification,
} from 'rsuite'
import { API_URL, CDN_LINK } from '../settings/variables'
import api, { getAuthToken } from '../services/api'

export default class CDNPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      type: 'podcasts',
      files: [],
      loading: true,
    }

    // this.getFiles = this.getFiles.bind()
  }

  getFiles(type = this.state.type) {
    this.setState({ loading: true, files: [], type })

    api
      .get('/cdn/' + type)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        this.setState({ files: response, loading: false })
      })
  }

  componentDidMount() {
    document.title = 'CDN | LOG-in'

    this.getFiles('podcasts')
  }

  render() {
    let { type, files, loading } = this.state

    return (
      <Container className="padding-left">
        <h1>Content Delivery Network</h1>
        <Divider />
        <Navbar
          onChange={(type) => {
            this.getFiles(type)
          }}
        />
        <br />

        <Uploader
          action={API_URL + '/cdn/upload/' + type}
          headers={{
            authorization: getAuthToken(),
          }}
          onSuccess={() => this.getFiles()}
          multiple
        >
          <Button
            bordered
            style={{ backgroundColor: '#57bf00', color: 'white' }}
          >
            <Icon icon="plus" size="1x" />
            <span className="padding-left">Adicionar</span>
          </Button>
        </Uploader>

        <br />

        <Panel bordered>
          {loading ? <Loader></Loader> : null}

          {files.map((v, i) => {
            return (
              <File
                key={i}
                file={{
                  name: v.filename,
                  type: v.type,
                  size: (v.size / 1024 / 1024).toFixed(2),
                  date: v.updatedAt
                    .split('T')[0]
                    .replace('-', '/')
                    .replace('-', '/'),
                  time:
                    parseInt(
                      v.updatedAt.split('T')[1].split('.')[0].split(':')[0]
                    ) -
                    3 +
                    ':' +
                    v.updatedAt.split('T')[1].split('.')[0].split(':')[1],
                  author: v.author
                    ? v.author.charAt(0).toUpperCase() + v.author.slice(1)
                    : '',
                  _id: v._id,
                }}
                spacing={files.length - 1 !== i}
                reload={() => this.getFiles()}
              />
            )
          })}
        </Panel>
      </Container>
    )
  }
}

class File extends Component {
  constructor(props) {
    super(props)

    this.copyToClipboard = this.copyToClipboard.bind(this)
    this.deleteFile = this.deleteFile.bind(this)
    this.sure = this.sure.bind(this)
  }

  copyToClipboard() {
    var textField = document.createElement('textarea')
    textField.innerText = encodeURI(
      CDN_LINK + this.props.file.type + '/' + this.props.file.name
    )
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    Alert.success('Copiado para a área de transferência')
  }

  sure() {
    Notification.open({
      title: 'Aviso',
      duration: 10000,
      key: 'sureNotification',
      description: (
        <div>
          <p>Você realmente deseja remover esse arquivo?</p>
          <small>Essa ação é irreversível.</small>
          <br />
          <br />
          <ButtonToolbar>
            <Button color="red" onClick={this.deleteFile}>
              Sim
            </Button>
            <Button
              color="primary"
              onClick={() => Notification.remove('sureNotification')}
            >
              Cancelar
            </Button>
          </ButtonToolbar>
        </div>
      ),
    })
  }

  deleteFile() {
    Notification.remove('sureNotification')

    let { _id } = this.props.file

    api
      .delete(`/cdn/${_id}`)
      .then((response) => {
        return response.data
      })
      .then((response) => {
        if (response.error) Alert.error(response.error)
        else {
          Alert.success('Arquivo excluído com sucesso')
          this.props.reload()
        }
      })
  }

  render() {
    let { type, name, size, date, time, author } = this.props.file
      ? this.props.file
      : {}

    return (
      <>
        <Panel bordered>
          <Row>
            <Col md={2}>
              {type ? (
                <Icon
                  size="5x"
                  icon={
                    type === 'podcasts'
                      ? 'file-audio-o'
                      : type === 'docs'
                      ? 'file-text-o'
                      : type === 'images'
                      ? 'file-image-o'
                      : 'file'
                  }
                />
              ) : null}
            </Col>
            <Col md={17}>
              <h4>
                <b>{name ? name : null}</b>
              </h4>
              <h5>
                {size ? size : null}MB | por {author ? author : 'Desconhecido'},
                em {date ? date : null}, às {time ? time : null}
              </h5>
            </Col>
            <Col md={5}>
              <a style={{ cursor: 'pointer' }} onClick={this.copyToClipboard}>
                <h5>
                  <Icon icon="paperclip" size="1x" /> Copiar link
                </h5>
              </a>
              <a
                href={encodeURI(CDN_LINK + type + '/' + name)}
                target="_blank"
                rel="noopener noreferrer"
                download={name}
              >
                <h5>
                  <Icon icon="download" size="1x" /> Baixar arquivo
                </h5>
              </a>
              <a style={{ cursor: 'pointer' }} onClick={this.sure}>
                <h5>
                  <Icon icon="trash" size="1x" /> Excluir arquivo
                </h5>
              </a>
            </Col>
          </Row>
        </Panel>
        {this.props.spacing ? <br /> : null}
      </>
    )
  }
}

class Navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 'podcasts',
    }

    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidMount() {}

  handleSelect(activeKey) {
    this.setState({ active: activeKey })

    this.props.onChange(activeKey)
  }

  render() {
    let { active } = this.state

    return (
      <Nav activeKey={active} appearance="subtle" onSelect={this.handleSelect}>
        <Nav.Item eventKey="podcasts" icon={<Icon icon="music" />}>
          Podcasts
        </Nav.Item>
        <Nav.Item eventKey="docs" icon={<Icon icon="file-text" />}>
          Documentos
        </Nav.Item>
        <Nav.Item eventKey="images" icon={<Icon icon="image" />}>
          Imagens
        </Nav.Item>
        <Nav.Item eventKey="other" icon={<Icon icon="file" />}>
          Outros
        </Nav.Item>
      </Nav>
    )
  }
}
