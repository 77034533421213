import React, { useState, useEffect } from 'react'
import {
  Schema,
  Divider,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Container,
  Alert,
  Panel,
  Button,
  Icon,
  ButtonToolbar,
  PanelGroup,
  Notification,
  Modal,
  Input,
  InputGroup,
} from 'rsuite'
import { useSelector } from 'react-redux'
import api from '../../services/api'
import FlawsTable from '../../components/FlawsTable'

function CreateModal({ ...props }) {
  const show = props.show ? props.show : false
  const [formValue, setFormValue] = useState({})
  const handleClose = props.onClose
  const handleRefresh = props.onRefresh

  const { StringType, NumberType } = Schema.Types

  let model = Schema.Model({
    description: StringType().isRequired('O campo "descrição" é obrigatório.'),
    technicianValue: NumberType('Insira um número válido (0.00)'),
    customerValue: NumberType('Insira um número válido (0.00)'),
  })

  const [, setFormModel] = useState(model)

  const handleSubmit = () => {
    const errors = model.check(formValue)
    let hasError = false

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key]
        if (element.hasError === true) {
          hasError = element.errorMessage
          break
        }
      }
    }

    if (hasError) {
      return Alert.error(hasError)
    }

    api
      .post('/flaw', formValue)
      .then(response => {
        return response.data
      })
      .then(response => {
        if (response.error) return Alert.error(response.error)

        Alert.success('Serviço adicionado com sucesso!')

        handleRefresh()
        handleClose()
      })
  }

  const handleChange = (v, e) => {
    let temp = formValue
    try {
      temp[e.target ? e.target.name : e] = v
      setFormValue(temp)
    } catch (e) {
      Alert.error(e.message)
    }
  }

  return (
    <Modal show={show} overflow={false} onHide={handleClose}>
      <Modal.Header>
        <h1>Adicionar serviço</h1>
      </Modal.Header>
      <Modal.Body>
        <Form fluid formValue={formValue} onCheck={setFormModel} model={model}>
          <FormGroup>
            <ControlLabel>Descrição</ControlLabel>
            <FormControl onChange={handleChange} name='description' />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Valor técnico (R$)</ControlLabel>
            <FormControl onChange={handleChange} name='technicianValue' />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Valor cliente (R$)</ControlLabel>
            <FormControl onChange={handleChange} name='customerValue' />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} appearance='primary'>
          Adicionar
        </Button>
        <Button onClick={handleClose} appearance='subtle'>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function EditModal({ ...props }) {
  const show = props.show ? props.show : false
  const [formValue, setFormValue] = useState({})
  const handleClose = props.onClose
  const handleRefresh = props.onRefresh

  const { StringType, NumberType } = Schema.Types

  let model = Schema.Model({
    description: StringType().isRequired('O campo "descrição" é obrigatório.'),
    technicianValue: NumberType('Insira um número válido (0.00)'),
    customerValue: NumberType('Insira um número válido (0.00)'),
  })

  useEffect(() => setFormValue(props.data), [props.data])

  const [, setFormModel] = useState(model)

  const handleSubmit = () => {
    const errors = model.check(formValue)
    let hasError = false

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key]
        if (element.hasError === true) {
          hasError = element.errorMessage
          break
        }
      }
    }

    if (hasError) {
      return Alert.error(hasError)
    }

    api
      .put('/flaw', formValue)
      .then(response => {
        return response.data
      })
      .then(response => {
        if (response.error) return Alert.error(response.error)

        Alert.success('Serviço editado com sucesso!')

        handleRefresh()
        handleClose()
      })
  }

  return (
    <Modal show={show} overflow={false} onHide={handleClose}>
      <Modal.Header>
        <h1>Editar serviço</h1>
      </Modal.Header>
      <Modal.Body>
        <Form
          fluid
          onChange={setFormValue}
          formValue={formValue}
          onCheck={setFormModel}
          model={model}
        >
          <FormGroup>
            <ControlLabel>Descrição</ControlLabel>
            <FormControl disabled name='description' />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Valor técnico (R$)</ControlLabel>
            <FormControl name='technicianValue' />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Valor cliente (R$)</ControlLabel>
            <FormControl name='customerValue' />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit} appearance='primary'>
          Editar
        </Button>
        <Button onClick={handleClose} appearance='subtle'>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function FlawsPage({ ...props }) {
  document.title = 'Serviços | LOG-in'

  const [createModal, setCreateModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [editData, setEditData] = useState({})
  const [filtros, setFiltros] = useState({ search: '' })
  const [flaws, setFlaws] = useState(null)

  const userData = useSelector(state => state.user)

  const getData = () => {
    api
      .get('/flaws')
      .then(response => {
        return response.data
      })
      .then(setFlaws)
  }

  useEffect(getData, false)

  const deleteFlaw = rowData => {
    api
      .delete('/flaw/' + rowData._id)
      .then(response => {
        return response.data
      })
      .then(response => {
        Notification.remove('sureNotification')

        if (response.error) return Alert.error(response.error)

        Alert.success('Serviço excluído com sucesso!')

        getData()
      })
  }

  const handleDelete = rowData => {
    Notification.open({
      title: 'Aviso',
      duration: 10000,
      key: 'sureNotification',
      description: (
        <div>
          <p>Você realmente deseja remover esse serviço?</p>
          <small>Essa ação é irreversível.</small>
          <br />
          <br />
          <ButtonToolbar>
            <Button color='red' onClick={() => deleteFlaw(rowData)}>
              Sim
            </Button>
            <Button
              color='primary'
              onClick={() => {
                Notification.remove('sureNotification')
              }}
            >
              Cancelar
            </Button>
          </ButtonToolbar>
        </div>
      ),
    })
  }

  const handleEdit = data => {
    setEditData(data)
    setEditModal(true)
  }

  return (
    <Container className='padding-left'>
      <h1>Serviços da assistência técnica</h1>
      <Divider />

      <PanelGroup bordered>
        <Panel header='Ações'>
          <Row>
            {(userData.roles.includes('admin') ||
              userData.roles.includes('employee')) && (
              <Col md={8} sm={12}>
                <ButtonToolbar>
                  <Button color='primary' onClick={() => setCreateModal(true)}>
                    <Icon icon='plus' />
                    <span className='padding-left'>Adicionar serviço</span>
                  </Button>
                </ButtonToolbar>
              </Col>
            )}
            <Col md={8} sm={12} mdOffset={(userData.roles.includes('admin') ||
              userData.roles.includes('employee')) ? 8 : 0}>
              <InputGroup inside>
                <Input
                  data={filtros.search}
                  onChange={search => {
                    setFiltros({ ...filtros, search })
                  }}
                  placeholder='Pesquisar'
                />
                <InputGroup.Button disabled>
                  <Icon icon='search' />
                </InputGroup.Button>
              </InputGroup>
            </Col>
          </Row>
        </Panel>
        <Panel>
          <FlawsTable
            filtros={filtros}
            data={flaws}
            loading={!flaws}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Panel>
      </PanelGroup>

      <CreateModal
        show={createModal}
        onClose={() => setCreateModal(false)}
        onRefresh={() => getData()}
      />

      <EditModal
        data={editData}
        show={editModal}
        onClose={() => setEditModal(false)}
        onRefresh={() => getData()}
      />
    </Container>
  )
}
