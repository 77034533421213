import React from 'react';
import { Navbar, Nav, Icon, IconButton, Dropdown } from 'rsuite';
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

import logo from '../img/log_white.svg'
import api from '../services/api';

const NavBar = ({...props}) => {
  const userData = useSelector(state => state.user)
  const dispatch = useDispatch()

  const changeUserData = data => {
    dispatch({ type: 'CHANGE_USER_DATA', user: data })
  }

  function logout() {
    api.post('/logout')
    .then(response => {

      changeUserData({logged: false})
    })
  }
  
  return (
    <Navbar {...props}>
      <Navbar.Header>
        <IconButton onClick={props.onNavToggle} icon={<Icon icon="bars" />} appearance="primary" />
        <img className="navbar-brand" src={logo} alt="LOGO Log" />
        <h3>interno</h3>
      </Navbar.Header>
      <Navbar.Body>
        <Nav onSelect={props.onSelect} activeKey={props.activeKey}>
        </Nav>
        <Nav pullRight>
          <Dropdown
            renderTitle={() => {
              return (<Nav.Item icon={<Icon icon="avatar" />}>{userData.username}</Nav.Item>)
            }}
            placement="bottomRight"
          >
            <Dropdown.Item onClick={() => {props.history.push('/user-settings'); props.onRedirect();}}><Icon icon="gear-circle" /> Minha conta</Dropdown.Item>
            <Dropdown.Item onClick={logout}><Icon icon="exit" /> Sair</Dropdown.Item>
          </Dropdown>
          
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
}

export default withRouter(NavBar);
