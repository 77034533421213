import React, { useState } from 'react'
import {
  Divider,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Container,
  Alert,
  Panel,
  SelectPicker,
  Button,
  Icon,
  ButtonToolbar,
  PanelGroup,
  IconButton,
  Notification,
} from 'rsuite'
import api from '../../services/api'

export default function LaudosPage ({ match, ...props }) {
  const [customer, setCustomer] = useState(false)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)

  document.title = `Cliente #${customer ? customer.id : ''} | LOG-in`

  const getData = async () => {
    await api
      .get('/customers/' + match.params.id)
      .then(response => {
        return response.data
      })
      .then(response => {
        setCustomer(response)
      })
  }

  const handleSave = () => {
    setSaving(true)
    api
      .put('/customer', customer)
      .then(response => {
        return response.data
      })
      .then(response => {
        setSaving(false)

        if (response.error) return Alert.error(response.error)

        Alert.success('Cliente atualizado com sucesso!')
      })
  }

  const deleteCustomer = () => {
    api
      .delete('/customers/' + customer._id)
      .then(response => {
        return response.data
      })
      .then(response => {
        setDeleting(false)
        Notification.remove('sureNotification')

        if (response.error) return Alert.error(response.error)

        Alert.success('Cliente excluído com sucesso!')

        props.history.push('/customers')
      })
  }

  const handleDelete = () => {
    setDeleting(true)
    Notification.open({
      title: 'Aviso',
      duration: 10000,
      key: 'sureNotification',
      description: (
        <div>
          <p>Você realmente deseja remover esse cliente?</p>
          <small>Essa ação é irreversível.</small>
          <br />
          <br />
          <ButtonToolbar>
            <Button color='red' onClick={deleteCustomer}>
              Sim
            </Button>
            <Button
              color='primary'
              onClick={() => {
                setDeleting(false)
                Notification.remove('sureNotification')
              }}
            >
              Cancelar
            </Button>
          </ButtonToolbar>
        </div>
      )
    })
  }

  if (customer === false) getData()

  if (!customer) return <Container className='padding-left' />

  return (
    <Container className='padding-left'>
      <React.Fragment>
        <h1>Cliente #{customer.id}</h1>
        <Divider />
        <Form
          fluid
          formValue={customer}
          onChange={val => {
            setCustomer(val)
          }}
        >
          <PanelGroup bordered>
            <Panel header='Ações'>
              <ButtonToolbar>
                <IconButton
                  onClick={handleSave}
                  appearance='primary'
                  loading={saving}
                  style={{ marginBottom: '10px' }}
                >
                  <Icon icon='save' />
                  Salvar
                </IconButton>

                <IconButton
                  onClick={handleDelete}
                  appearance='primary'
                  color='red'
                  loading={deleting}
                  style={{ marginBottom: '10px' }}
                >
                  <Icon icon='trash' />
                  Excluir
                </IconButton>
              </ButtonToolbar>
            </Panel>

            <Panel header='Contato'>
              <Row>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Nome</ControlLabel>
                    <FormControl name='name' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>E-mail</ControlLabel>
                    <FormControl name='email' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Telefone</ControlLabel>
                    <FormControl name='telephone' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={6}>
                  <FormGroup>
                    <ControlLabel>Documento</ControlLabel>
                    <FormControl name='document' />
                  </FormGroup>
                </Col>
              </Row>
            </Panel>

            <Panel header='Ocupação'>
              <Row>
                <Col sm={24} md={8}>
                  <FormGroup>
                    <ControlLabel>Instituição</ControlLabel>
                    <FormControl name='institution' />
                  </FormGroup>
                </Col>
                <Col sm={24} md={8}>
                  <FormGroup>
                    <ControlLabel>Perfil</ControlLabel>
                    <FormControl name="profile" block accepter={SelectPicker} searchable={false} data={[
                      { value: 'biologist', label: 'Biólogo' },
                      { value: 'observer', label: 'Observador/Interessado pela natureza' },
                      { value: 'manager', label: 'Setor de compras/Administrativo/Logística/Suprimentos' },
                      { value: 'other', label: 'Personalizado' }
                      ]} />
                  </FormGroup>
                </Col>
                <Col sm={24} md={8}>
                  { customer.profile === 'biologist' && (
                    <FormGroup>
                      <ControlLabel>Cargo</ControlLabel>
                      <FormControl name="profile_final" block accepter={SelectPicker} searchable={false} data={[
                        { value: 'Setor Administrativo', label: 'Setor Administrativo' },
                        { value: 'Consultoria Ambiental', label: 'Consultoria Ambiental' },
                        { value: 'Órgão Público', label: 'Órgão Público' },
                        { value: 'Professor e/ou Pesquisador vinculado a instituição de ensino e pesquisa', label: 'Professor e/ou Pesquisador vinculado a instituição de ensino e pesquisa' },
                        { value: 'Aluno graduação', label: 'Aluno graduação' }
                        ]} />
                    </FormGroup>
                  )}
                  
                  { customer.profile === 'other' && (
                    <FormGroup>
                      <ControlLabel>Cargo</ControlLabel>
                      <FormControl name="profile_final" />
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </Panel>

            <Panel header='Endereço'>
              <Form
                formValue={customer.address}
                onChange={val => setCustomer({ ...customer, address: val })}
              >
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <ControlLabel>CEP</ControlLabel>
                      <FormControl name='cep' />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={8}>
                    <FormGroup>
                      <ControlLabel>Logradouro</ControlLabel>
                      <FormControl name='street' />
                    </FormGroup>
                  </Col>
                  <Col sm={24} md={5}>
                    <FormGroup>
                      <ControlLabel>Número</ControlLabel>
                      <FormControl name='number' />
                    </FormGroup>
                  </Col>
                  <Col sm={24} md={5}>
                    <FormGroup>
                      <ControlLabel>Complemento</ControlLabel>
                      <FormControl name='complement' />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm={24} md={8}>
                    <FormGroup>
                      <ControlLabel>Bairro</ControlLabel>
                      <FormControl name='district' />
                    </FormGroup>
                  </Col>
                  <Col sm={24} md={8}>
                    <FormGroup>
                      <ControlLabel>Cidade</ControlLabel>
                      <FormControl name='town' />
                    </FormGroup>
                  </Col>
                  <Col sm={24} md={8}>
                    <FormGroup>
                      <ControlLabel>Estado</ControlLabel>
                      <FormControl name='state' />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </PanelGroup>
        </Form>
      </React.Fragment>
    </Container>
  )
}
