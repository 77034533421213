const rentalRequestStatus = {
  0: {
    label: 'Pendente',
    color: '#cc0011',
  },
  1: {
    label: 'Aguardando assinatura contrato',
    color: '#fcba03',
  },
  2: {
    label: 'Aguardando pagamento',
    color: '#2750cc',
  },
  3: {
    label: 'A enviar',
    color: '#cc0011',
  },
  4: {
    label: 'Em locação',
    color: '#fcba03',
  },
  5: {
    label: 'Prorrogado',
    color: '#2750cc',
  },
  6: {
    label: 'A receber',
    color: '#6d3780',
  },
  7: {
    label: 'Finalizado',
    color: '#119a2b',
  },
}

module.exports = {
  rentalRequestStatus,
}
